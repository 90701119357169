import {Box, GridItem, HStack, Text} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {ModaleAlerteConfirmation} from './ModaleAlerteConfirmation';
import {ProductControlSideHeader} from '@/components/Product/ProductControlSideHeader';
import {PanierCommands} from 'ff-common/src/hooks/panier/use-panier-commands';
import {useTranslation} from 'react-i18next';

export const PanierHeader: React.FC<{
  viderCommand: PanierCommands['vider'],
  nombre_de_lignes: number,
}> = ({
  viderCommand,
  nombre_de_lignes,
}) => {
  const {t} = useTranslation('common');
  return (
    <GridItem display={{base: 'none', md: 'flex'}} colSpan={{base: 1, lg: 5}} as={HStack}>
      <HStack margin={{base: '2', lg: '0'}}>
        <Text fontWeight='600'>Panier</Text>
        <Box textColor='gray.dark' fontWeight='600' fontSize='xs' bg='gray.light' p='1' borderRadius='8px' minW={'2em'} aspectRatio={1} textAlign='center'>
          {nombre_de_lignes}
        </Box>;
        {/* <Button variant='ghost' p='0' onClick={onToggle}>{!isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button> */}
      </HStack>
      <ProductControlSideHeader deleteText='Vider le panier' deleteAction={() => {
        NiceModal.show(ModaleAlerteConfirmation, {
          validate_texte:t('panier.supprimer_panier_action'),
          body: t('panier.supprimer_panier_confirm'),
          validateAction: () => {
            viderCommand.submit({} as never);
          }
        });
      }} />
    </GridItem>
  );
};
