import {useClient} from '@/haligator-factory';
import {Box, Button, Divider, HStack, Spacer, Stack, Text, Tooltip, VStack} from '@chakra-ui/react';
import {Link, useParams} from 'react-router-dom';
import {HaliRenderMany, HaliRenderOne} from '@synako/haligator';
import {ChevronLeftIcon} from '@/components/svg/ChevronLeftIcon';
import {useTranslation} from 'react-i18next';
import {dateAvecSlashes, dateEnTexte} from 'ff-common';
import {DownloadIcon, InfoIcon} from '@chakra-ui/icons';
import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon';
import {StatutBadge} from './StatutBadge';
import {BoueeIcon} from '@/components/svg/BoueeIcon';
import {routes} from 'ff-common';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {DownloadLink} from '../../components/DownloadLink';
import {toDecimalFr} from 'ff-common';
import {LigneProduitLayout} from '../liste/components/LigneProduitLayout';
import {ArticleBlock} from '../panier/PanierFound/ArticleBlock';
import {LigneDeCommandeDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';

export const CommandeDetailView: React.FC = () => {
  const {t} = useTranslation('common');
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {cle_commande} = useParams<{cle_commande: string}>();

  const commande = useClient().follow('commande', {cle_commande});
  const client = useClient();

  return (
    <Box width="100%">
      <HStack alignItems="start" bg="gray.bg" overflowX="hidden" width="100%"> {/* globale */}
        <HaliRenderOne
          nav={commande}
          render={({state, data}) => (
            <Box p={{base:0, md:5}} mr={{base:'0px', md:'20px', xl:'40px', '2xl':'65px'}} ml={{base:'0px', md:'20px', xl:'40px', '2xl':'65px'}} width="100%">
              {/* Titre */}
              <HStack  mb={6} mt={{base:'30px', md:'10px'}}>
                <Stack direction={{base:'column', md:'row'}}>
                  <HStack ml={{base:'20px', md:'0px'}}>
                    <Link to={buildAssistanceRoute(routes.client.commandes)}>
                      <ChevronLeftIcon color="black" width={{base:'25px', md:'37px'}} height={{base:'25px', md:'37px'}}/>
                    </Link>
                    <Text fontSize={{base:'lg', md:'2xl'}} fontWeight="700" color="black.text">{t('commande.livraison_du')} {dateEnTexte(data.tolede.date_de_livraison_ou_d_enlevement)}</Text>
                  </HStack>
                  <HStack>
                    <Text display={{base:'none', md:'inline'}} fontSize='2xl'> - </Text>
                    <Text ml={{base:'55px', md:'0'}} fontSize={{base:'18px', md:'25px'}}>{t('commande.n°')}{data.tolede.numero}</Text>
                    {data.tolede.passee_avec !== 'autre' && (
                      <Box borderRadius="4px" borderWidth="1px" borderColor="gray.dark" px={2} py={1} ml={2}>
                        <Text fontWeight="500" fontSize="sm">{t(`commande.${data.tolede.passee_avec}`)}</Text>
                      </Box>
                    )}
                  </HStack>
                </Stack>
              </HStack>

              {/* Informations générales de la commande */}
              <Box p={5} bg="white" borderRadius={{base:'0px', md:'8px'}} borderWidth="1px" alignSelf="flex-end">
                <Box bg="gray.50" mt={4} bgColor="white">
                  <VStack align="stretch" spacing={0}>
                    <Stack direction={{base:'column', md:'row'}} align={{base:'start', md:'center'}} w="100%" borderWidth={{base:'0', md:'1px'}} p={{base:0, md:4}} borderTopRadius="8px">
                      <Box width={{base:'300px', md:'500px'}}>
                        <Text fontWeight="600" fontSize="sm">
                          {t('commande.passee_le')} {dateEnTexte(data.tolede.cree_le)}
                        </Text>
                      </Box>
                      <HStack align="center" my={3} width="100%" >
                        <HStack>
                          <Text fontSize={{base:'xs', md:'sm'}} color="gray.dark">{t('global.produit', {count:data.tolede.nombre_de_lignes})}</Text>
                          <Text fontSize={{base:'xs', md:'sm'}}>•</Text>
                          <Text fontWeight="700" fontSize={{base:'xs', md:'sm'}}>{data.tolede.total_ttc} €</Text>
                        </HStack>
                        <Spacer/>
                        <Box>
                          {data.tolede.statut !== 'facturee' ? (
                            <StatutBadge variant={data.tolede.statut ?? ''}/>
                          ):(
                            <Stack spacing={{base:'2px', md:2}} direction={{base:'column', md:'row'}} color="green.success" bg="green.success_bg"
                              borderRadius="8px" px={4} py={1} justifyContent="center" alignItems="center" width={{base: '90px', md: 'auto'}}>
                              <Text fontSize="xs" fontWeight="400">{t('commande.facturee')}</Text>
                              <Text fontSize="xs" fontWeight="400" display={{base: 'none', md: 'inline'}}> - </Text>
                              <Text fontSize="xs" fontWeight="400">{dateAvecSlashes(data.tolede.facturee_le)}</Text>
                            </Stack>
                          )}
                        </Box>
                      </HStack>
                    </Stack>
                    <Divider display={{base: 'block', md: 'none'}} />
                    <Stack direction={{base:'column', md:'row'}} align="stretch" borderWidth={{base:'0', md:'1px'}} borderTopWidth={{base:'0', md:'0px'}} borderBottomRadius="8px" spacing={{base:5, md:2}}>
                      <Stack direction={{base:'column', md:'row'}} p={{base:0, md:4}} pt={2} width="100%">
                        <Stack direction={{base:'column', md:'row'}} width="100%" height="100%">
                          <HaliRenderMany
                            many={client.follow('adresses_de_livraison').followAll('items')}
                            render={({data: adresses}) => (
                              <>
                                {adresses
                                  .filter(adresse => adresse.tolede.code_adresse_de_livraison === data.tolede.code_adresse_de_livraison)
                                  .map(adresse => (
                                    <VStack align="start" spacing={0} width="100%" height="100%">
                                      <Text fontSize="xs" color="gray.dark" mb="10px">{t('commande.adresse_livraison')}</Text>
                                      <Text fontWeight="600" fontSize="sm">{adresse.tolede.enseigne}</Text>
                                      <Text fontSize="sm">{adresse.tolede.adresse.ligne1}</Text>
                                      <Text fontSize="sm">{adresse.tolede.adresse.ligne2}</Text>
                                      <Text fontSize="sm">{adresse.tolede.adresse.code_postal} {adresse.tolede.adresse.ville}</Text>
                                    </VStack>
                                  ))}
                              </>
                            )}/>
                          <VStack align="start" spacing={0} width="100%" height="100%"> {/* Adresse de facturation */}
                            <HaliRenderOne
                              nav={client}
                              render={({data}) => (
                                <>
                                  <Text fontSize="xs" color="gray.dark" mb="10px">{t('commande.adresse_facturation')}</Text>
                                  <Text fontSize="sm" fontWeight="600">{data.tolede.raison_sociale}</Text>
                                </>
                              )}/>
                            <HaliRenderOne
                              nav={client.follow('adresse_de_facturation')}
                              render={({data}) => (
                                <>
                                  <Text fontSize="sm">{data.tolede.ligne1}</Text>
                                  <Text fontSize="sm">{data.tolede.ligne2}</Text>
                                  <Text fontSize="sm">{data.tolede.code_postal} {data.tolede.ville}</Text>
                                </>
                              )}/>
                          </VStack>
                        </Stack>
                        <Divider display={{base: 'block', md: 'none'}} />

                        {data.tolede.reference && (
                          <><VStack width={{base: '100%', md: '33%'}} align="bottom" height="100%">
                            <Text fontSize="xs" color="gray.dark">{t('commande.reference_de_commande')}</Text>
                            <Text fontSize="sm">{data.tolede.reference}</Text>
                          </VStack><Divider display={{base: 'block', md: 'none'}} /></>
                        )}

                        {/* Section téléchargement */}
                      </Stack>
                      <VStack align="start" borderLeftWidth={{base: '0px', md: '1px'}} p={{base: 0, md: 5}}>
                        <DownloadLink state={state}>
                          <Button leftIcon={<DownloadIcon />} variant="ghost" borderWidth="1px" color="black.text" 
                            fontSize={{base: '2xs', md: 'xs'}}
                            fontWeight="600" bg="white" width={{base:'auto', md:'100%', lg:'auto'}}>
                            {t('commande.bon_de_commande')}
                          </Button>
                        </DownloadLink>
                      </VStack>
                    </Stack>
                  </VStack>
                </Box>

                {/* Section d'aide */}
                {data.tolede.statut !== 'facturee' && (
                  <Stack spacing={5} direction={{base:'column', md:'row'}} justify="space-between" w="100%" mt={4} p={4} borderRadius="md" borderWidth="1px" borderColor="blue.main_transparency_30" >
                    <HStack>
                      <Box display={{base:'none', md:'inline'}} padding="10px" paddingTop="12px" paddingBottom="12px" bg='blue.main_transparency_10' borderRadius="8px" mr={3}>
                        <BoueeIcon/>
                      </Box>
                      <VStack align="start" spacing={1}>
                        <Text fontWeight="700" fontSize="sm" color="blue.main">{t('commande.vous_etes_trompe')}</Text>
                        <Text fontSize="xs">{t('commande.on_vous_accompagne')}</Text>
                      </VStack>
                    </HStack>
                    <VStack align="start" spacing={1} >
                      <Text fontWeight="700" fontSize="sm" color="blue.main">{t('commande.ajouter_produit')}</Text>
                      <Button rightIcon={<RightSingleArrow/>} variant="link" fontSize={{base:'xs', md:'sm'}} colorScheme="blue" textDecoration="underline"
                        as={Link} to={routes.client.cadencier.path}>{t('commande.passer_nouvelle_commande')}</Button>
                    </VStack>
                    <VStack align="start" spacing={1}>
                      <Text fontWeight="700" fontSize="sm" color="blue.main">{t('commande.supprimer_produit_changer_quantite')}</Text>
                      <Button rightIcon={<RightSingleArrow/>} variant="link" fontSize={{base:'xs', md:'sm'}} colorScheme="blue" textDecoration="underline"
                        as={Link} to={routes.client.contacts.path}
                      >{t('commande.contacter_conseiller')}</Button>
                    </VStack>
                  </Stack>
                )}

                {/* Produits inclus dans la commande */}
                <HStack mt={10} mb={5}>
                  <Text fontWeight="600" fontSize="sm" color="black.tt">{t('commande.details_de_ma_commande')}</Text>
                  <HStack color="gray.dark" bg="gray.bg" borderRadius="8px" px={4} py={1} justifyContent="center" alignItems="center">
                    <Text fontSize="2xs" fontWeight="400">{t('global.produit', {count:data.tolede.nombre_de_lignes})}</Text>
                  </HStack>
                </HStack>

                {/* Lignes de la commande */}
                <VStack>
                  <HaliRenderMany
                    many={commande.followAll('lignes_de_commande')}
                    render={({states: lignes}) => (
                      <>
                        {lignes.map(ligne => (
                          <ProduitCommande key={ligne.data.id}
                            ligneDeCommande={ligne}
                          />
                        ))}
                      </>
                    )}/>
                </VStack>

                {/* Récapitulatif prix de la commande */}
                <HStack justifyContent="end" width="100%" mt={10}>
                  <VStack bg="gray.bg" p={5} spacing={4} minWidth={{base:'280px', md:'400px'}} borderRadius="8px">
                    <HStack color="gray.dark" spacing={4} fontSize={{base:'xs', md:'sm'}} justifyContent="space-between" width="100%">
                      <Text textTransform="uppercase">{t('commande.total_ht')}</Text>
                      <Text textTransform="uppercase">{toDecimalFr(data.tolede.total_ht, 2, 3)} €</Text>
                    </HStack>
                    <HStack color="gray.dark" spacing={4} fontSize={{base:'xs', md:'sm'}} justifyContent="space-between" width="100%">
                      <HStack>
                        <Text textTransform="uppercase">{t('commande.total_tva')}</Text>
                        <Tooltip label={t('commande.tooltip_tva')}>
                          <InfoIcon />
                        </Tooltip>
                      </HStack>
                      <Text textTransform="uppercase">{toDecimalFr(data.tolede.total_tva, 2, 3)} €</Text>
                    </HStack>
                    {/* <HStack color="gray.dark" spacing={4} fontSize={{base:"12px", md:"14px"}} justifyContent="space-between" width="100%">
                    <Text>{t('commande.frais_de_livraison')}</Text>
                    <Text>Gratuit</Text>
                  </HStack> */}
                    <HStack color="black.ff" spacing={4} fontWeight="600" justifyContent="space-between" width="100%">
                      <Text fontSize={{base:'sm', md:'md'}}>{t('commande.total')}</Text>
                      <Text fontSize={{base:'lg', md:'xl'}} fontWeight="700">{toDecimalFr(data.tolede.total_ttc, 2, 3)} €</Text>
                    </HStack>
                  </VStack>
                </HStack>
              </Box>
            </Box>
          )}/>
      </HStack>
    </Box>
  );
};

const ProduitCommande:React.FC<{
  ligneDeCommande:State<LigneDeCommandeDesc>
}> = ({
  ligneDeCommande
})=>{
  const {t} = useTranslation('common');
  const articleBlock = <ArticleBlock 
    code_article={ligneDeCommande.data.tolede.code_article} 
    article={ligneDeCommande.follow('article')}
    libelleFallback={ligneDeCommande.data.tolede.libelle_commercial ?? ligneDeCommande.data.tolede.libelle}
  />;

  const {quantite_commandee, prix_total_ht, prix_unitaire, unite_de_commande_client, unite_de_facturation} = ligneDeCommande.data.tolede;

  const prixBlock = <VStack minW={{base: 0, md: '9em'}} alignItems="start" gap='0' mt='15px' ml='auto' mr={{base: 0, md: 7, mr: 0}}>
    <HStack gap='0.5' alignItems='start'>
      <Text textColor='black.text' fontSize='xl' fontWeight='700'>{toDecimalFr(prix_total_ht)}</Text>
      <Text fontSize='xs'>€</Text>
      <Text fontSize='xs'>{t('ligne_produit.HT')}</Text>
    </HStack>
    <Text fontSize='xs' color='gray.dark'>
      {t('ligne_produit.soit_prix', {prix:toDecimalFr(prix_unitaire, 2, 3)})}/{t(`unite.${unite_de_facturation}`, {count:1})}
    </Text>
  </VStack>;

  const quantiteBlock = <Box
    fontSize='md'
    color="black"
    bg="gray.bg"
    p={3}
    borderRadius="8px"
    mr={8}
    alignSelf="center"
    whiteSpace='nowrap'>
    {quantite_commandee} {t(`unite.${unite_de_commande_client}`, {count:quantite_commandee})}
  </Box>;
  return <LigneProduitLayout flexWrap='wrap'>
    <HStack>
      {articleBlock}
    </HStack>
    <HStack ml='auto' alignItems='start' w={{base:'100%', md:'fit-content'}} p={{base:'3', md:'initial'}} pt='0'>
      {quantiteBlock}
      {prixBlock}
    </HStack>
  </LigneProduitLayout>;
};