import {useLivraisonsPossiblesContext} from 'ff-common';
import {useNavigate} from 'react-router-dom';
import {useIsAssistanceMode} from '../auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {useLivraisons} from '@/haligator-factory';
import {useHalStates} from '@synako/haligator';

export const useAfficherCadencier = () => {
  const {setLivraisonSelectionnee} = useLivraisonsPossiblesContext();
  const navigate = useNavigate();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));

  const afficherCadencier = (dateLivraison: string) => {
    const state = livraisonsPossibles.states?.find(livraison => livraison.data.tolede.date_de_livraison === dateLivraison);
    if (state) {
      setLivraisonSelectionnee(state);
    }
    navigate(buildAssistanceRoute(routes.client.cadencier));
  };

  return afficherCadencier;
};
