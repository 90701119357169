import {useCommand} from '@synako/haligator';
import {HalitrekErrorHandler, State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {Trans, useTranslation} from 'react-i18next';
// import {ModaleRupture} from '@/domains/cadencier/components/ModaleRupture';
import NiceModal from '@ebay/nice-modal-react';
import {TextToastCommand} from '../../types/TextToastCommand';
import {dateAvecSlashes} from '../../utils/date-tools';
import {FC} from 'react';

export type LigneDePanierCommands = ReturnType<typeof useLigneDePanierCommands>;

export const useLigneDePanierCommands = (
  ligneDePanier: State<LigneDePanierDesc>|undefined,
  textToast: TextToastCommand,
  onError: HalitrekErrorHandler,
  // <Any> pour ne pas mettre en commun le type de ModaleRupture pour le moment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ModaleRupture:FC<any>,
  multipleCommandeClient?: number,
) => {
  const {t} = useTranslation('common');

  const remplacer = useCommand(
    ligneDePanier,
    'c:remplacer-un-article', {
      onError,
      onSuccess: (event) => {
        const {libelle} = event.data.data.ligne_de_panier;
        textToast(t('toast.produit_remplace', {libelle}), {id:'produit-remplace-toast'});
        NiceModal.remove(ModaleRupture);
      }
    }
  );
  
  const differer = useCommand(
    ligneDePanier,
    'c:differer-la-livraison', {
      onError,
      onSuccess: (event) => {
        const {date_de_livraison} = event.data.data.panier_destination.livraison_tolede;
        textToast([
          <Trans t={t}>{t('toast.produit_differe', {date: dateAvecSlashes(date_de_livraison)})}</Trans>,
          t('toast.produit_differe_cta')
        ], {id:'produit-differe-toast'});
        NiceModal.remove(ModaleRupture);
      }
    }
  );

  const supprimer = useCommand(
    ligneDePanier,
    'c:supprimer-du-panier', {
      onError,
      onSuccess: ()=>{
        textToast(t('toast.supprime_panier'), {id:'supprime-panier-toast'});
      }
    }
  );

  const switchUnite = useCommand(
    ligneDePanier,
    'c:switch-unite', {
      onError,
      onSuccess: () => {
        // noop
      }
    }
  );

  const changerLaQuantite = useCommand(
    ligneDePanier,
    'c:changer-la-quantite', {
      onError,
      onSuccess: async (event) => {
        if (event.data.data.ligne_de_panier.quantite <= 0) {
          textToast(t('toast.supprime_panier'), {id:'supprime-panier-toast'});
        } else if (event.data.data.message === 'warning_multiple') {
          // quantité souhaitée n'est pas un multiple de la quantité de commande client
          textToast(<Trans t={t}>{t(`toast.warning_arrondis`, {multiple: multipleCommandeClient})}</Trans>);
        } else if (event.data.data.message) {
          // quantité minimum non respectée
          textToast(<Trans t={t}>{t(`error.${event.data.data.message}`)}</Trans>, {icon:'alert', id:'quantite-minimum-error-toast'});
        } else if (!location.pathname.includes('panier')) { 
          /*
            On ne veut checker la dispo que lorsque le panier n'est pas affiché
            Sur la page panier, on laisse le contrôle panier gérer l'affichage des erreurs
          */
          try {
            const dispo = await event.follow('disponibilite_article').get();
            const ligneDePanier = event.follow('ligne_de_panier');
            if (dispo.data.tolede.disponibilite === 'rupture' || dispo.data.quantite_manquante) {
              NiceModal.show(ModaleRupture, {
                dispo,
                ligneDePanier,
              });
            }
          } catch(_error) {
            textToast(<Trans t={t}>{t('error.controle_dispo')}</Trans>, {icon:'alert', id:'controle-dispo-error-toast'});
          }
        }
      }
    }
  );

  /* 
    commande pour gérer les ruptures partielles
    la quantité maximum qui sert d'ajustement est fournie pas le get-dispo
    il n'est pas utile de le ré-exécuter ici
  */
  const ajuster = useCommand(
    ligneDePanier,
    'c:changer-la-quantite', {
      onError,
      onSuccess: event => {
        /* 
          commande pour gérer les ruptures partielles
          la quantité maximum qui sert d'ajustement est fournie pas le get-dispo
          il n'est pas utile de le ré-exécuter ici
        */
        const quantite = event.data.data.ligne_de_panier.quantite;
        ligneDePanier?.follow('item').get().then(itemCatalogue => {
          textToast(t('toast.quantite_ajustee', {
            quantite,
            unite:  t(`unite.${itemCatalogue.data.tolede.unite_de_commande_client}`, {count: quantite})
          }), {id:'quantite-ajustee-toast'});
        });
      }
    }
  );

  const ligneDePanierCommands = {
    remplacer,
    differer,
    changerLaQuantite,
    ajuster,
    supprimer,
    switchUnite,
  };

  const somethingSubmitting = Object.values(ligneDePanierCommands).some(c => c.isSubmitting);

  return {
    somethingSubmitting,
    ...ligneDePanierCommands
  };
};
