import {dateAvecSlashes} from 'ff-common';
import {CalendarIcon} from '@chakra-ui/icons';
import {Button, HStack, Text, VStack} from '@chakra-ui/react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {HaliRenderOne} from '@synako/haligator';
import {FollowPromiseOne} from '@synako/halitrek';
import {DisponibiliteArticleDesc} from 'api-types/ffconnect-hal';
import {SkeletonChargementRequeteGetDispo} from '@/domains/ligneProduit/SkeletonChargementRequeteGetDispo';
import {Dispatch, SetStateAction} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ModaleLayout} from '../Modale/ModaleLayout';

export const ModaleDiffererLivraison = NiceModal.create<{
  dispoNav:FollowPromiseOne<DisponibiliteArticleDesc>, 
  validateAction:(_v:string)=>void,
  setRefreshing?: Dispatch<SetStateAction<boolean>>
}>(
    ({dispoNav, validateAction, setRefreshing}) => {
      const {t} = useTranslation('common');
      const modal = useModal();

      return <HaliRenderOne nav={dispoNav} 
        skeleton={()=>{
          return <ModaleLayout visible={modal.visible} 
            hide={()=>{modal.remove(); setRefreshing?.(false);}}
            size_desktop='lg'>
            <SkeletonChargementRequeteGetDispo/>
          </ModaleLayout>;
        }}
        render={({data})=>{
          const dateDebut = data.tolede.prochaine_date_livraison_au_plus_tot;
          const onClose = ()=>{modal.remove(); setRefreshing?.(false);}; 
          
          if (!dateDebut) return <ModaleLayout visible={modal.visible} 
            hide={onClose}>
            <Text>{t('error.date_differer_manquante')}</Text>
          </ModaleLayout>;

          return <ModaleLayout visible={modal.visible} 
            hide={onClose}
            size_desktop='lg'
            headerIcon={<CalendarIcon/>}
            titre={t('ligne_produit.indisponible.differer')}
            footer={
              <HStack justifyContent='start'>
                <Button colorScheme='blue' flex='1' mr={3} onClick={()=>{
                  validateAction(dateDebut);
                  modal.remove();
                }}>
                  {t('ligne_produit.indisponible.differer')}
                </Button>
                <Button bg='gray.light' flex='1' onClick={onClose}>{t('global.annuler')}</Button>
              </HStack>
            }
          >
            <VStack alignItems='stretch' spacing='5'>
              <Text>
                <Trans t={t}>{t('modale_differer.deplacement', {date_de_livraison: dateAvecSlashes(dateDebut)})}</Trans>
              </Text>
            </VStack>
          </ModaleLayout>;
        }}/>;
    }
    );
