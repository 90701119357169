import {Box, Stack, VStack} from '@chakra-ui/layout';
import {CadencierHeader} from './components/CadencierHeader';
import {HaliRenderAll, useHalState, useHalStates} from '@synako/haligator';
import {useCadencier} from '@/haligator-factory';
import {useMemo, useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import {routes} from 'ff-common';
import {ArrowForwardIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {usePanier} from 'ff-common';
import {BlueButton} from '@/components/Button/BlueButton';
import {Skeleton} from '@chakra-ui/react';
import {CadencierSection} from './controllers/CadencierSection';
import {CadencierTitreNombreDeProduits} from './components/CadencierTitreNombreDeProduits';
import {ItemListeSelectionDesc} from 'api-types/ffconnect-hal';
import {TriSelection} from 'api-types/ffconnect';
import {State} from '@synako/halitrek';
import React from 'react';
import {BreakPointContext} from '@/chakra/BreakpointProvider';

export const MonCadencier: React.FC = () => {
  const cadencierHook = useCadencier();
  const cadencierSelectionHook = cadencierHook.followAll('selection');
  const [editMode, setEditMode] = useState<boolean>(false);

  // console.log('RERENDER : MonCadencier');

  return (
    <HaliRenderAll all={[cadencierHook, cadencierSelectionHook]} 
      skeleton={()=><Skeleton width='100%' height='10' m='5'/>}
      render={({states, data}) => {
        const cadencier = data[0];
        const items = states[1];
        return (
          <Box bg='gray_bg' py={{base:'0', lg:'10'}} px={{base:'0', md: '2', xl: '20'}}>
            <VStack bg={{lg:'white'}} p={{base:'0', md:'4'}} gap={{base:'0',lg:'10'}} alignItems='start' position='relative'>
              <Stack gap='0' flexDirection='column' justify='start'
                width='100%' alignItems='baseline'
                bg='white' display={{base:'block', md:'none'}} position='sticky' top='114px' zIndex='sticky'>
                <CadencierTitreNombreDeProduits nombreDeProduits={cadencier!.nombre_articles} />
              </Stack>
              <Box id='cadencier_header_container' width="100%" zIndex='dropdown' position={{md:'sticky'}} top={{md:'120px'}} bg='white'>
                <CadencierHeader
                  nombreDeProduits={cadencier!.nombre_articles}
                  editMode={editMode}
                  setEditMode={setEditMode}
                  categories={cadencier?.tri_selection.map(cat => ({
                    categorieId: cat.categorieId,
                    categorie: cat.categorie
                  })) ?? []}
                />
              </Box>
              <Box id='cadencier_body_container' width="100%">
                <CadencierBody items={items} tri_selection={cadencier?.tri_selection} editMode={editMode}/>
              </Box>
              <ActiveGoToPanier />
            </VStack>
          </Box>
        );
      }} />
  );
};

const ActiveGoToPanier = () => {
  const {t} = useTranslation('common');
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {state: panier} = useHalState(usePanier());
  return <Box m='5' ml='auto'>
    {panier && 
      <Link to={buildAssistanceRoute(routes.client.panier, {date_panier_actif: panier.data.livraison_tolede.date_de_livraison})}>
        <BlueButton texte={t('cadencier.cta_panier')} rightIcon={<ArrowForwardIcon/>} p='7' m='2'/>
      </Link>
    }
  </Box>;
};

const CadencierBody:React.FC<{
  tri_selection:TriSelection|undefined,
  items:State<ItemListeSelectionDesc>[] | undefined,
  editMode:boolean
}> = ({tri_selection, items, editMode}) => {
  const lignesHook = useHalStates(usePanier().followAll('lignes_de_panier'));
  const TriSelection = useMemo(()=>tri_selection?.map((cat) => {
    return {
      categorieId: cat.categorieId,
      title: cat.categorie,
      lignes:lignesHook.states?.filter((l)=>cat.articles.includes(l.data.code_article)) ?? [],
      produits: items?.filter((p) => cat.articles.includes(p.data.tolede.code_article)) ?? []
    };
  }),[items, lignesHook.states, tri_selection]);

  // console.log('RERENDER : CadencierBody');

  const view = useContext(BreakPointContext);

  return !items || !tri_selection
    ? <Skeleton width='100%' height='10' m='5'/>
    : TriSelection?.map(({categorieId, lignes, produits, title}) => {
      return (
        <CadencierSection
          id={categorieId}
          key={categorieId}
          title={title}
          produits={produits}
          editMode={editMode}
          lignesDePanier={lignes}
          isMobile={!!view?.isMobile}
        />
      );
    });
};
