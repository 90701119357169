import {BoxProps} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';

export const scaleOnHover = {
  transitionDuration: '0.5s',
  css: {
    img: {
      transitionDuration: '0.5s',
    },
    '&:hover img': {
      transform: 'scale(1.1)',
    },
  },
};

export const scrollXMandatory: BoxProps = {
  overflowX: 'scroll',
  overflowY: 'hidden',
  scrollBehavior: 'smooth',
  scrollSnapType: 'x mandatory',
  maxW: '100%',
  sx: {
    scrollbarWidth: 0,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '> *': {
      scrollSnapAlign: 'start',
    },
    '> div': {
      maxW: '90%',
    },
  },
};

export const sxPrint = (sx: SystemStyleObject) => ({
  '@media print': sx,
});

export const sxHideOnPrint = sxPrint({display: 'none'});
