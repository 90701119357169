import {HStack} from '@chakra-ui/layout';
import {Image, Skeleton, Tag, Tooltip} from '@chakra-ui/react';
import {HaliRenderOne} from '@synako/haligator';
import {useLabelAsset} from '@/haligator-factory';
import {Label} from 'api-types/ffconnect';
import * as tolede from 'api-types/tolede';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';

const LabelImage: React.FC<{label: Label}> = ({label}) => {
  return (
    <HaliRenderOne 
      nav={useLabelAsset(label.code)}
      skeleton={() => <Skeleton />}
      fallback={() => <Tag fontSize='3xs'>{label.libelle}</Tag>}
      render={({data}) => {
        const labelImage = imageOptimisee(data.url, 22);
        return(
          <Tooltip label={label.libelle}>
            <Image
              boxSize="22px"
              src={labelImage}
              alt={label.libelle}
            />
          </Tooltip>
        );
      }}
    />
  );
};

export const DisplayLabels: React.FC<{SIQO_et_regimes_alimentaires: tolede.Article['SIQO_et_regimes_alimentaires'] | undefined}> = ({SIQO_et_regimes_alimentaires}) => {
  return (
    <HStack spacing={2}>
      {SIQO_et_regimes_alimentaires?.map((label) => (
        <LabelImage key={label.code} label={label} />
      ))}
    </HStack>
  );
};
