import {Box, SimpleGrid} from '@chakra-ui/react';
import {useParams} from 'react-router-dom';
import {useHalState} from '@synako/haligator';
import {useClient} from '@/haligator-factory';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {ImagePrincipaleDuProduit} from '@/domains/mediatheque/ImagePrincipaleDuProduit';
import {State} from '@synako/halitrek';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {ProduitBreadCrumb} from './ProduitBreadCrumb';
import {ProduitResume} from './ProduitResume';
import {ProduitTable} from './ProduitTable';
import ColdIcon from '@/components/svg/ColdIcon';

export const ProduitView: React.FC = () => {
  const {code_article} = useParams();

  const item = useHalState(useClient().follow('go:item_catalogue', {code_article}));
 
  return <CheckLoaded hook={item} render={({state}) => (
    <Box bg='white.100' p={{base: '', md:'30px 30px 30px 40px'}}>
      <ProductItem itemState={state} />
    </Box>
  )} />;
};

const ProductItem: React.FC<{itemState: State<ItemCatalogueDesc>}> = ({itemState}) => {
  const articleHook = useHalState(itemState.follow('article'));
  const montant_ht = itemState.data.tolede.prix_par_livraison![0].montant_ht;

  return <CheckLoaded hook={articleHook} render={({state}) => {
    return (
      <>
        <ProduitBreadCrumb
          libelle={state.data.tolede.libelle_commercial}
          marque={state.data.tolede.marque?.[0]}
          categorie={state.data.cat_web[state.data.cat_web.length - 1]}
        />
        <SimpleGrid columns={{base:1, md:2}} spacing={6}>
          <Box>
            <ImagePrincipaleDuProduit articleState={state} itemState={itemState}/>
            <Box ml='20px'>{state.data.temperature_livraison === 'negatif' && <ColdIcon boxSize="54px" />}</Box>
          </Box>
          <ProduitResume 
            itemState={itemState}
            prixHT={montant_ht}
            tva={itemState.data.tolede.taux_de_tva}
          />
          <ProduitTable articleState={state}/>
        </SimpleGrid>
      </>
    );}}/>;
};
