import * as tolede from 'api-types/tolede';

export const guessTitreAdresseDeLivraison = (tolede: tolede.AdresseDeLivraison) => {
  let nom_client: string | undefined;
  if (tolede.nom) {
    // le champ enseigne peut contenir le nom du contact sans lui être égal
    // ex :
    //  enseigne: M YOHANN LORENT-A LA BOUTIQUE DU FROMAGER
    //  nom: M YOHANN LORENT
    if (tolede.enseigne && !tolede.enseigne.includes(tolede.nom)) {
      if (tolede.prenom) {
        nom_client = `${tolede.prenom} ${tolede.nom}`;
      }
      nom_client = tolede.nom;
    }
  }
  return {
    nom_client,
    enseigne: tolede.enseigne
  };
};
