import {useState, useEffect} from 'react';
import {useSearchClient} from './use-search';

export const usePromoMpSearch = () => {
  const search = useSearchClient();
  const [itemResultsPromo, setItemResultsPromo] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [itemResultsMP, setItemResultsMP] = useState<Awaited<ReturnType<typeof search.searchItems>>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (search.indexIsBuilt) {
      setIsLoading(true);
      Promise.all([
        search.searchItems({nature_du_prix: 'promo'}),
        search.searchItems({est_MP: true}),
      ])
        .then(([promoResults, mpResults]) => {
          setItemResultsPromo(promoResults);
          setItemResultsMP(mpResults);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.indexIsBuilt]);

  const promoDispo = itemResultsPromo?.hits && itemResultsPromo.hits.length > 0;

  return {
    itemResultsPromo,
    itemResultsMP,
    isLoading,
    promoDispo,
  };
};
