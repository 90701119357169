import {drawerAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const {defineMultiStyleConfig} = createMultiStyleConfigHelpers(drawerAnatomy.keys);

export const DrawerSize = defineMultiStyleConfig({
  sizes: {
    sidebar1: {dialog: {maxWidth:'23rem', w:'23rem'}},
    sidebar2: {dialog: {maxWidth:'46rem', w:'46rem'}},
    sidebar3: {dialog: {maxWidth:'69rem', w:'69rem'}},
  }
});
