import {useParams} from 'react-router-dom';
import {buildRoute, Route} from '@synako/enhanced-router';
import {useCallback} from 'react';

export const useIsAssistanceMode = () => {
  const params = useParams<{id_client_assistance?: string}>();
  const isAssistanceMode = !!params.id_client_assistance;
  const assistanceClientId = params.id_client_assistance;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildAssistanceRoute = useCallback((route: Route<any>, routeParams = {}, query = {}) => {
    // prend en props :
    // - route : chemin d'origin
    // routeParams : exemple => code_article
    // query : exemple => {from: 'recherche', query: encodeURI(`?q=${term}`)}
    if (isAssistanceMode) {
      // construit le chemin d'assistance en remplaçant '/ffconnect' par '/ffconnect/backoffice/assistance/client/:id_client_assistance'
      const assistancePath = route.path.replace('/ffconnect', `/ffconnect/backoffice/assistance/client/${assistanceClientId}`);
      return buildRoute({...route, path: assistancePath}, routeParams, query);
    }
    // pour les routes normales, on garde le chemin d'origine
    return buildRoute(route, routeParams, query);
  }, [assistanceClientId, isAssistanceMode]);

  return {isAssistanceMode, assistanceClientId, buildAssistanceRoute};
};
