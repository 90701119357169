import {Icon, ResponsiveValue} from '@chakra-ui/react';

export const ChevronLeftIcon: React.FC<{
  color?:string;
  width?:ResponsiveValue<string>;
  height?:ResponsiveValue<string>;
}> = ({color='blue.main', width='20px', height='20px'}) => {
  return (
    <Icon
      width={width}
      height={height} 
      viewBox="0 0 20 20" 
      fill={color}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M13.0889 4.41058C13.4144 4.73602 13.4144 5.26366 13.0889 5.58909L8.67819 9.99984L13.0889 14.4106C13.4144 14.736 13.4144 15.2637 13.0889 15.5891C12.7635 15.9145 12.2359 15.9145 11.9104 15.5891L6.91042 10.5891C6.58498 10.2637 6.58498 9.73602 6.91042 9.41058L11.9104 4.41058C12.2359 4.08514 12.7635 4.08514 13.0889 4.41058Z"/>
    </Icon>
  );
};