const variant50 ={
  width: '50%',
  fontSize: 'lg',
  fontWeight: '500',
};
const variant100 ={
  width: '100%',
  fontSize: 'lg',
  fontWeight: '500',
};
const defaults = {
  w50: variant50,
  w100: variant100,
};

export const Text = {
  variants: {...defaults},
};
