import {Icon} from '@chakra-ui/react';

export const SwitchIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='gray.dark', width='18', height='18'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 18 18" 
      fill={color}
    >
      <path id="Vector" d="M10.7198 1.71974C10.8604 1.57914 11.0511 1.50015 11.25 1.50015C11.4489 1.50015 11.6396 1.57914 11.7802 1.71974L14.7803 4.71974C14.9209 4.86039 14.9998 5.05112 14.9998 5.24999C14.9998 5.44887 14.9209 5.6396 14.7803 5.78024L11.7802 8.78024C11.6388 8.91686 11.4493 8.99246 11.2527 8.99075C11.0561 8.98904 10.8679 8.91016 10.7289 8.77111C10.5898 8.63205 10.511 8.44394 10.5092 8.24729C10.5075 8.05065 10.5831 7.86119 10.7198 7.71974L12.4395 5.99999H3.75C3.55109 5.99999 3.36032 5.92097 3.21967 5.78032C3.07902 5.63967 3 5.4489 3 5.24999C3 5.05108 3.07902 4.86031 3.21967 4.71966C3.36032 4.57901 3.55109 4.49999 3.75 4.49999H12.4395L10.7198 2.78024C10.5791 2.6396 10.5002 2.44887 10.5002 2.24999C10.5002 2.05112 10.5791 1.86039 10.7198 1.71974ZM7.28025 9.21974C7.42085 9.36039 7.49984 9.55112 7.49984 9.74999C7.49984 9.94887 7.42085 10.1396 7.28025 10.2802L5.5605 12H14.25C14.4489 12 14.6397 12.079 14.7803 12.2197C14.921 12.3603 15 12.5511 15 12.75C15 12.9489 14.921 13.1397 14.7803 13.2803C14.6397 13.421 14.4489 13.5 14.25 13.5H5.5605L7.28025 15.2197C7.41687 15.3612 7.49246 15.5506 7.49076 15.7473C7.48905 15.9439 7.41017 16.1321 7.27111 16.2711C7.13206 16.4102 6.94395 16.489 6.7473 16.4907C6.55065 16.4925 6.3612 16.4169 6.21975 16.2802L3.21975 13.2802C3.07915 13.1396 3.00016 12.9489 3.00016 12.75C3.00016 12.5511 3.07915 12.3604 3.21975 12.2197L6.21975 9.21974C6.3604 9.07914 6.55113 9.00015 6.75 9.00015C6.94887 9.00015 7.1396 9.07914 7.28025 9.21974Z"/>
    </Icon>
  );
};