import {useContext} from 'react';
import {LivraisonsPossiblesContext, LivraisonsPossiblesContextValue} from '../LivraisonsPossiblesContextProvider';

export const useLivraisonsPossiblesContext = () => {
  return useContext(LivraisonsPossiblesContext) as LivraisonsPossiblesContextValue;
};

export const usePanier = () => {
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  return livraisonSelectionnee.follow('panier_ouvert');
};