import {HStack, VStack, Text, StackProps} from '@chakra-ui/layout';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {toDecimalFr} from 'ff-common';
import {Unite} from 'api-types/ffconnect';
import {TypographyProps} from '@chakra-ui/system';

//Utilisé dans le cadencier, où on veut mettre en avant le prix unitaire
export const PrixBlockUnitaire: React.FC<{
  prixUnitaire: number;
  uniteFacturation: Unite;
  tauxTVA: number | undefined;
  style?: StackProps;
  fontSizeMain?:TypographyProps['fontSize'];
  fontSizeSecondary?:TypographyProps['fontSize'];
}> = ({prixUnitaire, uniteFacturation, tauxTVA, 
  style, fontSizeMain='xl', fontSizeSecondary='xs'}) => {
  const {t} = useTranslation('common');
  return <VStack minW='9em' alignItems='start' gap='0' mt='15px' 
    fontSize={fontSizeSecondary}
    {...style}>
    <HStack gap='0.5' alignItems='start'>
      <Text textColor='black.text' fontSize={fontSizeMain} fontWeight='700'>{toDecimalFr(prixUnitaire, 2, 3)}</Text>;
      <Text>€</Text>
      <Text>{t('ligne_produit.HT')}/{t(`unite.${uniteFacturation}`, {count: 1})}</Text>
    </HStack>
    {tauxTVA && <Text color='gray.dark'>
      {t('ligne_produit.tva_percent', {tauxTVA})}
    </Text>}
  </VStack>;
};
