import {dateEnTexte} from 'ff-common';
import {Text} from '@chakra-ui/react';
import {Trans, useTranslation} from 'react-i18next';

export const CommandeDateTexte: React.FC<{date_de_livraison: string; color: string; fontSize: string | {base: string, sm: string, md: string}}> = ({
  date_de_livraison,
  color,
  fontSize,
}) => {
  const {t} = useTranslation('common');
  return (
    <Text
      color={color}
      fontSize={fontSize}
      fontStyle='normal'
      fontWeight={400}
    >
      <Trans components={{Text: <Text as="strong" fontWeight={700} wordBreak='keep-all' whiteSpace='nowrap'/>}}
      >{t('header_band.temps_restant', {date_de_livraison: dateEnTexte(date_de_livraison)})}</Trans>
    </Text>
  );
};
