import {Icon} from '@chakra-ui/react';

export const CheckCircleToastIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='green.success', width='20px', height='20px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill={color}
    >
      <g id="fi:check-circle" clipPath="url(#clip0_5178_70361)">
        <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M13.0527 3.14451C11.5754 2.48627 9.92486 2.3232 8.34731 2.67962C6.76975 3.03605 5.34968 3.89288 4.29887 5.12232C3.24807 6.35176 2.62284 7.88794 2.51642 9.50175C2.41001 11.1156 2.82811 12.7205 3.70838 14.0773C4.58865 15.4341 5.88392 16.47 7.40102 17.0305C8.91811 17.5909 10.5757 17.646 12.1267 17.1874C13.6776 16.7289 15.0388 15.7812 16.0072 14.4859C16.9755 13.1905 17.4992 11.6168 17.5002 9.99951V9.23332C17.5002 8.77309 17.8733 8.39999 18.3335 8.39999C18.7937 8.39999 19.1668 8.77309 19.1668 9.23332V9.99999C19.1657 11.9767 18.5256 13.9006 17.3421 15.4838C16.1585 17.067 14.4948 18.2252 12.5992 18.7857C10.7036 19.3462 8.67765 19.2789 6.82343 18.5938C4.9692 17.9088 3.3861 16.6427 2.31021 14.9844C1.23432 13.3262 0.723305 11.3645 0.853366 9.39209C0.983428 7.41965 1.7476 5.5421 3.03192 4.03945C4.31624 2.5368 6.05188 1.48957 7.98 1.05393C9.90812 0.6183 11.9254 0.817608 13.731 1.62213C14.1514 1.80945 14.3403 2.3021 14.153 2.72249C13.9657 3.14288 13.4731 3.33183 13.0527 3.14451Z"/>
        <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M18.9221 2.74378C19.2477 3.06906 19.248 3.59669 18.9227 3.92229L10.5894 12.264C10.4331 12.4204 10.2211 12.5083 10 12.5083C9.77896 12.5084 9.56691 12.4206 9.41058 12.2643L6.91058 9.76426C6.58514 9.43882 6.58514 8.91118 6.91058 8.58574C7.23602 8.26031 7.76366 8.26031 8.08909 8.58574L9.99954 10.4962L17.7436 2.74437C18.0689 2.41877 18.5965 2.41851 18.9221 2.74378Z"/>
      </g>
      <defs>
        <clipPath id="clip0_5178_70361">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </Icon>
  );};
