import {Icon, ResponsiveValue} from '@chakra-ui/react';

export const AvoirIcon: React.FC<{
  color?:string;
  width?:ResponsiveValue<string>;
  height?:ResponsiveValue<string>;
}> = ({color='gray.dark', width='16', height='17'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 16 17" 
      fill={color}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M7.4 0C5.26365 0.00609349 3.21124 0.832428 1.66667 2.30833V0.833333C1.66667 0.61232 1.57887 0.400358 1.42259 0.244078C1.26631 0.0877973 1.05435 0 0.833333 0C0.61232 0 0.400358 0.0877973 0.244078 0.244078C0.0877973 0.400358 0 0.61232 0 0.833333V4.58333C0 4.80435 0.0877973 5.01631 0.244078 5.17259C0.400358 5.32887 0.61232 5.41667 0.833333 5.41667H4.58333C4.80435 5.41667 5.01631 5.32887 5.17259 5.17259C5.32887 5.01631 5.41667 4.80435 5.41667 4.58333C5.41667 4.36232 5.32887 4.15036 5.17259 3.99408C5.01631 3.8378 4.80435 3.75 4.58333 3.75H2.58333C3.34309 2.94751 4.29042 2.34647 5.34011 2.00095C6.38979 1.65543 7.50891 1.57627 8.59678 1.77059C9.68465 1.9649 10.7072 2.42661 11.5723 3.11416C12.4375 3.80171 13.1181 4.69355 13.5531 5.70944C13.988 6.72534 14.1636 7.83342 14.0641 8.93402C13.9645 10.0346 13.5929 11.0932 12.9827 12.0145C12.3725 12.9359 11.5428 13.6911 10.5683 14.2122C9.59384 14.7334 8.50508 15.0041 7.4 15C7.17899 15 6.96702 15.0878 6.81074 15.2441C6.65446 15.4004 6.56667 15.6123 6.56667 15.8333C6.56667 16.0543 6.65446 16.2663 6.81074 16.4226C6.96702 16.5789 7.17899 16.6667 7.4 16.6667C9.61014 16.6667 11.7298 15.7887 13.2926 14.2259C14.8554 12.6631 15.7333 10.5435 15.7333 8.33333C15.7333 6.1232 14.8554 4.00358 13.2926 2.44078C11.7298 0.877974 9.61014 0 7.4 0ZM7.4 5C7.17899 5 6.96702 5.0878 6.81074 5.24408C6.65446 5.40036 6.56667 5.61232 6.56667 5.83333V8.33333C6.56667 8.55435 6.65446 8.76631 6.81074 8.92259C6.96702 9.07887 7.17899 9.16667 7.4 9.16667H9.06667C9.28768 9.16667 9.49964 9.07887 9.65592 8.92259C9.8122 8.76631 9.9 8.55435 9.9 8.33333C9.9 8.11232 9.8122 7.90036 9.65592 7.74408C9.49964 7.5878 9.28768 7.5 9.06667 7.5H8.23333V5.83333C8.23333 5.61232 8.14554 5.40036 7.98926 5.24408C7.83297 5.0878 7.62101 5 7.4 5Z"/>

    </Icon>
  );
};