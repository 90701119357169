import {Icon} from '@chakra-ui/react';

export const WarningSignIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='red.warning', width='24px', height='21px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 24 21" 
      fill={color}
    >
      <path d="M23.7299 18.0474C24.499 19.3597 23.5336 21 21.9975 21H2.00226C0.46322 21 -0.497696 19.3571 0.269887 18.0474L10.2676 0.98376C11.0371 -0.329109 12.9643 -0.32673 13.7324 0.98376L23.7299 18.0474ZM12 14.5195C10.9415 14.5195 10.0833 15.3642 10.0833 16.4062C10.0833 17.4483 10.9415 18.293 12 18.293C13.0585 18.293 13.9167 17.4483 13.9167 16.4062C13.9167 15.3642 13.0585 14.5195 12 14.5195ZM10.1803 7.73776L10.4894 13.3159C10.5038 13.5769 10.7231 13.7812 10.9886 13.7812H13.0114C13.2769 13.7812 13.4962 13.5769 13.5106 13.3159L13.8197 7.73776C13.8353 7.45582 13.6073 7.21875 13.3205 7.21875H10.6795C10.3927 7.21875 10.1647 7.45582 10.1803 7.73776Z"/>
    </Icon>
  );
};