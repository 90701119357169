import {VStack, SimpleGrid} from '@chakra-ui/react';
import {CatalogProductDetails} from './components/CatalogProductTemplate/CatalogProductDetails';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {Result} from '@orama/orama';
import {Location} from 'react-router-dom';
import {useContext} from 'react';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {usePanier} from 'ff-common';
import {useHalStates} from '@synako/haligator';

interface CatalogProductsGridProps {
  hitResult: Result<HitItemCatalogue>[];
  location: Location;
}

export const CatalogProductsGrid: React.FC<CatalogProductsGridProps> = ({
  hitResult,
  location,
}) => {
  const view = useContext(BreakPointContext);
  const columnsDefine = {base: 1, sm: 2, md: 3, lg: 4, xl: 5, '2xl': 6};
  const columns = columnsDefine[view?.breakpoint ?? 'base'];

  const {states:lignesDePanier} = useHalStates(usePanier().followAll('lignes_de_panier'));

  const productItems = hitResult.map((produit) => {
    return <CatalogProductDetails
      hit={produit.document}
      key={produit.id}
      location={location}
      ligneDePanier={lignesDePanier?.find((l)=>l.data.code_article===produit.document.code)}
    />;
  });

  return !view?.isMobile ?
    /* Version desktop */
    <SimpleGrid display={{base:'none', 'md':'grid'}}
      columns={columns} spacing="0" m="20px" bg="white.100" gap="0" sx={{
        [`& > *:nth-of-type(${columns}n), & > *:nth-last-of-type(1)`]:{
          borderRight: '1px solid',
          borderColor: 'gray.light',
        },
        [`& > *:nth-last-of-type(-n+${columns})`]: {
          borderBottom: '1px solid',
          borderColor: 'gray.light',
        }
      }}>
      {productItems}
    </SimpleGrid>

    : /* Version mobile */
    <VStack gap="0" display={{base:'flex', 'md':'none'}}>
      {productItems}
    </VStack>;
};
