import {MouseEvent} from 'react';
import {GridItem, HStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {DateButton} from './DateButton';
import {selectedProps} from '../calendar-utils';

export const CalendarCellLivraison: React.FC<{
  date: Date,
  isSelected: boolean
  livraisonAssociee: State<LivraisonDesc>,
  handleDateClick: (livraison: State<LivraisonDesc>, event: MouseEvent<HTMLButtonElement>) => void
}> = ({
  date,
  isSelected,
  livraisonAssociee,
  handleDateClick
}) => {
  return (
    <GridItem as={HStack} alignItems='stretch' justify='center'>
      <DateButton 
        date={date} 
        {...selectedProps(isSelected)} 
        onClick={event => {
          handleDateClick(livraisonAssociee, event);
        }} 
      />
    </GridItem>
  );
};
