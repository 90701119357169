import {Box, Heading, VStack, Text, HStack, Avatar, Button, SimpleGrid, Link} from '@chakra-ui/react';
import {PhoneBlackIcon} from '@/components/svg/PhoneBlackIcon';
import {MailIcon} from '@/components/svg/MailIcon';
import {CheckIcon, CopyIcon} from '@chakra-ui/icons';
import {useRepresentants, useSocieteCommerciale} from '@/haligator-factory';
import {HaliRenderMany, useHalState} from '@synako/haligator';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';

const BoutonCopie: React.FC<{texte: string}> = ({texte}) => {
  const [copieFeedback, setCopieFeedback] = useState(false);

  const handleCopy = (texte: string) => {
    navigator.clipboard.writeText(texte);

    setCopieFeedback(true);

    setTimeout(() => {
      setCopieFeedback(false);
    }, 1000);
  };
  
  return (
    <Button p={0} bg="transparent" isDisabled={!texte} _hover={{bg: 'transparent'}}
      onClick={() => {handleCopy(texte);}}>
      {copieFeedback ? <CheckIcon/> : <CopyIcon color="black" />}
    </Button>
  );
};

const Representant: React.FC<{nom: string, src:string | undefined, email:string | undefined, telephone:string, statut:string}> = ({nom, src, email, telephone, statut}) => {
  const {t} = useTranslation('common');

  return (
    <VStack alignItems="start">
      <Text fontWeight="700" color="blue.main" mb={3}>{statut}</Text>
      <HStack fontSize="sm" alignItems="center" spacing={5} mb={2}>
        <Avatar size='md' name={nom} src={src}/>
        <Text fontWeight="500">{nom}</Text>
      </HStack>
      <VStack alignItems="start" spacing={3} ml={{base:'0px', md:'70px'}} width="100%">
        <HStack spacing={0} width="100%" overflow="hidden">
          <MailIcon />
          <HStack flex="1" minWidth={0} overflow="hidden">
            <Text 
              whiteSpace="nowrap" 
              overflow="hidden" 
              textOverflow="ellipsis" 
              fontSize="sm" 
              pl="6px"
            >
              {email ? (
                <Link href={`mailto:${email}`} fontSize="sm">
                  {email}
                </Link>
              ) : (
                t('contact.non_renseigne')
              )}
            </Text>
            <BoutonCopie texte={email ?? ''} />
          </HStack>
        </HStack>
        <HStack>
          <PhoneBlackIcon/>
          <Text fontSize='sm'>{telephone}</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

export const RepresentantsView: React.FC = () => {
  const societeCommercialeHook = useHalState(useSocieteCommerciale());
  const {t} = useTranslation('common');

  return (
    <Box display="flex" bg="gray.bg" overflowX="hidden" width="100%"> {/* globale */}
      <Box p={5} mr={{base:'0px', md:'20px', xl:'40px', '2xl':'65px'}} ml={{base:'0px', md:'20px', xl:'40px', '2xl':'65px'}} width="100%">
        <Heading size={{base:'md', md:'lg'}} ml={{base:'30px', md:'0'}} mb={6} mt="10px" color="black.text">{t('menu.contacts')}</Heading>
        <SimpleGrid columns={{base:1, lg:2}} spacing={{base: 7, lg:5, xl:10}}>
          <HaliRenderMany
            many={useRepresentants().followAll('items')}
            render={({data}) => data.map((contact) => (
              <Box key={contact.id} bg="white" borderRadius="8px" borderWidth="1px" borderColor="gray.light" p={7} width="100%">
                <Representant
                  nom={`${contact.tolede.individu.prenom ?? ''} ${contact.tolede.individu.nom ?? ''}`}
                  src=''
                  email={contact.tolede.individu.email}
                  telephone={contact.tolede.individu.telephone ?? t('contact.non_renseigne')}
                  statut={t(`contact.${contact.tolede.en_tant_que}`)}
                />
              </Box>
              
            ))}/>
          <CheckLoaded
            hook={societeCommercialeHook}
            render={({data})=>{
              return (
                <Box bg="white" borderRadius="8px" borderWidth="1px" borderColor="gray.light" p={7}>
                  <Representant
                    nom={data.tolede.nom_commercial}
                    src={data.url_logo ?? data.couleur}
                    email={data.email}
                    telephone={data.telephone ?? t('contact.non_renseigne')}
                    statut={t('contact.societe_commerciale')}/>
                </Box>
              );
            }}/>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default RepresentantsView;
