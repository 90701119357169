import {Box, HStack, Image, Skeleton, Stack, Text, VStack} from '@chakra-ui/react';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {useApi, useClient} from '@/haligator-factory';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {routes} from 'ff-common';
import {DisplayLabels} from '@/domains/panier/components/DisplayLabels';
import {HitItemCatalogue} from 'api-types/ffconnect';
import {PromoIcon} from '@/components/svg/PromoIcon';
import {LabelDisponibilite} from '@/domains/catalogue/components/LabelDisponibilite';
import {encodeURI} from '@/hooks/useFromFilters';
import {Location} from 'react-router-dom';
import {ListeDrapeaux} from '@/domains/panier/components/listeDrapeaux';
import {useContext} from 'react';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {imageOptimisee} from '@/domains/mediatheque/optimisation';
import {useBuyingBlocks} from '@/hooks/panier/useBuyingBlocks';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import ColdIcon from '@/components/svg/ColdIcon';
import {PrixBlockUnitaire} from '@/domains/cadencier/components/PrixBlockUnitaire';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import React from 'react';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';

interface CatalogProductDetailsProps {
  hit: HitItemCatalogue;
  location: Location;
  ligneDePanier:State<LigneDePanierDesc>|undefined
}

export const ItemSkeletonMobile: React.FC = () => (
  <>
    <HStack justifyContent='space-between' width='100%'>
      <Box mt='5px' ml='auto' maxW='400px' minW='164px' flex='1'>
        <Skeleton height='40px' width='100%' />
      </Box>
      <VStack height='40px' width='100%' alignItems='flex-end' gap='0' mx='5' flex='2'>
        <HStack gap='0.5' alignItems='start'>
          <Skeleton height='24px' width='80px' />
          <Skeleton height='16px' width='20px' />
        </HStack>
        <Skeleton height='11px' width='60px' />
      </VStack>
    </HStack>
    <HStack height='94px' width='100%' flexGrow={1}>
      <Skeleton height='40px' width='100%' />
    </HStack>
  </>
);

const ItemSkeletonDesktop: React.FC = () => (
  <>
    <HStack justifyContent='end' width='100%' mt='auto'>
      <VStack height='40px' alignItems='flex-end' gap='0' mt='10px'>
        <HStack gap='0.5' alignItems='start'>
          <Skeleton height='24px' width='80px' />
          <Skeleton height='16px' width='20px' />
          <Skeleton height='16px' width='60px' />
        </HStack>
        <Skeleton height='11px' width='100px' />
      </VStack>
    </HStack>
    <Box mt='15px' width='100%'>
      <Skeleton height='40px' width='100%' />
    </Box>
    <HStack height='94px' width='100%' flexGrow={1}>
      <Skeleton height='40px' width='100%' />
    </HStack>
  </>
);

export const CatalogProductDetails: React.FC<CatalogProductDetailsProps> = React.memo(({
  hit,
  location,
  ligneDePanier
}) => {
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const code_article = hit.code;
  const {t} = useTranslation('common');
  const articleNav = useApi().follow('get-article', {code_article});
  const itemNav = useClient().follow('go:item_catalogue', {code_article});
  const item = useHalState(itemNav);
  const isMobile = useContext(BreakPointContext)?.isMobile;

  const precommande = item.data?.tolede.precommande;

  const itemVignette = imageOptimisee(hit.vignette_url, 200);

  const LabelSkeleton: React.FC = () => null;

  const itemLink = () => {
    const from = location.pathname.includes('/recherche') ? 'recherche' : undefined;
    if (from) {
      return buildAssistanceRoute(routes.client.produit, {code_article}, {from, query: encodeURI(location.search)});
    } else {
      return buildAssistanceRoute(routes.client.produit, {code_article});
    }
  };

  const pays = hit.pays_d_origine ?? [];

  // Logique de l'affichage des disponibilités (voir pour V2)
  // Produit A pour B, on affiche rien => verification de disponible_au_plus_tot si la date correspond au lendemain ou si la date est passée et que precommande est false
  // Produits A pour C : on affiche "à partir du XX/XX/XXXX => affichage de disponible_au_plus_tot si la date ne correspond pas au lendemain, si la date n'est pas passé et si et precommand false
  // Produits en précommande : "Précommander à partir du XX/XX/XXXX => si precommande est true affichage de prochaine_date_livraison_au_plus_tot et si la date n'est pas passé
  const textToast = useSimpleTextToast();
  const {buyBlock, uniteQuantiteBlock} = useBuyingBlocks(textToast, item.state, ligneDePanier);
  
  return (
    <>
      {!isMobile ?
      /* Version desktop */
        <VStack minH='450px' maxH='470px' minW='240px' padding="20px"
          border="1px" borderBottom='0' borderRight='0' borderColor='gray.light' position="relative"
          
        >
          {hit.nature_du_prix?.includes('promo') && (
            <HStack position="absolute" top={precommande ? '45px' : '10px'} left="10px"
              bg="red" color="white" fontSize="xs" fontWeight="bold" py="2px" px="8px" borderRadius="md" transition="top 0.3s ease-in-out">
              <PromoIcon color='white'/>
              <Text>{t('global.promotion')}</Text>
            </HStack>
          )}
          <>
            <LabelDisponibilite precommande={precommande} />
            <Link to={itemLink()} style={{textDecoration: 'none', color: 'inherit', display: 'block', width: '100%'}}>
              <Stack justifyContent="center" alignItems="center" width='100%' height='134px' flexGrow={1} mb='10px'>
                <Box width="100%" height="134px" display="flex" justifyContent="center" alignItems="center">
                  <Image src={itemVignette} alt={hit.libelle_commercial} objectFit="contain" maxWidth="100%" maxHeight="100%" width="auto" height="auto" />
                </Box>
              </Stack>
              <Box height='35px'>{hit.temperature_livraison === 'negatif' && <ColdIcon boxSize='25px' mb='20px'/>}</Box>
              <VStack height='80px' width='100%' flexGrow={1} alignItems='rigth'>
                <HStack height='22px' alignItems="center">
                  <Text align='left' color='gray.dark' fontSize='xs' fontWeight='400'>
                    {code_article}
                  </Text>
                  <HaliRenderOne nav={articleNav} skeleton={LabelSkeleton} render={({state: articlestate}) => {
                    return <DisplayLabels articleState={articlestate}/>;
                  }}/>
                  <ListeDrapeaux listepays={pays} max={5}/>
                </HStack>
                <VStack height='70px' alignItems='left'>
                  <Text align='left' color='black' fontSize='sm' lineHeight='18px' fontWeight='600'>{hit.libelle_commercial}</Text>
                  <Text align='left' color='black' fontSize='xs' lineHeight='18px'>{hit.marque}</Text>
                </VStack>
              </VStack>
            </Link>
          </>
          <HaliRenderOne nav={itemNav} skeleton={ItemSkeletonDesktop} render={({data: itemCatalogue}) => {
            const montant_ht = itemCatalogue.tolede.prix_par_livraison?.[0]?.montant_ht;
            return (
              <>
                <HStack justifyContent='end' width='100%' mt='auto'>
                  <VStack alignItems='flex-end' gap='0' mt='10px'>
                    {montant_ht && <PrixBlockUnitaire 
                      prixUnitaire={montant_ht}
                      uniteFacturation={itemCatalogue.tolede.unite_de_facturation}
                      tauxTVA={itemCatalogue.tolede.taux_de_tva}
                      style={{mb:-1, mt:0, minW:0}}
                    />}
                  </VStack>
                </HStack>
                <Box mt='15px' width='100%'>
                  {uniteQuantiteBlock({w:'100%'})}
                </Box>
                <HStack height='94px' width='100%' flexGrow={1} position="relative">
                  {buyBlock({afficher_bouton_liste:true, minW:'100%', mt:'0'})}
                </HStack>
              </>
            );
          }} />
        </VStack>

      /* Version mobile */  
        : <VStack
          height='255px' width='100%' padding="20px" pt='30px' borderBottom="1px"
          borderColor='gray.light' position="relative">
          {hit.nature_du_prix?.includes('promo') && (
            <HStack position="absolute" top={precommande ? '35px' : '10px'} left="12px"
              bg="red" color="white" fontSize="xs" fontWeight="bold" py="2px" px="8px" borderRadius="md" zIndex='docked'>
              <PromoIcon color='white'/>
              <Text>{t('global.promotion')}</Text>
            </HStack>
          )}
          <HStack position="absolute" left='2px' top='0'><LabelDisponibilite precommande={precommande} /></HStack>
          <Link to={itemLink()} style={{textDecoration: 'none', color: 'inherit', display: 'block', width: '100%'}}>
            <HStack height='85px' width='100%' mb='10px' alignItems="flex-start" justifyContent="flex-start" spacing={4}>
              <Box boxSize="90px" display="flex" justifyContent="center" alignItems="center" position="relative">
                <Image src={itemVignette} alt={hit.libelle_commercial} objectFit="contain" maxWidth="100%" maxHeight="100%" width="auto" height="auto" />
                <Box position="absolute" bottom="0" left="0">{hit.temperature_livraison === 'negatif' && <ColdIcon boxSize='25px' />}</Box>
              </Box>
              <VStack alignItems="flex-start" spacing={1}>
                <HStack >
                  <Text align='left' color='gray.dark' fontSize='xs' fontWeight='400'>{code_article}</Text>
                  <HaliRenderOne nav={articleNav} skeleton={LabelSkeleton} render={({state: articlestate}) => {
                    return<DisplayLabels articleState={articlestate}/>;
                  }}/>
                  <ListeDrapeaux listepays={pays} max={9}/>
                </HStack>
                <VStack spacing={0} alignItems='start'>
                  <Text align='left' color='black' fontSize='sm' lineHeight='18px' fontWeight='600'>{hit.libelle_commercial}</Text>
                  <Text align='left' color='black' fontSize='xs' lineHeight='18px'>{hit.marque}</Text>
                </VStack>
              </VStack>
            </HStack>
          </Link>
          <HaliRenderOne nav={itemNav} skeleton={ItemSkeletonMobile} render={({data: itemCatalogue}) => {
            const montant_ht = itemCatalogue.tolede.prix_par_livraison?.[0]?.montant_ht;
            return (
              <>
                <HStack justifyContent='space-between' width='100%'>
                  <Box mt='5px' ml='auto' maxW='400px' minW='164px' flex='1'>
                    {uniteQuantiteBlock()}
                  </Box>
                  <VStack width='100%' alignItems='flex-end' gap='0' mx='5' flex='2'>
                    {montant_ht && <PrixBlockUnitaire 
                      prixUnitaire={montant_ht}
                      uniteFacturation={itemCatalogue.tolede.unite_de_facturation}
                      tauxTVA={itemCatalogue.tolede.taux_de_tva}
                      style={{mt:0, minW:0}}
                    />}
                  </VStack>
                </HStack>
                <HStack height='94px' width='100%' flexGrow={1} position="relative">
                  {buyBlock({afficher_bouton_liste:true, minW:'100%', mt:'0'})}
                </HStack>
              </>
            );
          }} />
        </VStack>
      }
    </>
  );
});
