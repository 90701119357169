import {useCallback} from 'react';
import {useDefaultOnError} from '../useDefaultOnError';
import {State} from '@synako/halitrek';
import {ArticlePanierAjouteEventDesc} from 'api-types/ffconnect-hal';
import {Trans, useTranslation} from 'react-i18next';
import {Button} from '@chakra-ui/react';
import {useCommand} from '@synako/haligator';
import {PanierLink} from '@/domains/panier/controllers/PanierLink';
import {useNavigate} from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import {ModaleRupture} from '@/domains/cadencier/components/ModaleRupture';
import {useIsAssistanceMode} from '../auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {useClient} from '@/haligator-factory';
import {AjouterAuPanierCommand} from 'api-types/ffconnect';
import {TextToastCommand} from 'ff-common/src/types/TextToastCommand';
export type AjouterAuPanier = ReturnType<typeof useAjouterAuPanier>;

export const useAjouterAuPanier = (
  livraison_id: string,
  textToast:TextToastCommand
) => {
  const {t} = useTranslation('common');
  const navigate = useNavigate();
  const onError = useDefaultOnError(textToast);
  const {isAssistanceMode, buildAssistanceRoute} = useIsAssistanceMode();

  const onSuccess = useCallback(async (event: State<ArticlePanierAjouteEventDesc>) => {
    const {date_de_livraison} = event.data.data.livraison.tolede;
    const handleNavigate = () => {
      const url = buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison});
      navigate(url);
    };

    textToast([
      <Trans t={t}>{t('toast.ajoute_panier_date', {date: new Date(date_de_livraison)})}</Trans>,
      isAssistanceMode ? (
        <Button onClick={handleNavigate} variant='unstyled' fontWeight="bold" textDecoration="underline">
          {t('toast.voir_panier')}
        </Button>
      ) : (
        <PanierLink t={t} date_de_livraison={date_de_livraison} navigate={navigate} />
      )
    ], {id:'ajoute-panier-toast'});

    try {
      const dispo = await event.follow('disponibilite_article').get();
      const ligneDePanier = event.follow('ligne_de_panier');
      if (dispo.data.tolede.disponibilite === 'rupture' || dispo.data.quantite_manquante) {
        NiceModal.show(ModaleRupture, {
          dispo,
          ligneDePanier,
          date_de_livraison,
        });
      }
    } catch(_error) {
      textToast(<Trans t={t}>{t('error.controle_dispo')}</Trans>, {icon:'alert', id:'controle-dispo-error-toast'});
    }
  }, [t, textToast, navigate, buildAssistanceRoute, isAssistanceMode]);

  const client = useClient();
  const {submit, submitAsync, ...rest} = useCommand(client,
    'c:ajouter-au-panier', {
      onError,
      onSuccess,
    }
  );
  return {
    ...rest,
    submit: (payload:AjouterAuPanierCommand) => {submit(payload, {livraison_id});},
    submitAsync: (payload:AjouterAuPanierCommand) => submitAsync(payload, {livraison_id})
  };
  // return useCommand(
  //   livraison,
  //   'c:ajouter-au-panier', {
  //     onError,
  //     onSuccess
  //   }
  // );
};
