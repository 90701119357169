import {Box, HStack, VStack} from '@chakra-ui/react';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {PrixBlockUnitaire} from '@/domains/cadencier/components/PrixBlockUnitaire';
import {ProduitDejaCommande} from '@/domains/ligneProduit/ProduitDejaCommande';

export const ProduitBottomBar: React.FC<{
  itemState:State<ItemCatalogueDesc>,
  prixHT:number,
  tva:number,
  buyBlock:React.ReactElement|undefined
}> = ({itemState, prixHT, tva, buyBlock}) => {
  const uniteFacturation = itemState.data.tolede.unite_de_facturation;
  const produitDejaCommandeBlock = <ProduitDejaCommande itemState={itemState} />;

  return (
    <>
      <Box position="fixed" bottom="4.5rem" left={0} right={0} zIndex='dropdown' bg="white" p="10px" borderTopWidth="1px" borderColor="gray.light" shadow="md">
        <HStack p="0px 15px 0px 10px">
          <VStack width="30%" alignItems="flex-start" gap="0" flex="2">
            <PrixBlockUnitaire
              prixUnitaire={prixHT}
              uniteFacturation={uniteFacturation}
              tauxTVA={tva}
              style={{mt:0}}
            />
          </VStack>
          <Box width="62%">
            {buyBlock}
          </Box>
        </HStack>
        <HStack>{produitDejaCommandeBlock}</HStack>
      </Box>
    </>
  );
};
