import {GridItem, HStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {CommandesCollectionDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import {DateButton} from './DateButton';
import {selectedProps} from './selected-props';
import {useClient} from '@/haligator-factory';
import {useHalCollection, UseHalCollectionHook} from '@synako/haligator';
import {useMemo} from 'react';

const useCommandeExistante = (dateDeLivraison: string) => {
  const client = useClient();

  const enCoursResource = useMemo(() => client.follow('commandes', {periode: 'en_cours'}), [client]);

  const enCoursHook = useHalCollection(enCoursResource);

  const commandeExistante = useMemo(() => {
    const checkCommandes = (hook: UseHalCollectionHook<CommandesCollectionDesc, 'items'>) =>
      hook.items?.some(item =>
        item.data.tolede.date_de_livraison_ou_d_enlevement?.split('T')[0] === dateDeLivraison
      );
    return checkCommandes(enCoursHook);
  }, [enCoursHook, dateDeLivraison]);

  return commandeExistante;
};

export const CalendarCellPanier: React.FC<{
  date: Date,
  isSelected: boolean
  livraisonAssociee: State<LivraisonDesc>,
  onDateClick: (livraison: State<LivraisonDesc>) => void
}> = ({
  date,
  isSelected,
  livraisonAssociee,
  onDateClick,
}) => {
  const panierEnCours = livraisonAssociee.hasOneLink('panier_ouvert');
  const dateDeLivraison = livraisonAssociee.data.tolede.date_de_livraison;
  const commandeExistante = useCommandeExistante(dateDeLivraison);

  return(
    <GridItem as={HStack} alignItems='stretch' justify='center'>
      <DateButton
        date={date}
        {...selectedProps(isSelected)}
        panierEnCours={panierEnCours}
        commandeExistante={commandeExistante}
        onClick={() => {
          onDateClick(livraisonAssociee);
        }}
      />
    </GridItem>
  );
};
