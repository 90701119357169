import {radioAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    minW:'46px',
  },
  control: {
    backgroundColor: 'white.100', 
  },
});

export const radioTheme = defineMultiStyleConfig({baseStyle});