import {useHalAll} from '@synako/haligator';
import {Navigable} from '@synako/halitrek';
import {ArticleDesc} from 'api-types/ffconnect-hal';
import {useMemo} from 'react';

export function useVignetteArticle(article: Navigable<ArticleDesc>) {
  const medias = article.follow('medias');
  const all = useMemo(() => ([
    medias.follow('image_principale'),
    medias.follow('vignette'),
  ]), [medias]);
  const {
    loading,
    data: [
      principale,
      vignette,
    ]} = useHalAll(all);
  return loading ? undefined : (vignette?.url ?? principale?.url);
}