
import {ItemCatalogue} from 'api-types/ffconnect';
import {ReactElement} from 'react';
import {toDecimalFr} from '../utils/toDecimalFr';
import {TFunction} from 'i18next';

//Renvoie la liste des options possible pour le SelectUnite
//Uniformise aussi l'affichage des type de quantite possible dans le remplacement
export const useGetSelectUniteOptions = (
  {
    ic,
    quantite_max,
    t
  }:{
    ic: ItemCatalogue['tolede'];
    quantite_max: number | undefined;
    t:TFunction;
  }) => {
  const optionsList: {value: string; texte: ReactElement;}[] = [];

  const commandableEnColis = ic.quantite_colis_entier !== undefined &&
    ic.quantite_colis_entier !== 0 &&
    ic.quantite_colis_entier !== 999999999 &&
    ic.quantite_colis_entier !== ic.multiple_de_commande_client;
  const isDisabled = !commandableEnColis && (!quantite_max || quantite_max < ic.quantite_colis_entier!);

  if (ic.unite_de_commande_client === ic.unite_de_facturation) {
    optionsList.push({
      value: 'unitaire', texte: <>{t('commande.par_multiple', {
        quantite: ic.multiple_de_commande_client,
        unite: t(`unite.${ic.unite_de_facturation}`, {count: ic.multiple_de_commande_client}),
      })}&nbsp;</>
    });
  } else {
    optionsList.push({
      value: 'unitaire', texte: <>
        {// Tu commandes par unite_de_commande_client
          t('commande.par_multiple', {
            quantite: ic.multiple_de_commande_client,
            unite: t(`unite.${ic.unite_de_commande_client}`, {count: ic.multiple_de_commande_client}),
          })}&nbsp;
        {// Et pour chaque unité tu es facturé une quantité correspondante
          t('commande.de_multiple_facturation', {
            multiple_facturation: toDecimalFr(
              ic.coefficient_commande_client_vers_facturation! *
              ic.multiple_de_commande_client,
              0, 2
            ),
            unite_facturation: t(`unite.${ic.unite_de_facturation}`, {count: (ic.coefficient_commande_client_vers_facturation ?? 1) * ic.multiple_de_commande_client})
          })}
      </>
    });
  }

  if (!isDisabled) {
    optionsList.push({
      value: 'colis', texte: <>
        {t('commande.par_colis', {
          quantite: ic.quantite_colis_entier,
          unite: t(`unite.${ic.unite_de_commande_client}`, {count: ic.quantite_colis_entier})
        })}
      </>
    });
  }

  return {optionsList, isDisabled, commandableEnColis};
};
