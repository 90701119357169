import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {dateEnTexte} from 'ff-common';
import {toDecimalFr} from 'ff-common';
import {Box, Button, HStack, Text, VStack} from '@chakra-ui/react';
import {Panier} from 'api-types/ffconnect';
import {TFunction} from 'i18next';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';

export const InfoLivraisonPanierVide: React.FC<{
  panierData: Panier,
  t: TFunction,
}> = ({panierData, t})=>{
  const {buildAssistanceRoute} = useIsAssistanceMode();

  const {
    livraison_tolede: {date_de_livraison},
    total_estime_ttc,
    nombre_de_lignes
  } = panierData;

  return (
    <HStack 
      as={Link} 
      gap='0' 
      cursor='pointer' 
      to={buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison})} 
      textColor='blue.main' 
      backgroundColor={'transparent.0'} 
      p='5' 
      px='6' 
      borderRadius='8px' 
      border='1px solid' 
      borderColor='gray.light'
      _hover={{bg:'gray.bg'}}
      flexWrap='wrap'
    >
      <VStack alignItems='start' mr='5'>
        <Text>
          <Trans t={t}>
            {t('panier.pour_la_livraison_du', {date: dateEnTexte(date_de_livraison)})}
          </Trans>
        </Text>
        <HStack fontSize='sm' flexWrap='wrap'>
          <Box 
            p='1.5' 
            px='2.5' 
            borderRadius='8px' 
            backgroundColor='gray.light' 
            color='blue.main'
          >
            {t('global.produit', {count: nombre_de_lignes})}
          </Box>
          <Box 
            p='1.5' 
            px='2.5' 
            borderRadius='8px' 
            backgroundColor='yellow.warning' 
            color='yellow.warning_text'
          >
            {t('global.a_valider')}
          </Box>
          <HStack color='black.text'>
            <Text>{t('panier.recap.total_ttc')}:</Text>
            <Text>
              <Text as='span' fontWeight='700'>{toDecimalFr(total_estime_ttc)}</Text> €
            </Text>
          </HStack>
        </HStack>
      </VStack>
      <Button 
        variant='outline' 
        colorScheme='blue' 
        borderColor='blue.main' 
        _hover={{backgroundColor:'gray.light'}}
        m='1' 
        ml='auto' 
        w={{base:'100%', md:'initial'}}
      >
        {t('global.voir_mon_panier')}
      </Button>
    </HStack>
  );
};
