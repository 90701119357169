import {CartPlusIcon} from '@/components/svg/CartPlusIcon';
import {Button, Spinner, StyleProps} from '@chakra-ui/react';

export const BuyButton: React.FC<{
  libelle: string,
  onClick: () => void,
  isSubmitting: boolean
} & StyleProps> = ({
  libelle,
  onClick,
  isSubmitting
}) => (
  <Button
    width={{base:'100%', md:'initial'}}
    border='1px'
    borderColor='blue.main'
    bg='white'
    color='blue.main'
    px='8' 
    flex={1}
    leftIcon={<>
      {isSubmitting ? <Spinner color="brand.500" aria-label="Chargement..." /> : <CartPlusIcon />}
    </>} 
    opacity={isSubmitting ? '0.5' : '1.0'} 
    onClick={onClick} 
    isDisabled={isSubmitting}
  >
    {libelle}
  </Button>

);
