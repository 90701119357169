import {Box, Image, HStack, VStack, Text} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {ArticleDesc, ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {useHalState} from '@synako/haligator';
import {PromoIcon} from '@/components/svg/PromoIcon';
import {useTranslation} from 'react-i18next';
import {imageOptimiseeH} from './optimisation';

export const ImagePrincipaleDuProduit: React.FC<{articleState: State<ArticleDesc>, itemState:State<ItemCatalogueDesc>,}>
= ({articleState, itemState}) => {
  const {t} = useTranslation('common');
  const imagePrincipale = useHalState(articleState.follow('medias').follow('image_principale'));
  
  const promo = itemState.data.tolede.prix_par_livraison?.[0]?.type;
  return(
    <Box position="relative" p={{base: '10px 20px 0px 20px', md: ''}}>
      {/* Version mobile */}
      <VStack mt='10px' display={{base:'flex', md:'none'}}>
        {promo === 'promo' &&
        <HStack position="absolute" top='10px' left="30px"
          bg="red" color="white" fontSize="xs" fontWeight="bold" py="2px" px="8px" borderRadius="md">
          <PromoIcon color='white'/>
          <Text>{t('global.promotion')}</Text>
        </HStack>
        }
        <Box maxHeight="274px">
          <Image src={imageOptimiseeH(imagePrincipale.data?.url, 274)} alt="" objectFit="cover" width="100%" maxHeight="274px"/>
        </Box>
      </VStack>
      {/* Version desktop */}
      <Box m="20px" display={{base:'none', md:'block'}}>
        <Box position="relative">
          {promo === 'promo' &&
          <HStack position="absolute" top='0px' left="10px"
            bg="red" color="white" fontSize="xs" fontWeight="bold" py="2px" px="8px" borderRadius="md">
            <PromoIcon color='white'/>
            <Text>{t('global.promotion')}</Text>
          </HStack>
          }
          <HStack height="372px" width='100%' justifyContent='center'>
            <Image src={imageOptimiseeH(imagePrincipale.data?.url, 372)} alt="" objectFit="contain" maxH="372px" />
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
