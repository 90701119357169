import {useHalState} from '@synako/haligator';
import {PanierFound} from './PanierFound';
import {PanierNotFound} from './PanierNotFound';
import {usePanier} from 'ff-common';

export const PanierView: React.FC = () => {  
  const derniereAction = new URLSearchParams(location.search).get('derniereAction');
  const panierNav = usePanier();
  const {state: panier} = useHalState(panierNav);

  if (panier) {
    return <PanierFound panier={panierNav} />;
  }
  return <PanierNotFound derniereAction={derniereAction} />;
};
