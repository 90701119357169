import {useCallback, useState} from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {Text, VStack, Button, Link, useToast, AlertIcon, Spinner, Skeleton} from '@chakra-ui/react';
import {AddIcon} from '@chakra-ui/icons';
import {useHalCollection, useCommand, useHalStates} from '@synako/haligator';
import {useCadencier, useListes} from '@/haligator-factory';
import {HalitrekErrorHandler, ProblemBody, State} from '@synako/halitrek';
import {ArticleCadencierAjouteEventDesc, ItemCatalogueDesc, ListeDesc} from 'api-types/ffconnect-hal';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {Trans, useTranslation} from 'react-i18next';
import {ContextsComposant} from '@/utils/ContextsComposant';
import {routes} from 'ff-common';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {useNavigate} from 'react-router-dom';
import {CustomToast} from '@/components/toast/CustomToast';
import {ModalCreateListe} from './ModalCreateListe';
import {BlueButton} from '@/components/Button/BlueButton';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';

interface CadencierActionsProps {
  itemState: State<ItemCatalogueDesc>;
  clearAndClose?: () => void;
}

export interface ModalSelectListProps {
  itemState: State<ItemCatalogueDesc>
  context?: ContextsComposant;
}

interface ListeSelectionProps {
  list: State<ListeDesc>;
  itemState: State<ItemCatalogueDesc>;
  selectedList: string;
  setSelectedList: (listCode: string) => void;
  clearAndClose: () => void;
}

export const ListeSelection: React.FC<ListeSelectionProps> = ({list, itemState, selectedList, setSelectedList, clearAndClose}) => {
  const textToast = useSimpleTextToast();
  const {t} = useTranslation('common');
  const toast = useToast();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const navigate = useNavigate();

  const onError = useCallback<HalitrekErrorHandler>((error) => {
    let message = error.message;
    if ((error.body as ProblemBody).type === 'validation_error'){
      const validation = (error.body as {validation?:{keyword:string}[]}).validation; 
      if (validation?.length == 1){
        message = t([`error.${validation[0].keyword}`, message]);
      }
    }
    toast({
      duration: 4000,
      render: ({onClose}) => {
        const codeListe = list.data.code_liste;
        return <CustomToast icon={<AlertIcon />} onClose={onClose} body={<VStack alignItems='start' gap='0'>
          <Text>{t([`error.${message}`, message])}</Text>
          <Link fontWeight='bold' textDecorationLine='underline' onClick={()=>{navigate(buildAssistanceRoute(routes.client.listes, {code_liste:codeListe}));}}>
            {t('toast.voir_liste')}
          </Link>
        </VStack>} />;
      }
    });
  }, [t, toast, buildAssistanceRoute, list.data.code_liste, navigate]);
  
  const onSuccess = useCallback(() => {
    textToast([
      <Trans t={t}>{t('toast.ajout_liste')}</Trans>,
      <Link fontWeight='bold' textDecorationLine='underline' onClick={()=>{navigate(buildAssistanceRoute(routes.client.listes, {code_liste:list.data.code_liste}));}}>
        {t('toast.voir_liste')}
      </Link>
    ]);
  }, [t, textToast, buildAssistanceRoute, list.data.code_liste, navigate]);
  
  const {submit: AjouterAction} = useCommand(list.follow('self'), 'c:ajouter-article-a-une-liste', {onSuccess, onError,});
  
  const handleClick = useCallback(() => {
    setSelectedList(list.data.code_liste ?? '');
    AjouterAction({liste_id: list.data.id, code_article: itemState.data.tolede.code_article});
    clearAndClose();
  }, [list, itemState, setSelectedList, AjouterAction, clearAndClose]);

  const {states} = useHalStates(list.followAll('selection'));
  const articleDejaPresentListe = states?.some((article) => article.data.tolede.code_article === itemState.data.tolede.code_article);

  return (
    <Button w="100%" bgColor={selectedList === list.data.code_liste ? 'blue.300' : 'white'}
      color={selectedList === list.data.code_liste ? 'white' : 'gray.dark'}
      border="1px solid" borderColor={selectedList === list.data.code_liste ? 'white' : 'blue.300'}
      _hover={{bg: 'blue.300', color: 'white', borderColor: 'white'}} onClick={handleClick} isDisabled={articleDejaPresentListe}>
      {list.data.nom_liste}
    </Button>
  );
};

export const CadencierActions: React.FC<CadencierActionsProps> = ({itemState, clearAndClose = () => {
  console.log('clearAndClose not provided');}},) => {
  const textToast = useSimpleTextToast();
  const {t} = useTranslation('common');
  const defaultOnError = useDefaultOnError(textToast);

  const onSuccess = useCallback((event: State<ArticleCadencierAjouteEventDesc>) => {
    switch (event.data.data.logique) {
      case 'avertissement':
        textToast(<Trans t={t}>{t('toast.ajout_cadencier_safelive')}</Trans>);
        break;
      case 'simulation':
        textToast(<Trans t={t}>{t('toast.ajout_cadencier_simulation')}</Trans>);
        break;
      default:
        textToast(<Trans t={t}>{t('toast.ajout_cadencier')}</Trans>);
        break;
    }
    clearAndClose();
  }, [t, textToast, clearAndClose]);

  const onError: HalitrekErrorHandler = useCallback((error) => {
    defaultOnError(error);
  }, [defaultOnError]);

  const {submit: AjouterAction, isSubmitting, isDisabled} = useCommand(itemState.resource, 'c:ajouter-au-cadencier', {onSuccess, onError});

  const AddArticleToListAction = useCallback(async () => {
    AjouterAction({code_article: itemState.data.tolede.code_article});
  }, [AjouterAction, itemState.data.tolede.code_article]);

  const contenuButton =
  isSubmitting ? (
    <VStack w='160px'><Spinner size="sm" color='gray.dark'/></VStack>
  ) : t('liste.ajouter_au_cadencier');

  const {states} = useHalStates(useCadencier().followAll('selection'));
  const articleDejaPresentCadencier = states?.some((article)=> article.data.tolede.code_article === itemState.data.tolede.code_article);

  return (
    <>
      <Button
        w={'100%'}
        _hover={{bgColor: 'lblue.200'}}
        bgColor={'lblue.100'}
        color='black'
        cursor="pointer"
        fontWeight='600'
        transition="all 0.2s ease-in-out"
        p={'30px 30px'}
        onClick={AddArticleToListAction}
        isDisabled={isDisabled || isSubmitting || articleDejaPresentCadencier}
      >
        {contenuButton}
      </Button>
    </>
  );
};

export const ModalSelectList = NiceModal.create(({itemState, context}: ModalSelectListProps) => {
  const modal = NiceModal.useModal();
  const [selectedList, setSelectedList] = useState<string>('');
  const {items, loading} = useHalCollection(useListes());

  const {t} = useTranslation('common');

  const clearAndClose = () => {
    setSelectedList('');
    modal.remove();
  };

  return (
    <>
      <ModaleLayout
        visible={modal.visible} hide={clearAndClose}
        titre={t('liste.ajouter_article')}
      >
        <Text mb={2}>{t('liste.veuillez-selectionner-ou-ajouter-larticle')}</Text>
        <VStack>
          {context !== 'fiche_produit' && <CadencierActions itemState={itemState} clearAndClose={clearAndClose} />}
          {loading && <Skeleton width='100%' height='5em'/>}
          {items?.map((list) => (
            <ListeSelection key={list.data.id} list={list} itemState={itemState} selectedList={selectedList}
              setSelectedList={setSelectedList} clearAndClose={clearAndClose} />
          ))}
          <BlueButton texte={t('liste.creer_liste')} onClick={() => NiceModal.show(ModalCreateListe)} leftIcon={<AddIcon />} w='100%' mb='10px'/>
        </VStack>
      </ModaleLayout>
    </>
  );
});
