/**
 * Utilisé pour le remplacement article du catalogue avant refacto
 */
import {Navigable, State} from '@synako/halitrek';
import {ItemCatalogueDesc, LivraisonDesc} from 'api-types/ffconnect-hal';
import {HaliRenderMany, HaliRenderOne} from '@synako/haligator';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {SkeletonChargementRequeteGetDispo} from './SkeletonChargementRequeteGetDispo';
import {Trans, useTranslation} from 'react-i18next';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useLivraisons} from '@/haligator-factory';
import {dateAvecSlashes} from 'ff-common';
import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import {PrecommandeContent} from '@/components/MenuLivraison/ModaleMenuLivraison/PrecommandeContent';
import {Dispatch, SetStateAction} from 'react';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {useLivraisonsPossiblesContext} from 'ff-common';

export interface PrecommandeModalProps {
  livraisonSelectionnee: State<LivraisonDesc>
  setLivraisonSelectionnee: Dispatch<SetStateAction<State<LivraisonDesc>>>
  itemState: State<ItemCatalogueDesc>;
  commandeParColis: boolean;
  remplacementAction?: (selectedLivraison: Navigable<LivraisonDesc>) => Promise<void>
}

export const PrecommandeModal = NiceModal.create(({
  itemState,
  commandeParColis,
  remplacementAction
}: PrecommandeModalProps) => {
  const {t} = useTranslation('common');
  const textToast = useSimpleTextToast();

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const {submit: ajouterAuPanierAction} = useAjouterAuPanier(livraisonSelectionnee.data.id, textToast);

  const modal = useModal();
  const {multiple_de_commande_client:multiple, minimum_de_commande_client:minimum} = itemState.data.tolede;

  return <ModaleLayout
    visible={modal.visible}
    hide={modal.remove}
    titre={t('modale_livraison.header.preco')}
    size_desktop='2xl'
  >
    <HaliRenderOne nav={livraisonSelectionnee} render={({data: livraison}) => {
      const quantite = Math.max(minimum, multiple);
      return (
        <HaliRenderOne nav={itemState.follow('disponibilite_article', {
          date_de_livraison: livraison.tolede.date_de_livraison,
          quantite_demandee: quantite
        })} 
        getOptions={{cache: 'no-store'}}
        skeleton={()=>{
          return <SkeletonChargementRequeteGetDispo/>;
        }}
        render={({data:{tolede:dispo}})=>{
          // modal.visible empêche la commande d'être appelée plusieurs fois
          // problème connu de re-render ?
          if (dispo.disponibilite === 'disponible' && modal.visible) {
            modal.remove();
            if (remplacementAction) {
              remplacementAction(livraisonSelectionnee);
            } else {
              ajouterAuPanierAction({
                code_article: itemState.data.tolede.code_article,
                quantite,
                commande_en: commandeParColis ? 'colis' : 'unitaire'
              });
            }
            modal.visible = false;
            return;
          } else {
            return (
              <HaliRenderMany many={useLivraisons().followAll('items')} render={({states: livraisonsPossibles}) => {
                const dateDebut = new Date(dispo.prochaine_date_livraison_au_plus_tot ?? new Date());
                const prochaineLivraison = livraisonsPossibles.find(livraison => {
                  const dateLivraison = new Date(livraison.data.tolede.date_de_livraison);
                  return dateLivraison >= dateDebut;
                });
                if (!prochaineLivraison) {
                  textToast(
                    <Trans t={t}>{t('toast.pas_de_livraison', {date: dateAvecSlashes(dateDebut.toISOString())})}</Trans>
                  );
                  return;
                }
              
                return (
                  <PrecommandeContent
                    livraisonSelectionnee={prochaineLivraison}
                    dateDebut={dateDebut.toISOString()}
                    itemPreco={itemState}
                    commandeParColis={commandeParColis} 
                    selectionQuantitePossible={!remplacementAction} 
                    remplacementAction={remplacementAction}
                    modal={modal}
                  />
                );
              }} />
            );
          }
        }}/>
      );
    }} />
  </ModaleLayout>;
});
