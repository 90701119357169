import {HStack} from '@chakra-ui/react';
import {MenuLivraison} from '../MenuLivraison/MenuLivraison';
import {AffichagePanierNavTemplate} from './AffichagePanierNavTemplate';

export const CartButton: React.FC = () => {
  return <HStack borderRadius={{base: '0', md: '50px'}} ml='auto' 
    maxW={{base: '100%', md: '400px'}}
    minW={{base: '100%', md: '400px'}} 
    height='100%'
    alignItems='stretch'
    justifyContent='center' border='1px solid rgba(0, 114, 186, 0.3)' gap='0' >
    <MenuLivraison />
    <AffichagePanierNavTemplate />
  </HStack >;
};
