import {defineStyleConfig} from '@chakra-ui/react';

const greenStyle = {
  bg: 'green.success_bg',
  color: 'green.success',
};

const yellowStyle = {
  bg: 'yellow.warning',
  color: 'yellow.warning_text',
};

const redStyle = {
  bg: 'red.warning_bg',
  color: 'rail.fail',
};

export const statutBadgeTheme = defineStyleConfig({
  variants: {
    saisie: yellowStyle,
    mise_en_prepa: yellowStyle,
    prepa_en_cours: yellowStyle,
    prepa_terminee: greenStyle,
    facturee: greenStyle,
    annulee: redStyle,
    ferme: redStyle,
    a_payer: redStyle,
    payee: greenStyle,
    // lettre: greenStyle,
  },
  defaultProps: {
    variant: 'saisie',
  },
});
