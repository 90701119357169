import {tableAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle(() => {
  return {
    table:{
      tableLayout: 'auto',
      whiteSpace: 'normal',
    },
    td:{
      '&.border_right':{
        borderRight: '1px solid',
        borderColor: 'gray.400',
      },
    },
    th:{
      zIndex: 1,
      textTransform:'none',
      fontSize:'14px',
      fontWeight:'400',
      position: 'relative',
      '&.border_right':{
        borderRight: '1px solid',
        borderColor: 'gray.400',
      },
    },
    thead:{
      tr:{
        th: {
          maxW:'177px',
          letterSpacing:'normal',
          zIndex: 1,
          _hover:{
            color:'blue.100',
            fontWeight:'500',
            ':after':{
              color:'blue.100',
              bg: 'lblue.300',
              content: '""',
              height: '10000px',    
              left: 0,
              position: 'absolute',  
              top: '-5000px',
              width: '100%',
              zIndex: -1, 
            },
          },
        },
      },
    },
    tbody:{
      tr:{
        th: {
          pl:'1',
          maxW:'220px',
          '&.subheader':{
            pl:'6',
            pt:'6',
          },
          '&.bold':{
            fontWeight: '500',
          },
          '&.pl':{
            pl:'4',
          }
        },
        td:{
          py: '2.5',
          px: '4',
          position: 'relative',
          ':hover::after':{
            backgroundColor: 'lblue.300',
            content: '""',
            height: '10000px',    
            left: 0,
            position: 'absolute',  
            top: '-5000px',
            width: '100%',
            zIndex: 0, 
          },
        },
        '&.row_active':{
          bg: 'lblue.transparency_300_60',
        },
        '&.border_top':{
          borderTop: '1px solid',
          borderColor: 'gray.400',
        },
      },
    },
  };
});

// export const tableTheme = defineMultiStyleConfig({
//   defaultProps: {
//   size: 'xl',
//   variant: 'rounded'
//   }
// })

export const tableTheme = defineMultiStyleConfig({variants: {baseStyle}});