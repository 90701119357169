import {Icon} from '@chakra-ui/react';

export const ChevronRightBlueIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='blue.main', width='20px', height='20px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill={color}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.91107 15.5894C6.58563 15.264 6.58563 14.7363 6.91107 14.4109L11.3218 10.0002L6.91107 5.58942C6.58563 5.26398 6.58563 4.73634 6.91107 4.41091C7.23651 4.08547 7.76414 4.08547 8.08958 4.41091L13.0896 9.41091C13.415 9.73634 13.415 10.264 13.0896 10.5894L8.08958 15.5894C7.76414 15.9149 7.23651 15.9149 6.91107 15.5894Z"/>
    </Icon>
  );
};