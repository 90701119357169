import {Route} from '@synako/enhanced-router';

console.log('ffconnect.ts');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: import.meta is only supported in Vite
const env = import.meta.env;
// console.log('import.meta.env: ', env);
const index: Route = {
  // path: '/ffconnect',
  path: (env) ? env.BASE_URL : '/'
};

export default {
  ...index,
};
