import {GridItem, HStack} from '@chakra-ui/react';
import {DateButton} from './DateButton';

export const CalendarCellInactive: React.FC<{
  date: Date
}> = ({
  date,
}) => (
  <GridItem as={HStack} alignItems='stretch' justify='center'>
    <DateButton date={date} isDisabled={true} />
  </GridItem>
);
