import {State} from '@synako/halitrek';
import {BonDeLivraisonDesc, CommandeDesc, FactureDesc} from 'api-types/ffconnect-hal';
import {ReactNode, useEffect, useState} from 'react';

export const DownloadLink: React.FC<{
  state: State<BonDeLivraisonDesc | CommandeDesc | FactureDesc>,
  children: ReactNode
}> = ({state, children}) => {
  const [url, setUrl] = useState('#');

  useEffect(() => {
    const getUrl = async () => {
      const pdfState = await state.follow('pdf').resolve();
      setUrl(pdfState.uri);
    };
    getUrl();
  }, [state]);

  // la récupération des documents est assez longue, on ouvre un nouvel onglet pour que le user voit qu'il se passe quelque chose
  // avec la prop download à true, le nouvel onglet ne s'ouvre pas, elle n'est pas nécessaire pour obtenir le fichier
  return (
    <a href={url} target="_blank">
      {children}
    </a>
  );
};
