import NiceModal, {NiceModalContext} from '@ebay/nice-modal-react';
import {useCallback, useContext} from 'react';

export const useRemoveAllModal = ()=>{
  const context = useContext(NiceModalContext);
  const removeAllModal = useCallback(()=>{
    for (const [key] of Object.entries(context)) {
      NiceModal.remove(key);
    }
  }, [context]);
  
  return removeAllModal;
};
