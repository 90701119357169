import {Icon} from '@chakra-ui/react';

export const CatalogueIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='gray.dark', width='20px', height='20px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1993_118673)">
        <path d="M19.9394 12.8841L19.9394 12.8841L19.9372 12.8819L17.8071 10.7674C18.6382 9.67704 19.0375 8.31574 18.9249 6.94585C18.8086 5.53103 18.1551 4.21453 17.0987 3.26708C16.0422 2.31963 14.6632 1.81325 13.2451 1.85208C11.827 1.89091 10.4777 2.47201 9.47458 3.47586C8.4715 4.4797 7.89088 5.82999 7.85208 7.24906C7.81328 8.66813 8.31924 10.0482 9.26596 11.1054C10.2127 12.1626 11.5282 12.8167 12.9421 12.9331C14.3111 13.0458 15.6716 12.646 16.7611 11.8141L18.8735 13.9281C18.8735 13.9281 18.8736 13.9282 18.8737 13.9283C18.9433 13.9985 19.0262 14.0542 19.1174 14.0923L19.1752 13.9538L19.1174 14.0923C19.2088 14.1304 19.3068 14.15 19.4058 14.15C19.5047 14.15 19.6027 14.1304 19.6941 14.0923C19.7855 14.0542 19.8684 13.9984 19.938 13.9281L19.9394 13.9267C20.0745 13.7868 20.15 13.5999 20.15 13.4054C20.15 13.2109 20.0745 13.024 19.9394 12.8841ZM13.4093 11.4641C12.6088 11.4641 11.8263 11.2266 11.1607 10.7815C10.4951 10.3364 9.97629 9.70379 9.66993 8.9636C9.36357 8.22342 9.28341 7.40894 9.4396 6.62316C9.59578 5.83738 9.98128 5.11561 10.5473 4.54912C11.1134 3.98263 11.8346 3.59686 12.6197 3.44057C13.4048 3.28428 14.2186 3.36449 14.9582 3.67107C15.6978 3.97764 16.3299 4.49681 16.7747 5.16295C17.2194 5.82908 17.4568 6.61226 17.4568 7.41343C17.4568 8.48778 17.0304 9.5181 16.2713 10.2777C15.5122 11.0374 14.4828 11.4641 13.4093 11.4641Z" stroke={color} strokeWidth="0.3"/>
        <rect x="0.5" y="2.33203" width="6.66667" height="1.5" rx="0.75"/>
        <rect x="0.5" y="7.66797" width="5.33333" height="1.5" rx="0.75"/>
        <rect x="0.5" y="12.9961" width="9.33333" height="1.5" rx="0.75"/>
      </g>
      <defs>
        <clipPath id="clip0_1993_118673">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </Icon>
  );
};