import {HStack, StyleProps} from '@chakra-ui/react';

export const LigneProduitLayout: React.FC<React.PropsWithChildren<{precommande?: boolean;} & StyleProps>> = ({
  children, precommande = false, ...props
}) => {
  const extraPaddingTop = precommande ? '40px' : '';
  return <HStack 
    border='1px'
    borderColor='gray.light' _notLast={{borderBottom: '0'}}
    background='white' width='100%' alignItems='stretch' position='relative'
    p={{base:'2', md:'20px'}}
    pt={{base:extraPaddingTop, md:extraPaddingTop}} 
    {...props}
  >
    {children}
  </HStack>;
};
