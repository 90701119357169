import {add, addMonths, format, formatDistanceToNow, formatISO, isEqual} from 'date-fns';
import {fr} from 'date-fns/locale';

export const getTempsRestant = (timestamp: string | number) => {
  return formatDistanceToNow(new Date(timestamp));
};

export const dateAvecSlashes = (timestamp: string | number | undefined) => {
  return format(timestamp ? new Date(timestamp) : new Date(), 'dd/MM/yyyy', {locale: fr});
};

export const dateEnTexte = (timestamp: string | number | undefined) => {
  return format(timestamp ? new Date(timestamp) : new Date(), 'do MMMM yyyy', {locale: fr});
};

export const isDateDepassee = (timestampDispo: string | number | undefined, precommande: boolean) => {
  if (!precommande && timestampDispo) {
    const today = new Date();
    const tomorrow = formatISO(add(today, {days: 1}), {representation: 'date'});
    const dateDispo = formatISO(timestampDispo, {representation: 'date'});
    return dateDispo <= tomorrow;
  }
  return false;
};

export const isDateEqual = (d1: Date, d2: Date) => {
  return isEqual(format(d1, 'yyyy-MM-dd'), format(d2, 'yyyy-MM-dd'));
};

export const moisAnneeEnTexte = (dateLivraison: string, monthOffset: number) => {
  const formated = format(addMonths(dateLivraison, monthOffset), 'MMMM yyyy', {locale: fr});
  return `${formated.substring(0, 1).toUpperCase()}${formated.substring(1)}`;
};

export const jourMoisEnTexte = (dateLivraison: string) => {
  return format(dateLivraison, 'do MMMM', {locale: fr});
};
