import {Box, Button, ChakraProps, useBreakpointValue} from '@chakra-ui/react';
import {HamburgerIcon} from '../../svg/HamburgerIcon';
import NiceModal from '@ebay/nice-modal-react';
import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale.js';

export interface ProductSection {
  id: number;
  title: string;
  img: string;
  categories?: {name: string; subcategories: string[]}[];
}

export const MenuCatalog: React.FC<{props?: ChakraProps}> = ({props}) => {
  const viewMobile = useBreakpointValue({base: true, lg: false});

  return (
    <>
      <Box {...props}>
        <Button
          rightIcon={!viewMobile?<HamburgerIcon />:undefined}
          variant="transparent"
          bg='transparent'
          borderColor="gray.200"
          borderWidth="1px"
          _hover={{borderColor: 'gray.300'}}
          onClick={() => {NiceModal.show(CatalogueModale);}}
        >
          {!viewMobile?'Catalogue':<HamburgerIcon />}
        </Button>
      </Box>
    </>
  );
};
