import {useAjouterAuPanier} from '@/hooks/livraison/use-ajouter-au-panier';
import {TFunction} from 'i18next';
import {BuyButton} from './BuyButton';
import {ItemCatalogue} from 'api-types/ffconnect';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useLivraisonsPossiblesContext} from 'ff-common';

export const AjouterButton: React.FC<{
  itemCatalogue: ItemCatalogue,
  commandeEnColis: boolean,
  t: TFunction
}> = ({
  itemCatalogue,
  commandeEnColis,
  t
}) => {
  // console.log('RERENDER : AjouterButton '+ itemCatalogue.tolede.code_article);

  const textToast = useSimpleTextToast();
  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const ajouterAuPanier = useAjouterAuPanier(livraisonSelectionnee.data.id, textToast);
  const {
    submit: ajouterAuPanierAction,
    isSubmitting: ajouterAuPanierSubmitting
  } = ajouterAuPanier;
  
  const onClick = () => {
    const {tolede:{
      code_article:codeArticle,
      quantite_colis_entier:quantiteColisEntier,
      minimum_de_commande_client:minimumCommande,
      multiple_de_commande_client:multipleCommande
    }} = itemCatalogue;
    ajouterAuPanierAction(
      {
        code_article: codeArticle,
        quantite: commandeEnColis ? quantiteColisEntier! : Math.max(minimumCommande, multipleCommande),
        commande_en: commandeEnColis ? 'colis' : 'unitaire'
      }
    );
  };

  return (
    <BuyButton
      libelle={t('bouton_achat.buy')}
      onClick={onClick}
      isSubmitting={ajouterAuPanierSubmitting}
    />
  );
};
