import React, {useMemo, useState} from 'react';
import {Box, Button, HStack, IconButton, Text, Heading, Spacer, Stack, useRadioGroup} from '@chakra-ui/react';
import {DownloadIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {FactureIcon} from '@/components/svg/FactureIcon';
import {AvoirIcon} from '@/components/svg/AvoirIcon';
import {useClient} from '@/haligator-factory';
import {useHalStates} from '@synako/haligator';
import {dateAvecSlashes} from 'ff-common';
import {DownloadLink} from '../../components/DownloadLink';
import {FactureDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import DataTable from '@/components/DataTable';
import {createColumnHelper} from '@tanstack/react-table';
import {RadioCard} from '@/chakra/components/Radio/RadioCard';

export const FacturesAvoirsView: React.FC = () => {
  const {t} = useTranslation('common');
  const [selectedPeriod, setSelectedPeriod] = useState('6_mois');
  const {loading, refreshing, states} = useHalStates(useClient().follow('factures', {
    nombre_de_jours: selectedPeriod === '2_mois' ? 60 : selectedPeriod === '6_mois' ? 180 : 360
  }).followAll('items'));

  const columnHelper = createColumnHelper<State<FactureDesc>>();

  const periods = [
    // {value: '2_mois', label: t('factures_avoirs.2_derniers_mois')},
    {value: '6_mois', label: t('factures_avoirs.6_derniers_mois')},
    {value: '12_mois', label: t('factures_avoirs.12_derniers_mois')},
  ];

  const {getRootProps, getRadioProps} = useRadioGroup({
    name: 'period',
    defaultValue: selectedPeriod,
    onChange: setSelectedPeriod,
  });

  const group = getRootProps();

  const columns = useMemo(() => [
    columnHelper.accessor('data.tolede', {
      header: t('factures_avoirs.facture_n°'),
      cell: (ctx) => (
        <HStack>
          {ctx.getValue().type === 'paiement' ? (
            <><FactureIcon color="gray.dark" width="26px" height="31px" /><Text>{t('factures_avoirs.facture')}</Text></>
          ) : (
            <><AvoirIcon width="26px"/><Text>{t('factures_avoirs.avoir')}</Text></>
          )}
          <Text>{t('factures_avoirs.n°')}{ctx.getValue().numero}</Text>
        </HStack>
      ),
      meta: {
        tdProps: {width: {base: '100%', lg: '30%'}, textAlign: 'left', py: 3},
      },
    }),
    columnHelper.accessor('data.tolede.emise_le', {
      header: t('factures_avoirs.date_emission'),
      cell: (ctx) => dateAvecSlashes(ctx.getValue()),
      meta: {
        tdProps: {width: {base: '100%', lg: '15%'}, textAlign: 'left', py: 3},
      },
    }),
    columnHelper.accessor('data.tolede.echeance_au', {
      header: t('factures_avoirs.echeance'),
      cell: (ctx) => dateAvecSlashes(ctx.getValue()),
      meta: {
        tdProps: {width: {base: '100%', lg: '15%'}, textAlign: 'left', py: 3},
      },
    }),
    columnHelper.accessor('data.tolede.montant_ttc', {
      header: t('factures_avoirs.total_ttc'),
      cell: (ctx) => `${ctx.getValue()} €`,
      meta: {
        isNumeric: false,
        tdProps: {width: {base: '100%', lg: '15%'}, textAlign: 'left', py: 3},
      },
    }),
    columnHelper.accessor('data.tolede.montant_ht', {
      header: () => <Box textAlign="left" width="100%">{t('factures_avoirs.total_ht')}</Box>,
      cell: (ctx) => `${ctx.getValue()} €`,
      meta: {
        isNumeric: true,
        tdProps: {fontWeight: '700', color: 'black.ff', width: {base: '100%', lg: '15%'}, textAlign: 'left', py: 3},
      },
    }),
    // columnHelper.accessor('data.tolede.statut', {
    //   header: () => <HeaderWithTooltip text={t('factures_avoirs.statut')} tooltip={t('factures_avoirs.statut_tooltip')} />,
    //   cell: () => null,
    // }),
    columnHelper.accessor((row) => row, {
      id: 'actions',
      header: t('factures_avoirs.action'),
      cell: (ctx) => (
        <DownloadLink state={ctx.getValue()}>
          {/* en mobile */}
          <Button
            leftIcon={<DownloadIcon />}
            variant="ghost"
            borderWidth="1px"
            color="black.text"
            fontSize="xs"
            fontWeight="600"
            w='100%'
            display={{base: 'flex', lg: 'none'}}
            cursor='pointer'
          >
            {t('factures_avoirs.telecharger')}
          </Button>
          {/* en desktop */}
          <IconButton
            icon={<DownloadIcon />}
            aria-label={t('factures_avoirs.telecharger')}
            variant="ghost"
            borderWidth="1px"
            color="black.text"
            display={{base: 'none', lg: 'flex'}}
            cursor='pointer'
          />
        </DownloadLink>
      ),
      meta: {
        tdProps: {width: {base: '100%', lg: '10%'}, textAlign: 'left', py: 3},
      },
    }),
  ], [t, columnHelper]);

  const isLoading = loading || refreshing;

  return (
    <HStack alignItems="start" bg="gray.bg" overflowX="hidden" width="100%">
      <Box p={5} mr={{base:'0px', md:'20px'}} ml={{base:'30px', lg:0}} width="100%">
        <Heading size={{base:'md', md:'lg'}} ml={{base:'30px', md:'0'}} mb={6} mt="10px" color="black.text">
          {t('menu.factures_et_avoirs')}
        </Heading>
        <Box bg='white' p={{base: 4, md: 6}} borderRadius="8px" boxShadow="sm" w="100%">
          <Stack direction={{base:'column', lg:'row'}} mb={4} width="100%">
            <HStack {...group} spacing={2}>
              {periods.map((period) => {
                const radio = getRadioProps({value: period.value});
                return (
                  <RadioCard key={period.value} {...radio} loading={isLoading}>
                    {period.label}
                  </RadioCard>
                );
              })}
            </HStack>
            <Box bg="yellow.warning" borderRadius="8px" p={3} maxWidth="430px">
              <Text color="yellow.warning_text" fontSize="xs" textAlign="center">
                {t('factures_avoirs.alerte')}
              </Text>
            </Box>
            <Spacer />
          </Stack>

          {!isLoading && states && states.length > 0 && (
            <Text mt={4} fontSize={{base: 'sm', md: 'md'}} fontWeight="400" color="gray.dark">
              {t('factures_avoirs.factures_et_avoirs', {count: states.length})}
            </Text>
          )}

          <DataTable
            columns={columns}
            data={states ?? []}
            isLoading={isLoading}
            empty={
              <Text fontSize={{base: 'sm', md: 'md'}} textAlign='center' fontWeight="400" color="gray.dark" my={7} mt="50px">
                {t('factures_avoirs.aucune_facture_ou_avoir')}
              </Text>
            }
          />

        </Box>
      </Box>
    </HStack>
  );
};

export default FacturesAvoirsView;
