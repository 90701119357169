import {Text, Drawer, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useBreakpointValue, HStack, BoxProps, DrawerBody, DrawerFooter, ModalBody, ModalFooter, StyleProps} from '@chakra-ui/react';
import {PropsWithChildren, ReactElement} from 'react';

export const ModaleLayout:React.FC<PropsWithChildren<{
  visible:boolean, hide:()=>void,
  titre?:string,
  headerIcon?:ReactElement,
  footer?:ReactElement,
  size_desktop?:string | undefined,
  onCancel?:()=>void,
  drawerPlacement?: 'top' | 'bottom';
  drawerSize?: 'full' | 'auto';
  contentStyle?: StyleProps;
  closeButtonStyle?: StyleProps;
}> & BoxProps> = 
({visible, hide, titre, headerIcon, children:content, footer, size_desktop, onCancel, drawerPlacement = 'bottom', drawerSize = 'auto', contentStyle, closeButtonStyle})=>{
  const isMobile = useBreakpointValue({base:true, md:false});
  const header_style = {
    color:'black.text', 
    fontWeight:'700',
    display:'flex', 
    alignItems:'center', 
    paddingLeft:'1', 
    paddingTop:'0',
  };

  if (isMobile === undefined) return <></>;

  if (isMobile) return <Drawer
    isOpen={visible}
    onClose={hide}
    placement={drawerPlacement}
    size={drawerSize}
    blockScrollOnMount={false}
  >
    <DrawerOverlay />
    <DrawerContent 
      bg='white' 
      p={{base: '5px', md: '6'}}
      top='auto'
      //Hauteur du bottomNav
      mb='4.5rem'
      {...contentStyle}
    >
      <DrawerCloseButton
        onClick={onCancel ?? hide}
        margin={{base: '0', md: '2'}}
        tabIndex={-1}
        {...closeButtonStyle}
      />
      {(titre ?? headerIcon) && <DrawerHeader {...header_style}>
        <HStack mr='10px'>
          {headerIcon}
          <ModaleLayoutHeader>{titre}</ModaleLayoutHeader>
        </HStack>
      </DrawerHeader>}
      <DrawerBody>
        {content} 
      </DrawerBody>
      {footer && <DrawerFooter>
        {footer}
      </DrawerFooter>}
    </DrawerContent>

  </Drawer>;
  return <Modal
    isOpen={visible}
    onClose={hide}
    size={size_desktop ?? 'xl'}
    blockScrollOnMount={false}
  >
    <ModalOverlay />
    <ModalContent
      bg='white'
      p={{base: '5px', md: '6'}}
      {...contentStyle}
    >
      <ModalCloseButton
        onClick={onCancel ?? hide}
        margin={{base: '0', md: '2'}}
        {...closeButtonStyle}
      />
      {(titre ?? headerIcon) && <ModalHeader {...header_style}>
        <HStack>
          {headerIcon} 
          <ModaleLayoutHeader>{titre}</ModaleLayoutHeader>
        </HStack>
      </ModalHeader>}
      <ModalBody>
        {content} 
      </ModalBody>
      {footer && <ModalFooter>
        {footer}
      </ModalFooter>}
    </ModalContent>
  </Modal>;
}; 

export const ModaleLayoutHeader:React.FC<PropsWithChildren> = ({children})=>{
  return <Text fontSize={{base: 'md', md: 'xl'}}>{children}</Text>;
};
