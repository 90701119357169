import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {Link} from '@chakra-ui/react';
import {TFunction} from 'i18next';
import {NavigateFunction} from 'react-router-dom';

export const PanierLink: React.FC<{
  date_de_livraison: string,
  t: TFunction,
  navigate: NavigateFunction
}> = ({
  date_de_livraison,
  t,
  navigate
}) => {
  const {buildAssistanceRoute} = useIsAssistanceMode();
  // un Link de react-router-dom ou useNavigate sont inutilisables dans un toast, 
  // on est semble-t-il hors du contexte du Router
  // On fournit la méthode navigate depuis le Component appelant
  // Mettre le lien dans le href provoque un rechargement de la page, 
  // on navigate donc vers le lien dans le onClick
  return (
    <Link 
      fontWeight='bold' 
      textDecorationLine='underline' 
      href="#" 
      onClick={() => {navigate(buildAssistanceRoute(routes.client.panier_redirect, {date_de_livraison}));}}
    >
      {t('toast.voir_panier')}
    </Link>
  );
};
