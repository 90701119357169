import {ReactElement} from 'react';

//Affichage du contenu du bouton en fonction de la quantite
export const produitBuyButtonDisplay = (
  {
    quantiteDisplay,
    QuantiteInputBlock,
    BuyButtonBlock
  }: {
    quantiteDisplay: number | undefined;
    QuantiteInputBlock: (quantite:number)=>ReactElement;
    BuyButtonBlock: ReactElement;
  }) => {
  return quantiteDisplay !== undefined && quantiteDisplay !== 0
    ? QuantiteInputBlock(quantiteDisplay)
    : BuyButtonBlock;
};