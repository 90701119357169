import NiceModal, {useModal} from '@ebay/nice-modal-react';
import {Box} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {WarningIcon} from '@chakra-ui/icons';
import {ModaleLayout} from '@/components/Modale/ModaleLayout';
import {BlueButton} from '@/components/Button/BlueButton';
import {GrayButton} from '@/components/Button/GrayButton';
import {ReactElement} from 'react';

//NOTE(Nathan) popup confirmation générique
export const ModaleAlerteConfirmation =  NiceModal.create<{
  titre?:string, headerIcon:ReactElement, body:string, validate_texte:string, cancel_text?:string
  validateAction:()=>void
}>(({
      titre, headerIcon=<WarningIcon/>, body, validate_texte, cancel_text,
      validateAction
    }) => {
      const modal = useModal();
      const {t} = useTranslation('common');

      return <ModaleLayout
        visible={modal.visible}
        hide={modal.remove}
        headerIcon={headerIcon}
        titre={titre ?? t('global.attention')}
        footer={<>
          <BlueButton texte={validate_texte} 
            onClick={()=>{
              validateAction();
              modal.remove();
            }}
            mr='4'/>
          <GrayButton texte={cancel_text ?? t('global.annuler')} 
            onClick={()=>{modal.remove();}}/>
        </>}
      >
        <Box>
          {body}
        </Box>
      </ModaleLayout>;
    });
