import {BoxProps} from '@chakra-ui/react';
import {useHaligator} from '@synako/haligator';
import {RootDesc as FFConnectRoot} from 'api-types/ffconnect-hal';
import {useParams} from 'react-router-dom';

declare module '@synako/haligator' {
  interface HaliRenderMoreProps {
    style?: BoxProps;
  }
}

export function useMe() {
  return useApi().follow('get-me');
}

export function useApi() {
  const {client} = useHaligator();
  return client.go<FFConnectRoot>();
}

function useIsAssistanceMode() {
  const {id_client_assistance} = useParams<{id_client_assistance?: string}>();
  return {id_client_assistance};
}

export function useClient() {
  const {id_client_assistance} = useIsAssistanceMode();
  const api = useApi();
  const me = useMe();

  if (id_client_assistance) {
    return api.follow('get-client', {client_id: id_client_assistance});
  } else {
    return me.follow('client');
  }
}

export function useCadencier() {
  return useClient().follow('cadencier');
}

export function useLivraisons() {
  return useClient().follow('livraisons_possibles');
}

export function useNomenclature() {
  return useClient().follow('nomenclature');
}

export function useRepresentants() {
  return useClient().follow('representants');
}

export function useSocieteCommerciale() {
  return useClient().follow('societe_commerciale');
}

export function useListes() {
  return useClient().follow('listes');
}

export function useLabelAsset(codeAsset: string) {
  const {client} = useHaligator();
  return client.go<FFConnectRoot>().follow('get-label_asset', {code: codeAsset});
}
