import {routes} from 'ff-common';
import {decodeURI} from '../useFromFilters';
import {useBreakpointValue} from '@chakra-ui/react';
import {useSearchClient} from 'ff-common';
import {CategorieWeb} from 'api-types/ffconnect';
import {useSearchParams} from 'react-router-dom';
import {useIsAssistanceMode} from '../auth/useIsAssistanceMode';

export interface Crumb {
  name: string;
  link: string;
}

export default function useBreadCrumbs(cat_web: string) {
  const viewMobile = useBreakpointValue({base: true, xl: false});
  const searchClient = useSearchClient();
  const nomenclatureWeb = searchClient.getNomenclatureWeb();

  const {buildAssistanceRoute} = useIsAssistanceMode();

  const [searchParams] = useSearchParams();
  const categories = [];
  let actuelId : string | undefined = cat_web;

  while (actuelId) {
    const next = nomenclatureWeb?.find(cat => cat.id === actuelId);
    if(next) {
      categories.unshift(next);
      actuelId = next.id_parent !== actuelId ? next.id_parent : undefined;
    } else {
      actuelId = undefined;
    }
  }

  const crumbs: Crumb[] = [
    {
      name: 'Accueil',
      link: buildAssistanceRoute(routes.index)
    }
  ];

  const from = searchParams.get('from');
  if (from === 'recherche') {
    const query = decodeURI(searchParams.get('query'));
    const plusPetite : CategorieWeb | undefined = categories[categories.length - 1];
    if (query) {
      crumbs.push({
        name: 'Recherche',
        link: buildAssistanceRoute(routes.client.recherche) + query,
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (plusPetite) {
      crumbs.push({
        name: plusPetite.libelle,
        link: buildAssistanceRoute(routes.client.catalogue, undefined, {cat_web})
      });
    }
  } else {
    categories.forEach((cat, index) => {
      if (viewMobile && index === 1 && categories.length > 2) {
        crumbs.push({
          name: '...',
          link: buildAssistanceRoute(routes.client.catalogue, undefined, {cat_web: cat.id})
        });
      } else if (!viewMobile || index === 0 || index === categories.length - 1) {
        crumbs.push({
          name: cat.libelle,
          link: buildAssistanceRoute(routes.client.catalogue, undefined, {cat_web: cat.id})
        });
      }
    });
  }

  return crumbs;
}
