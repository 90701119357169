import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {LigneProblem} from 'api-types/ffconnect';

/**
 * Pour afficher les problèmes en haut, il faut retrouver la ligne de panier correspondante.
 * Pour chaque ligne de panier, il faut savoir s'il y a une erreur.
 */

export const analyseLignes = (
  lignesDePanierStates: State<LigneDePanierDesc>[],
  problemes_lignes: LigneProblem[]
) => {
  const problemes = problemes_lignes.map(probleme => ({
    probleme,
    ligneDePanier: lignesDePanierStates.find(ligne => probleme.ligne_de_panier_id === ligne.data.id),
  }));
  const lignesAvecProbleme = lignesDePanierStates.map(ligneDePanier => ({
    ligneDePanier,
    probleme: problemes_lignes.find(probleme => probleme.ligne_de_panier_id === ligneDePanier.data.id),
  }));
  return {
    problemes,
    lignesAvecProbleme,
  };
};
