import useBreadCrumbs from '@/hooks/catalogue/useBreadCrumbs';
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Heading} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export const ProduitBreadCrumb: React.FC<{libelle?: string, marque?: string, categorie: string}>
= ({libelle, marque, categorie}) => {
  const navigate = useNavigate();
  const crumbs = useBreadCrumbs(categorie);

  const {t} = useTranslation('common');

  return(
    <>
      {/* Version desktop */}
      <HStack gap='0' display={{base:'none', md:'flex'}}>
        <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.dark' opacity="0.8"/>}>
          {crumbs.map((crumb, index) => {
            return <BreadcrumbItem key={index}>
              <BreadcrumbLink 
                mr="1" 
                height="17px" 
                opacity="0.8" 
                fontWeight='400' 
                fontSize="sm" 
                lineHeight="17px" 
                color="gray.dark" 
                onClick={()=>{navigate(crumb.link);}}
              >
                {crumb.name}
              </BreadcrumbLink>
            </BreadcrumbItem>;
          }
          )}
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink mr="1" height="17px" opacity="0.8"
              fontWeight='700' fontSize="sm" lineHeight="17px"
              color="gray.dark" href='#'>
              {libelle} {marque}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      {/* Version mobile */}
      <HStack p="20px" display={{base:'flex', md:'none'}}
        onClick={() => {navigate(-1);}} 
        cursor='pointer'>
        <ChevronLeftIcon boxSize="22px" color="gray.dark"/>
        <Heading as="h5" fontSize="sm" fontWeight="400" color="gray.dark">
          {t('global.retour')}
        </Heading>
      </HStack>
    </>    
  );
};
