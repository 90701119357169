import {NiceModalHandler} from '@ebay/nice-modal-react';
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {useCallback, useEffect, useState} from 'react';

export const useSelectedLivraison = (
  livraisonSelectionnee: State<LivraisonDesc>,
  setLivraisonSelectionnee: (newLivraison: State<LivraisonDesc>) => void,
  modal: NiceModalHandler,
  prochaineLivraison?: State<LivraisonDesc>
) => {
  const [selectedLivraison, setSelectedLivraison] = useState<State<LivraisonDesc>>(
    livraisonSelectionnee
  );

  useEffect(() => {
    // la modale est juste masquée, pas démontée
    // le state garde la valeur connue au moment de la fermeture
    // on lui redonne la valeur correcte au chargement
    setSelectedLivraison(prochaineLivraison ?? livraisonSelectionnee);
  }, [
    prochaineLivraison,
    setSelectedLivraison,
    livraisonSelectionnee
  ]);

  const onCancel = useCallback(() => {
    if (selectedLivraison.uri !== livraisonSelectionnee.uri) {
      setLivraisonSelectionnee(livraisonSelectionnee);
      setSelectedLivraison(livraisonSelectionnee);
    }
    modal.remove();
  }, [
    modal,
    selectedLivraison,
    setLivraisonSelectionnee,
    livraisonSelectionnee
  ]);

  const onDateSelect = (selected: State<LivraisonDesc>) => {
    setSelectedLivraison(selected);
  };

  return {
    selectedLivraison,
    setSelectedLivraison,
    onCancel,
    onDateSelect
  };
};
