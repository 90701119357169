import {Icon} from '@chakra-ui/react';

export const HomeIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='gray.dark', width='20px', height='20px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.6667 6.66838L11.6667 2.28505C11.2084 1.87509 10.615 1.64844 10.0001 1.64844C9.38513 1.64844 8.79176 1.87509 8.33341 2.28505L3.33341 6.66838C3.06872 6.90511 2.8575 7.19551 2.71381 7.52025C2.57012 7.84499 2.49726 8.19662 2.50008 8.55171V15.835C2.50008 16.4981 2.76347 17.134 3.23231 17.6028C3.70115 18.0717 4.33704 18.335 5.00008 18.335H15.0001C15.6631 18.335 16.299 18.0717 16.7678 17.6028C17.2367 17.134 17.5001 16.4981 17.5001 15.835V8.54338C17.5017 8.18968 17.4283 7.83966 17.2846 7.51645C17.141 7.19323 16.9304 6.90418 16.6667 6.66838ZM11.6667 16.6684H8.33341V12.5017C8.33341 12.2807 8.42121 12.0687 8.57749 11.9125C8.73377 11.7562 8.94573 11.6684 9.16675 11.6684H10.8334C11.0544 11.6684 11.2664 11.7562 11.4227 11.9125C11.5789 12.0687 11.6667 12.2807 11.6667 12.5017V16.6684ZM15.8334 15.835C15.8334 16.0561 15.7456 16.268 15.5893 16.4243C15.4331 16.5806 15.2211 16.6684 15.0001 16.6684H13.3334V12.5017C13.3334 11.8387 13.07 11.2028 12.6012 10.7339C12.1323 10.2651 11.4965 10.0017 10.8334 10.0017H9.16675C8.5037 10.0017 7.86782 10.2651 7.39898 10.7339C6.93014 11.2028 6.66675 11.8387 6.66675 12.5017V16.6684H5.00008C4.77907 16.6684 4.5671 16.5806 4.41082 16.4243C4.25454 16.268 4.16675 16.0561 4.16675 15.835V8.54338C4.1669 8.42506 4.19224 8.30813 4.24109 8.20036C4.28995 8.0926 4.36119 7.99647 4.45008 7.91838L9.45008 3.54338C9.60215 3.40978 9.79766 3.3361 10.0001 3.3361C10.2025 3.3361 10.398 3.40978 10.5501 3.54338L15.5501 7.91838C15.639 7.99647 15.7102 8.0926 15.7591 8.20036C15.8079 8.30813 15.8333 8.42506 15.8334 8.54338V15.835Z"/>
    </Icon>
  );
};