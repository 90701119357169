import {HStack, VStack, Text} from '@chakra-ui/layout';
import {OrderModalIcon} from '@/components/svg/OrderModalIcon';
import NiceModal from '@ebay/nice-modal-react';
import {dateEnTexte} from 'ff-common';
import {ChangerDateDeLivraisonModale} from '@/components/Modale/CalendarModale/ChangerDateDeLivraisonModale';
import {useTranslation} from 'react-i18next';
import {PanierCommands} from 'ff-common/src/hooks/panier/use-panier-commands';

export const PanierRecapDate: React.FC<{
  date_de_livraison_ou_d_enlevement?: string;
  changerDateCommand: PanierCommands['changerDateDeLivraison']
}> = ({
  date_de_livraison_ou_d_enlevement,
  changerDateCommand
}) => {
  const {t} = useTranslation('common');
  return <VStack alignItems='stretch' p='5' borderRadius='8px' bg='blue.bg' textColor='blue.main'>
    <HStack>
      <OrderModalIcon color='blue.main' />
      <Text>{t('mes_informations.date_de_livraison')}</Text>
    </HStack>
    <Text fontWeight='700' fontSize='lg'>{dateEnTexte(date_de_livraison_ou_d_enlevement)}</Text>
    <Text 
      textDecorationLine='underline' 
      cursor='pointer' 
      onClick={() => {
        NiceModal.show(ChangerDateDeLivraisonModale, {
          submit: changerDateCommand.submitAsync,
        });
      }}
    >{t('panier.changer_date')}</Text>
  </VStack>;
};
