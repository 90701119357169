import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {useMemo} from 'react';

// utilisation de buildAssistanceRoute au lieu de buildRoute pour supporter le mode d'assistance
export const useNavigatePanier = () => {
  const {buildAssistanceRoute} = useIsAssistanceMode();

  const navigatePanier = useMemo(() => {
    return (date_de_livraison: string|undefined) => {
      if (!date_de_livraison) return buildAssistanceRoute(routes.client.panier_default);
      return buildAssistanceRoute(routes.client.panier, {date_panier_actif:date_de_livraison});
    };
  }, [buildAssistanceRoute]);

  return navigatePanier;
};
