import {useCallback, useRef} from 'react';

export function useDebouncedCallback<T extends unknown[]>(
  callback: (...args: T) => void,
  delay = 300
) {
  // Use a ref to store the timeout between renders
  // and prevent changes to it from causing re-renders
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: T) => {
      const later = () => {
        clearTimeout(timeout.current);

        callback(...args);
      };

      clearTimeout(timeout.current);

      timeout.current = setTimeout(later, delay);
    },
    [callback, delay]
  );
}
