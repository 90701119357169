
// TODO : pour l'instant elle est Team Ouest Distralis
export const placeholderImage = `${import.meta.env['VITE_MEDIA_URL']}/article/placeholder.webp`;

export function imageOptimisee(srcUrl: string | undefined, width: number) {
  if (!srcUrl) {
    return placeholderImage;
  }
  // Hypothèse forte que 
  const sep = srcUrl.includes('?') ? '&' : '?';
  return `${srcUrl}${sep}width=${width}`;
}

export function imageOptimiseeH(srcUrl: string | undefined, height: number) {
  if (!srcUrl) {
    return placeholderImage;
  }
  // Hypothèse forte que 
  const sep = srcUrl.includes('?') ? '&' : '?';
  return `${srcUrl}${sep}height=${height}`;
}