import {Box, VStack, Text} from '@chakra-ui/react';
import {ComponentType} from 'react';
import {Link} from 'react-router-dom';

//Boutons générique principalement utilisé dans la BottomNav
export const NavButton:React.FC<{showAs?:ComponentType, ButtonIcon:ComponentType<{height?:string|undefined, color?: string | undefined}>, label:string, to?:string, onClick?:()=>void; color?: string;}>
= ({showAs=Link, ButtonIcon, label, color='gray.dark', ...props}) => {
  return <Box as={showAs} color='gray.dark' height='100%' borderRadius='md' cursor='pointer'
    flex='1' _hover={{bg:'gray.bg'}}  p='1' {...props}>
    <VStack gap='0' justify='center' height='100%'>
      <Box display='flex' height='25px'>
        <ButtonIcon height='100%' color={color}/>
      </Box>
      <Text fontSize='sm' textAlign='center' fontWeight='500' color={color}>{label}</Text>
    </VStack>
  </Box>;
};
