import {Box, StyleProps, Tooltip, Text, HStack} from '@chakra-ui/react';
import {Dispatch, ReactNode, SetStateAction, useEffect, useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {WarningSignIcon} from '../svg/WarningSignIcon';

export const TooltipAsToast: React.FC<StyleProps & {
  tooltip: string | undefined,
  setTooltip: Dispatch<SetStateAction<string | undefined>>,
  icon?: ReactNode
  duration?: number
}> = ({
  tooltip, 
  setTooltip,
  icon = <WarningSignIcon color="#CC8800" />,
  duration = 5000,
  ...props
}) => {
  const {t} = useTranslation('common');
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>();

  useEffect(() => {
    if (tooltip) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setTooltip(undefined);
      }, duration);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [duration, tooltip, setTooltip]);

  if (!tooltip) {
    return;
  }

  return (
    <Tooltip 
      label={<HStack>{icon}<Text><Trans t={t}>{tooltip}</Trans></Text></HStack>} 
      placement={props.top ? 'top' : 'bottom'}
      isOpen
      borderRadius="8px"
      border="1px solid"
      borderColor={props.borderColor ?? '#CC8800'}
      p="0.5em"
      backgroundColor="white"
      color="#CC8800"
      {...props}
    >
      {/* pour éviter les glitches au render, on utilise une div vide comme enfant du tooltip */}
      <Box position="absolute" w="100%" {...props} />
    </Tooltip>
  );
};
