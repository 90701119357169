import {TrashIcon} from '@/components/svg/TrashIcon';
import {Button, IconProps, VStack} from '@chakra-ui/react';

export const DeleteBlock: React.FC<{
  onClick: () => void,
  Icon?: React.FC<IconProps>
}> = ({
  onClick,
  Icon
}) => {
  return (
    <>
      <VStack display={{base: 'none', md: 'block'}} position='absolute' top='20px' right='20px' justify='center'>
        <Button variant='ghost' onClick={onClick}>
          {Icon ? 
            <Icon height="5" />
            :
            <TrashIcon height='5' />
          }
        </Button>
      </VStack><VStack display={{base: 'block', md: 'none'}} alignSelf='center' justify='center' ml='auto' position='absolute' mt='10px' right='1'>
        <Button variant='ghost' onClick={onClick}>
          {Icon ? 
            <Icon height="6" />
            :
            <TrashIcon height='6' />
          }
        </Button>
      </VStack>
    </>
  );
};
