import {Icon} from '@chakra-ui/react';

export const CartPlusIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='blue.main', width='19', height='19'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 28 19" 
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5265_2403)">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.25 16.6248C14.25 15.7504 14.9589 15.0415 15.8333 15.0415C16.7078 15.0415 17.4167 15.7504 17.4167 16.6248C17.4167 17.4993 16.7078 18.2082 15.8333 18.2082C14.9589 18.2082 14.25 17.4993 14.25 16.6248Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.54199 16.6248C5.54199 15.7504 6.25087 15.0415 7.12533 15.0415C7.99978 15.0415 8.70866 15.7504 8.70866 16.6248C8.70866 17.4993 7.99978 18.2082 7.12533 18.2082C6.25087 18.2082 5.54199 17.4993 5.54199 16.6248Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.791667C0 0.354441 0.354441 0 0.791667 0H3.95833C4.33566 0 4.66055 0.266305 4.7346 0.636297L5.39951 3.95833H18.2083C18.4443 3.95833 18.6679 4.06357 18.8183 4.24536C18.9687 4.42714 19.0302 4.66654 18.986 4.8983L17.7182 11.5463C17.6096 12.093 17.3121 12.5841 16.878 12.9337C16.4459 13.2815 15.9059 13.467 15.3515 13.4583H7.67018C7.1158 13.467 6.57574 13.2815 6.14371 12.9337C5.7097 12.5843 5.41234 12.0934 5.30364 11.547C5.30359 11.5468 5.30368 11.5472 5.30364 11.547L3.98113 4.93937C3.97576 4.91751 3.9713 4.8953 3.9678 4.87277L3.30942 1.58333H0.791667C0.354441 1.58333 0 1.22889 0 0.791667ZM5.71641 5.54167L6.8565 11.2379C6.89269 11.4201 6.99184 11.5838 7.13657 11.7003C7.2813 11.8168 7.4624 11.8787 7.64816 11.8751L7.66333 11.875H15.3583L15.3735 11.8751C15.5593 11.8787 15.7404 11.8168 15.8851 11.7003C16.0292 11.5843 16.128 11.4216 16.1647 11.2404L17.2514 5.54167H5.71641Z"/>
      </g>
      <path d="M23.6098 7.35736H21.6441V5.39168C21.6441 4.75725 21.0932 4.31049 20.501 4.31049C19.9087 4.31049 19.3579 4.75725 19.3579 5.39168V7.35736H17.3922C16.7577 7.35736 16.311 7.90822 16.311 8.50049C16.311 9.09276 16.7577 9.64361 17.3922 9.64361H19.3579V11.6093C19.3579 12.2437 19.9087 12.6905 20.501 12.6905C21.0932 12.6905 21.6441 12.2437 21.6441 11.6093V9.64361H23.6098C24.2442 9.64361 24.691 9.09276 24.691 8.50049C24.691 7.90822 24.2442 7.35736 23.6098 7.35736Z" stroke="white" strokeWidth="0.88"/>
      <defs>
        <clipPath id="clip0_5265_2403">
          <rect width="19" height="19" fill="white"/>
        </clipPath>
      </defs>
    </Icon>
  );
};