import {ContextsComposant} from '@/utils/ContextsComposant';

// Code commenté en attendant de savoir ce que l'on fait au sujet des drapeaux

// export const ListeDrapeaux:React.FC<{listepays:string[], max?:number, context?: ContextsComposant;}> = ({listepays, max=12, context='other'})=>{
//   const {t} = useTranslation('common', {keyPrefix:'countries'});
//   const paysSorted = trierPays(listepays, 'FR');

//   if (context !== 'fiche_produit' && listepays.length !== 1) {
//     return null;
//   }

//   return <>
//     {paysSorted.slice(0, max).map((pays) => (
//       <Tooltip key={pays} label={t(pays)}><Img key={pays} alt={`Drapeau ${t(pays)}`} src={imageOptimisee(`${import.meta.env['VITE_MEDIA_URL']}drapeaux/${pays}.svg`, 20)} boxSize="20px" borderRadius='50%'/></Tooltip>
//     ))}
//     {listepays.length > max && <Tooltip label={paysSorted.slice(max).map((p)=><Box>{t(p)}</Box>)}><Text fontSize='sm' fontWeight='600'>...</Text></Tooltip>}
//   </>;
// };

// const trierPays = (pays: string[], premierPays: string) => {
//   return pays.sort((a, b)=>a === premierPays ? -1 : b === premierPays ? 1 : 0);
// };

export const ListeDrapeaux:React.FC<{listepays?:string[], max?:number, context?: ContextsComposant;}> = ()=>{
  return null;
};
