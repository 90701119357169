import {ButtonProps} from '@chakra-ui/react';

export const selectedProps = (isSelected: boolean) => {
  const buttonProps: ButtonProps = {};
  if (isSelected) {
    buttonProps.className = 'active';
    buttonProps.borderColor = 'blue.main';
    buttonProps.background = 'rgba(0, 114, 186, 0.1)';
    buttonProps.color = 'blue.main';
    buttonProps.variant = 'solid';
  }
  return buttonProps;
};
