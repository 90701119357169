import {Image, ImageProps} from '@chakra-ui/react';

interface CategorieImageProps extends Omit<ImageProps, 'src'> {
  categorieId: string;
  libelle: string;
}

const getCategorieImageUrl = (categorieId: string): string => {
  // split pour récupérer l'image
  const idCategoriePrincipale = categorieId.split('-')[0];
  return `${import.meta.env.BASE_URL}/categories/${idCategoriePrincipale}.webp`;
};

export const CategorieImage: React.FC<CategorieImageProps> = ({categorieId, libelle, ...props}) => {
  const imageUrl = getCategorieImageUrl(categorieId);
  const fallbackUrl = `${import.meta.env.BASE_URL}/categories/101.webp`;

  return (
    <Image
      src={imageUrl}
      alt={libelle}
      fallbackSrc={fallbackUrl}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.src = fallbackUrl;
      }}
      {...props}
    />
  );
};
