import {useAfficherPanier} from '@/hooks/livraison/use-afficher-panier';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

export const RedirectToPanier = () => {
  const {date_de_livraison} = useParams();
  const afficherPanier = useAfficherPanier();

  useEffect(() => {
    if (date_de_livraison) {
      afficherPanier(date_de_livraison);
    }
  }, [date_de_livraison, afficherPanier]);

  return <></>;
};
