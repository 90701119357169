import {Box,Grid,Menu,MenuItem,MenuList, VStack} from '@chakra-ui/react';
import {ButtonUser} from './ButtonUser';
import {UserBlackIcon} from '@/components/svg/UserBlackIcon';
import {OrderIcon} from '@/components/svg/OrderIcon';
import {FactureIcon} from '@/components/svg/FactureIcon';
import {ShippingIconBlack} from '@/components/svg/ShippingBlackIcon';
import {useClient, useMe} from '@/haligator-factory';
import {HaliRenderOne, useHalState} from '@synako/haligator';
import {routes} from 'ff-common';
import {Text} from '@chakra-ui/react';
import {SettingsIcon} from '@/components/svg/SettingsIcon';
import {RepeatIcon, UnlockIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {Link} from 'react-router-dom';
import {ContactsIcon} from '@/components/svg/ContactsIcon';

export const UserMenu = () => {
  const {t} = useTranslation('common');
  const client = useClient();
  const switch_ = useHalState(useMe().follow('switch'));
  const {buildAssistanceRoute} = useIsAssistanceMode();

  const listMenuItems = [
    {icon: <UserBlackIcon />, label: t('menu.informations'), route: routes.client.profil},
    {icon: <OrderIcon />, label: t('menu.commandes'), route: routes.client.commandes},
    {icon: <FactureIcon color='black' height='25px' width='25px'/>, label: t('menu.factures_et_avoirs'), route: routes.client.factures},
    {icon: <ShippingIconBlack />, label: t('menu.bons_de_livraison'), route: routes.client.livraisons},
    {icon:<ContactsIcon color='black' height='23px' width='23px'/>, label: t('menu.contacts'), route: routes.client.contacts},
    {icon: <SettingsIcon color='black'/>, label: t('menu.preferences'), route: routes.client.preferences},
    {icon: <UnlockIcon color='black'/>, label: t('menu.deconnecter'), href: '/authn/logout'},
  ];

  // si l'utilisateur peut se connecter à plusieurs clients
  // On ajoute une item pointant vers la page de sélection
  if ((switch_.data?.items.length ?? 0) > 1) {
    // insertion du nouvel item avant 'Me déconnecter'
    listMenuItems.splice(listMenuItems.length-1, 0, {
      icon: <RepeatIcon color="black" />,
      label: t('menu.changer_de_client'),
      href: routes.client.clientSelect.path
    });
  }

  return <HaliRenderOne
    nav={client}
    render={({data}) => <Box display='flex' alignItems='center'>
      <Menu>
        <ButtonUser nom={data.tolede.raison_sociale ?? data.tolede.code_client} />
        <MenuList position='relative'>
          {listMenuItems.map((item)=>(
            <UserMenuItem 
              key={`usermenu_${item.label}`} 
              icon={item.icon} 
              label={item.label} 
              href={item.href ?? buildAssistanceRoute(item.route)}
            />))}
          <Box borderTop='1px solid gray.light' />
          <MenuItem
            as={VStack}
            color='gray.dark'
            fontWeight='600'
            fontSize='sm'
          >
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
    }
  />;
};

export const UserMenuItem: React.FC<{icon: React.ReactNode; label: string; href: string | undefined}> = ({icon, label, href}) => {
  // condition pour utiliser soit un lien <a> pour la déconnexion, soit un Link  pour les routes
  if (href === '/authn/logout') {
    return (
      <a href={href}>
        <MenuItem as={Grid} fontSize='sm' fontWeight='500'>
          <VStack mr='10px' width='26px' justifyContent='center'>
            {icon}
          </VStack>
          <Text>{label}</Text>
        </MenuItem>
      </a>
    );
  }

  return (
    <Link to={href ?? '#'}>
      <MenuItem as={Grid} fontSize='sm' fontWeight='500'>
        <VStack mr='10px' width='26px' justifyContent='center'>
          {icon}
        </VStack>
        <Text>{label}</Text>
      </MenuItem>
    </Link>
  );
};
