import {alertAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const {definePartsStyle, defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
  title: {
    fontWeight:'normal'
  },
  container: {
    bg: 'white',
    border: '1px solid',
    borderColor: 'green.success',
    boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.25); '
  },
});

export const alerteTheme = defineMultiStyleConfig({baseStyle});