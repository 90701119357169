import {Metier} from 'api-types/ffconnect';
import {imageOptimisee} from './optimisation';

const imagePaths = {
  A1: '/restauration_commerciale_3.webp',
  A2: '/restauration_commerciale_2.webp',
  A3: '/restauration_commerciale_3.webp', //image par defaut (A1)
  A4: '/vitrine_5.webp',
  A5: '/restauration_commerciale_3.webp', //image par defaut (A1)
  A6: '/restauration_commerciale_3.webp', //image par defaut (A1)
  B1: '/patisserie_3.webp',
  B2: '/2024_03_08_FF_Romain_Le_Gal_MOF_117.webp',
  B3: '/traiteur_1.webp',
  B4: '/autres_1.webp',
  E1: '/restauration_commerciale_3.webp', //image par defaut (A1)
  P1: '/restauration_commerciale_3.webp', //image par defaut (A1)
};

export const getImageUrlOptimisee = (metier: Metier, width: number) => {
  const basePath = `${import.meta.env['VITE_MEDIA_URL']}/metiers`;
  const imagePath = imagePaths[metier as keyof typeof imagePaths] || imagePaths.A1; // A1 comme image par défaut
  return imageOptimisee(`${basePath}${imagePath}`, width);
};

export const getObjectPosition = (metier: string) => {
  switch (metier) {
    case 'A2':
      return 'bottom';
    case 'B1':
      return 'bottom';
    case 'B3':
      return 'bottom';
    default:
      return 'center'; // Valeur par défaut
  }
};

export const getTextColor = () => {
  return 'black.text';
};

export const getTextShadow = () => {
  return '2px 2px 10px white, 0px 0px 10px white, 0px 0px 15px white';
};
