export default {
  global: {
    'html, body': {
      backgroundColor: 'gray.bg',
      color: 'gray.900',
      fontSize: '16px',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
    },
    a: {
      fontWeight: 500,
    },
    '#chakra-toast-manager-top-right':{
      mt:'110px',
    }
  },
};
