import {Icon} from '@chakra-ui/react';

export const ContactsIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='white', width='21px', height='20px'}) => {
  return (
    <Icon
      width={width}
      height={height} 
      viewBox="0 0 25 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2222 21H8.33333C7.71449 21 7.121 20.7471 6.68342 20.2971C6.24583 19.847 6 19.2365 6 18.6V7.4C6 6.76348 6.24583 6.15303 6.68342 5.70294C7.121 5.25286 7.71449 5 8.33333 5H19.2222C19.4285 5 19.6263 5.08429 19.7722 5.23431C19.9181 5.38434 20 5.58783 20 5.8V20.2C20 20.4122 19.9181 20.6157 19.7722 20.7657C19.6263 20.9157 19.4285 21 19.2222 21ZM18.4444 19.4V17.8H8.33333C8.12705 17.8 7.92922 17.8843 7.78336 18.0343C7.6375 18.1843 7.55556 18.3878 7.55556 18.6C7.55556 18.8122 7.6375 19.0157 7.78336 19.1657C7.92922 19.3157 8.12705 19.4 8.33333 19.4H18.4444ZM7.55556 16.336C7.799 16.248 8.06111 16.2 8.33333 16.2H18.4444V6.6H8.33333C8.12705 6.6 7.92922 6.68429 7.78336 6.83431C7.6375 6.98434 7.55556 7.18783 7.55556 7.4V16.336ZM13 11.4C12.5874 11.4 12.1918 11.2314 11.9001 10.9314C11.6083 10.6313 11.4444 10.2243 11.4444 9.8C11.4444 9.37565 11.6083 8.96869 11.9001 8.66863C12.1918 8.36857 12.5874 8.2 13 8.2C13.4126 8.2 13.8082 8.36857 14.0999 8.66863C14.3917 8.96869 14.5556 9.37565 14.5556 9.8C14.5556 10.2243 14.3917 10.6313 14.0999 10.9314C13.8082 11.2314 13.4126 11.4 13 11.4ZM10.6667 14.6C10.6667 13.9635 10.9125 13.353 11.3501 12.9029C11.7877 12.4529 12.3812 12.2 13 12.2C13.6188 12.2 14.2123 12.4529 14.6499 12.9029C15.0875 13.353 15.3333 13.9635 15.3333 14.6H10.6667Z"/>
    </Icon>
  );
};