import {Text, VStack} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {DotLoaderSlow} from '@/components/Loading/DotLoaderSlow';

export const SkeletonChargementRequeteGetDispo: React.FC = () => {
  const {t} = useTranslation('common');
  return <>
    <VStack py='50'>
      <Text>{t('modale_livraison.nous-requetons-les-disponibilites-de-larticle')}</Text>
      <DotLoaderSlow />
    </VStack>
  </>;
};
