import {useCallback, useEffect, useState} from 'react';
import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {useClient} from '@/haligator-factory';
import {useHalState} from '@synako/haligator';
import {UniteQuantite} from 'api-types/ffconnect';
import {useLigneDePanierCommands} from 'ff-common';
import {TextToastCommand} from 'ff-common/src/types/TextToastCommand';
import {useDefaultOnError} from '../useDefaultOnError';
import {ModaleRupture} from '@/domains/cadencier/components/ModaleRupture';

export const useSelectionParColis = (
  ligneDePanier:State<LigneDePanierDesc>|undefined,
  code_article:string|undefined,
  textToast: TextToastCommand
) => {
  //Selection par defaut:
  // - X Check si une ligne de panier en cours est en mode colis
  // - X Sinon, check les preferences utilisateurs
  // - > Sinon, unitaire

  const {state:preferences} = useHalState(useClient().follow('preferences'));
  const [selectionParColis, setStateSelectionParColis] = useState<boolean>(ligneDePanier ? ligneDePanier.data.commande_en=='colis' : false); //FIXME
  
  useEffect(()=>{
    if (!preferences) return;
    if (!code_article) return;
    const ColisParDefaut = preferences.data.articles_en_colis?.includes(code_article);
    if (ColisParDefaut) setStateSelectionParColis(
      //Applique l'etat de la ligne en priorité, sinon la valeur par defaut
      ligneDePanier ? ligneDePanier.data.commande_en=='colis' : ColisParDefaut);
  }, [preferences, setStateSelectionParColis, code_article, ligneDePanier]);

  const onError = useDefaultOnError(textToast);
  const {switchUnite} = useLigneDePanierCommands(ligneDePanier, textToast, onError, ModaleRupture);
  const setSelectionUnite = useCallback(async (unite: UniteQuantite)=>{
    setStateSelectionParColis(unite==='colis');
    if (ligneDePanier) {
      return switchUnite.submitAsync({unite});
    }
    return;
  },[ligneDePanier, switchUnite]);

  return {selectionParColis, setSelectionUnite, switchUnite};
};
