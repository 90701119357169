import {extendTheme} from '@chakra-ui/react';
import styles from './styles';
import colors from './colors';
import {fonts} from './fonts';
import {checkboxTheme} from './components/Checkbox';
import {radioTheme} from './components/Radio';
import {tableTheme} from './components/Table';
import {alerteTheme} from './components/Alerte';
import {Text} from './components/Text';
import {breakpoints} from './breakpoints';
import {DrawerSize} from './components/Drawer';
import {statutBadgeTheme} from './components/StatutBadge';
import {Button} from './components/Button';
import {fontSizes} from './fontSizes';

const overrides = {
  styles,
  colors,
  fonts,
  fontSizes,
  components: {
    Text,
    Checkbox: checkboxTheme,
    Radio: radioTheme,
    Table: tableTheme,
    Drawer: DrawerSize,
    Alert: alerteTheme,
    StatutBadge: statutBadgeTheme,
    Button
  },
  breakpoints,
};

export default extendTheme(overrides);