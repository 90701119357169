import {Button, HStack, useBreakpointValue} from '@chakra-ui/react';
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';

interface PaginationProps {
  pageActuelle: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({pageActuelle, totalPages, onPageChange}) => {
  const viewMobile = useBreakpointValue({base: true, lg: false});

  const styleBoutonsPagination = {
    boxSize: {base: '25px', lg: '40px'},
    variant: 'outline',
    colorScheme: 'blue',
    borderColor: 'blue.500',
    _hover: {
      borderColor: 'white',
      bg: 'blue.300'
    }
  };

  const paginationBoutons = () => {
    const boutons = [];
    const maximumBoutonsVisibles = !viewMobile ? 6 : 3;
    let pageDebut = Math.max(1, pageActuelle - Math.floor(maximumBoutonsVisibles / 2));
    const pageFin = Math.min(totalPages, pageDebut + maximumBoutonsVisibles - 1);

    if (pageFin - pageDebut + 1 < maximumBoutonsVisibles) {
      pageDebut = Math.max(1, pageFin - maximumBoutonsVisibles + 1);
    }

    for (let i = pageDebut; i <= pageFin; i++) {
      boutons.push(
        <Button
          {...styleBoutonsPagination}
          key={i}
          borderColor={i === pageActuelle ? 'white' : 'blue.500'}
          variant={i === pageActuelle ? 'solid' : 'outline'}
          onClick={() => {onPageChange(i);}}
        >
          {i}
        </Button>
      );
    }

    return boutons;
  };

  return (
    <HStack p='10px' justifyContent='center' spacing={{lg: 2, base: 1.5}}>
      <Button {...styleBoutonsPagination} isDisabled={pageActuelle <= 1} onClick={() => {onPageChange(1);}}>
        <ArrowLeftIcon boxSize='10px' />
      </Button>
      <Button {...styleBoutonsPagination} isDisabled={pageActuelle <= 1} onClick={() => {onPageChange(pageActuelle - 1);}}>
        <ChevronLeftIcon />
      </Button>
      {paginationBoutons()}
      <Button {...styleBoutonsPagination} isDisabled={pageActuelle >= totalPages} onClick={() => {onPageChange(pageActuelle + 1);}}>
        <ChevronRightIcon />
      </Button>
      <Button {...styleBoutonsPagination} isDisabled={pageActuelle >= totalPages} onClick={() => {onPageChange(totalPages);}}>
        <ArrowRightIcon boxSize='10px' />
      </Button>
    </HStack>
  );
};
