import {HookUseTypedFilters} from '@synako/typed-path';
import {ArrowUpDownIcon, ChevronDownIcon} from '@chakra-ui/icons';
import {Box, BoxProps, Checkbox,HStack, Select, Text} from '@chakra-ui/react';
import {Results} from '@orama/orama';
import {HitItemCatalogue, TemperatureLivraison} from 'api-types/ffconnect';
import {FilterIcon} from '@/components/svg/FilterIcon';
import {CatalogFilterModale} from './CatalogFilterModale';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import {PromotionIcon} from '@/components/svg/PromotionIcon';
import {Flex, FlexProps} from '@chakra-ui/layout';
import {FiltresItemCatalogue} from 'ff-common/src/hooks/search/use-search';

export const CatalogFilter: React.FC<{
  itemResults: Results<HitItemCatalogue> | undefined;
  direction?: FlexProps['direction'];
  filtersHook: HookUseTypedFilters<FiltresItemCatalogue>,
  props?:BoxProps,
}> = ({
  itemResults,
  direction,
  filtersHook,
  ...props
}) => {
  const paysOrigine = Object.entries(itemResults?.facets?.['pays_d_origine']?.values ?? {});
  const paysOrigineCount = itemResults?.facets?.['pays_d_origine'].count;
  const labels = Object.entries(itemResults?.facets?.['labels']?.values ?? {});
  const labelsCount = itemResults?.facets?.['labels'].count;
  const natureDuPrixFacets = itemResults?.facets?.['nature_du_prix'];
  const promoCount = natureDuPrixFacets?.values['promo'] ?? 0;
  const temperatureLivraisonFacets = itemResults?.facets?.['temperature_livraison']?.values ?? {};
  const fraisCount = temperatureLivraisonFacets['positif'] ?? 0;
  const surgeleCount = temperatureLivraisonFacets['negatif'] ?? 0;
  const {params: filters, change: changeFilter} = filtersHook;
  const {t} = useTranslation('common');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPromo = searchParams.get('from') === 'promo';
  const hasPromo = promoCount > 0;

  // On filtre avec le pays d'origine
  // const handlePaysChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedValue = event.target.value;
  //   changeFilter('pays_d_origine', selectedValue === 'pays_d_origine' ? undefined : selectedValue);
  // };

  // On filtre avec le label
  const handleLabelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    changeFilter('labels', selectedValue === 'label' ? undefined : selectedValue);
  };

  // On gere le classement des produits par ordre alphabetique
  const handleSortChange = () => {
    const newSortBy = filters.sortBy === 'alphabetique' ? undefined : 'alphabetique';
    changeFilter('sortBy', newSortBy);
  };

  // on filtre pour les produits surgelé/non surgelé
  const handleNonSurgeleSurgeleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    changeFilter('temperature_livraison', selectedValue === 'all' ? undefined : selectedValue as TemperatureLivraison);
  };

  return (
    <HStack pt={{base: '0', md:direction === 'column-reverse' ? '0' : '10px'}} pb={{base: '10px', md: '0'}} pr={{base: '0', md: '20px'}} pl={{base:'0', md:'5px'}}
      width="100%" justifyContent={{base: 'center', md: 'space-between'}} spacing={0} {...props}>
      {/* Version desktop */}
      <Flex gap={0}  direction={direction ?? 'row'} justifyContent='space-between' w='100%' display={{base:'none', md:'flex'}}>
        <HStack spacing={0} ml='15px'>
          {hasPromo &&
          <Box height='38px' p='7px 11px 7px 11px' borderLeftRadius='8px' fontWeight="400"
            color="blue.main" border="1px" borderColor='gray.light' bg='white'>
            <Checkbox
              borderColor='gray.light'
              size='sm'
              isChecked={filters.nature_du_prix === 'promo'}
              onChange={(event) => {
                if (event.target.checked) {
                  changeFilter('nature_du_prix', 'promo');
                } else {
                  changeFilter('nature_du_prix', undefined);
                }
              }}
              isDisabled={fromPromo}
              sx={{
                '& .chakra-checkbox__control': {
                  borderColor: 'gray.light',
                  _checked: {
                    bg: 'red.200',
                    borderColor: 'red.200',
                  },
                },
              }}
            >
              <HStack>
                <Text color="red.200">{t('catalogue.filtre.promotions')} ({promoCount})</Text>
                <PromotionIcon height="24px" width="24px" color="red.200" />
              </HStack>
            </Checkbox>
          </Box>
          }
          <Select
            bg='white'
            borderColor='gray.light'
            borderRadius='none'
            borderLeftRadius={hasPromo ? '0px' : '8px'}
            borderRight='none'
            borderLeft={hasPromo ? 'none' : 'gray.light'}
            icon={<ChevronDownIcon color='blue.main' />}
            iconSize='xs'
            w='inherit'
            maxW={hasPromo ? '125px' : 'none'}
            fontSize='sm'
            fontWeight='400'
            height='38px'
            value={filters.labels ? filters.labels : 'label'}
            onChange={handleLabelChange}
            isDisabled={!labelsCount || labelsCount <= 0}
          >
            <option value="label">{t('catalogue.filtre.labels')} ({labelsCount})</option>
            {labels.map(([label, count]) => (
              count ? <option key={label} value={label}>{label} ({count})</option> : null
            ))}
          </Select>
          {/* <Select
            bg='white'
            borderColor='gray.light'
            borderTopLeftRadius='none'
            borderBottomLeftRadius='none'
            icon={<ChevronDownIcon color='blue.main' />}
            iconSize='xs'
            w='inherit'
            maxW={hasPromo ? '130px' : 'none'}
            fontSize='sm'
            fontWeight='400'
            height='38px'
            value={filters.pays_d_origine ? filters.pays_d_origine : 'pays_d_origine'}
            onChange={handlePaysChange}
            isDisabled={!paysOrigineCount || paysOrigineCount <= 0}
          >
            <option value="pays_d_origine">{t('catalogue.filtre.origine')} ({paysOrigineCount})</option>
            {paysOrigine.map(([pays, count]) => (
              <option key={pays} value={pays}>{t(`countries.${pays}`)} ({count})</option>
            ))}
          </Select> */}
          <Select
            bg='white'
            borderColor='gray.light'
            borderRadius='none'
            borderRightRadius='8px'
            icon={<ChevronDownIcon color='blue.main' />}
            iconSize='xs'
            w='inherit'
            fontSize='sm'
            fontWeight='400'
            height='38px'
            value={filters.temperature_livraison ?? 'all'}
            onChange={handleNonSurgeleSurgeleChange}
          >
            <option value="all">{t('catalogue.filtre.non_surgele_surgele')} ({fraisCount + surgeleCount})</option>
            { fraisCount && <option value="positif">{t('catalogue.filtre.non_surgele')} ({fraisCount})</option>}
            { surgeleCount && <option value="negatif">{t('catalogue.filtre.surgele')} ({surgeleCount})</option>}
          </Select>
        </HStack>
        <Select bg='white' height='38px' maxW='180px' pl='15px' mb={direction && '10px'} border='1px' borderRadius='8px' fontWeight="400" color="blue.main" borderColor='blue.main'
          fontSize='sm' icon={<ArrowUpDownIcon mr='5px'/>} iconSize='sm' w='auto' value={filters.sortBy ?? 'pertinence'}
          onChange={handleSortChange}>
          <option value="pertinence">{t('catalogue.filtre.tri')} {t('catalogue.filtre.pertinence')}</option>
          <option value="alphabetique">{t('catalogue.filtre.tri')} {t('catalogue.filtre.a-z')}</option>
        </Select>
      </Flex>
      {/* Version mobile */}
      <>
        <HStack bg="white" width='100%' spacing={0} display={{base:'flex', md:'none'}}>
          {hasPromo && <Box height='38px' fontWeight="400" color="blue.main" border="1px" borderColor='gray.light'
            flex={1} display="flex" alignItems="center" justifyContent="center">
            <HStack spacing={2}>
              <Checkbox
                borderColor='gray.light'
                size='sm'
                isChecked={filters.nature_du_prix === 'promo'}
                textColor='red.200'
                onChange={(event) => {
                  if (event.target.checked) {
                    changeFilter('nature_du_prix', 'promo');
                  } else {
                    changeFilter('nature_du_prix', undefined);
                  }
                }}
                isDisabled={fromPromo}
                sx={{
                  '& .chakra-checkbox__control': {
                    borderColor: 'gray.light',
                    _checked: {
                      bg: 'red.200',
                      borderColor: 'red.200',
                    },
                  },
                }}
              >
                {t('catalogue.filtre.promo')} ({promoCount})
              </Checkbox>
            </HStack>
          </Box>
          }
          <Box height='38px' fontSize='sm' fontWeight="400" color="grey.light" border="1px" borderColor='gray.light'
            flex={1} display="flex" alignItems="center" justifyContent="center" 
            onClick={()=>{NiceModal.show(CatalogFilterModale, {
              labels,
              labelsCount,
              paysOrigine,
              promoCount,
              paysOrigineCount,
              hasPromo,
              itemResults,
              filtersHook,
              fraisCount,
              surgeleCount,
            });}}
            cursor="pointer"
          >
            <HStack spacing={2}>
              <Text>{t('catalogue.filtre.filtrer')}</Text>
              <FilterIcon />
            </HStack>
          </Box>
          <HStack height='38px' spacing={2} flex={1} border="1px" borderColor='gray.light'
            display="flex" alignItems="center" justifyContent="center" cursor='pointer' onClick={handleSortChange}>
            <Text fontSize='sm' fontWeight="400">
              {t('catalogue.filtre.trier')}
            </Text>
            <ArrowUpDownIcon />
          </HStack>
        </HStack>
      </>
    </HStack>
  );
};
