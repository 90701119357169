import {TFunction} from 'i18next';
import {BuyButton} from './BuyButton';
import NiceModal from '@ebay/nice-modal-react';
import {PrecommandeModal} from '@/domains/ligneProduit/PrecommandeModal';
import {ItemCatalogueDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';

export const PrecommandeButton: React.FC<{
  itemState: State<ItemCatalogueDesc>,
  t: TFunction
}> = ({
  itemState,
  t
}) => {
  return (
    <BuyButton
      libelle= {t('bouton_achat.preco')}
      onClick={() => {
        NiceModal.show(PrecommandeModal, {
          itemState,
        });
      }} 
      isSubmitting={false}
    />
  );
};
