import {Icon} from '@chakra-ui/react';

export const CloseIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='#1A051D', width='16px', height='16px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 16 16" 
      fill={color}
    >
      <path d="M9.06066 8L15.5303 14.4697C15.8232 14.7626 15.8232 15.2374 15.5303 15.5303C15.2374 15.8232 14.7626 15.8232 14.4697 15.5303L8 9.06066L1.53033 15.5303C1.23744 15.8232 0.762563 15.8232 0.46967 15.5303C0.176777 15.2374 0.176777 14.7626 0.46967 14.4697L6.93934 8L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L8 6.93934L14.4697 0.46967C14.7626 0.176777 15.2374 0.176777 15.5303 0.46967C15.8232 0.762563 15.8232 1.23744 15.5303 1.53033L9.06066 8Z"/>
    </Icon>
  );
};