import {defineStyleConfig} from '@chakra-ui/react';

export const Button = defineStyleConfig({
  sizes: {
    sm: {
      height: '40px'
    },
    md: {
      height: '42px'
    },
    lg: {
      height: '44px'
    },
  },

  defaultProps: {
    size: 'md'
  },
});