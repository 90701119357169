import React from 'react';
import {Box, Heading, VStack, Button, Table, Tbody, Tr, Td, Th, Thead} from '@chakra-ui/react';
import {HaliRenderOne, useHalCollection} from '@synako/haligator';
import {useClient} from '@/haligator-factory';

export const CacheView: React.FC = () => {
  const cacheCollection = useHalCollection(useClient().follow('cache_tolede')); //find-cache_tolede

  return (
    <Box p={5} borderWidth="1px" borderRadius="lg" overflow="hidden" display="flex" flexDirection="column" alignItems="center" width="100%">
      <VStack spacing={4} align="stretch" width="100%">
        <Heading size="lg" textAlign="center">Test du cache</Heading>
        <Box overflow="auto" maxHeight="70vh" width="100%">
          <Table size="sm" variant="striped" width="100%">
            <Thead>
              <Tr>
                <Th>Operation ID</Th>
                <Th>Full Path</Th>
                <Th>Inv By</Th>
                <Th>Client ID</Th>
                <Th>Modified At</Th>
                <Th>Request ID</Th>
                <Th>Content</Th>
                <Th>Status</Th>
                <Th>Flush</Th>
              </Tr>
            </Thead>
            <Tbody>
              {cacheCollection.items?.map((item, index) => 
                <HaliRenderOne
                  nav={item}
                  render={({data}) =>  {
                    return <Tr key={data.id}>
                      <Td>{data.operation_id}</Td>
                      <Td maxWidth="250px">{data.full_path}</Td>
                      <Td>{data.inv_by}</Td>
                      <Td>{data.client_id}</Td>
                      <Td>{data.modified_at}</Td>
                      <Td>{data.request_id}</Td>
                      <Td>
                        <Button
                          minW="min(fit-content, 50%)"
                          borderRadius="0"
                          p="5"
                          borderY="1px solid"
                          borderX="0.5px solid"
                          colorScheme="blue"
                        >
                          Contenu
                        </Button>
                      </Td> { /* non implémenté */}
                      <Td>{data.status}</Td>
                      <Td>
                        <Button minW="min(fit-content, 50%)" borderRadius="0" p="5" borderY="1px solid" borderX="0.5px solid" colorScheme="blue" alignItems={'center'}
                          onClick={()=>{
                            cacheCollection.items?.[index]?.submitCommand('c:flush', {});
                          }}> 
                          Vider le cache
                        </Button>
                      </Td>
                    </Tr>;
                  }}/>)}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </Box>
  );
};

export default CacheView;
