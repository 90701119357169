import {Icon} from '@chakra-ui/react';

export const PolygonDown: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='lblue.100', width='30px', height='14px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 30 14" 
      fill={color}
    >
      <path d="M15 14L29.7224 0.5H0.277568L15 14Z"/>
    </Icon>
  );
};