import {Icon} from '@chakra-ui/react';

export const UserIcon: React.FC<{
  color?:string;
  width?:string;
  height?:string;
}> = ({color='white', width='21px', height='20px'}) => {
  return (
    <Icon
      width={width} 
      height={height} 
      viewBox="0 0 21 20" 
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.7459 10.5917C14.6038 9.94891 15.2299 9.06746 15.5373 8.06997C15.8446 7.07249 15.8179 6.00858 15.4608 5.02625C15.1036 4.04391 14.4339 3.19202 13.5448 2.58907C12.6556 1.98612 11.5912 1.66211 10.4997 1.66211C9.40817 1.66211 8.34379 1.98612 7.45463 2.58907C6.56547 3.19202 5.89574 4.04391 5.53863 5.02625C5.18151 6.00858 5.15477 7.07249 5.46211 8.06997C5.76945 9.06746 6.3956 9.94891 7.25344 10.5917C5.78351 11.1526 4.50094 12.0828 3.54247 13.2833C2.584 14.4838 1.98556 15.9094 1.81094 17.4084C1.7983 17.5178 1.80842 17.6285 1.84071 17.7342C1.87301 17.8399 1.92685 17.9386 1.99916 18.0245C2.14521 18.1979 2.35763 18.309 2.58969 18.3334C2.82176 18.3577 3.05446 18.2932 3.2366 18.1541C3.41875 18.015 3.53542 17.8127 3.56094 17.5917C3.75308 15.9627 4.56867 14.4582 5.85188 13.3657C7.1351 12.2732 8.79598 11.6692 10.5172 11.6692C12.2384 11.6692 13.8993 12.2732 15.1825 13.3657C16.4657 14.4582 17.2813 15.9627 17.4734 17.5917C17.4972 17.7965 17.5998 17.9856 17.7614 18.1225C17.923 18.2595 18.1321 18.3346 18.3484 18.3334H18.4447C18.6741 18.3082 18.8837 18.1978 19.0279 18.0261C19.1722 17.8544 19.2393 17.6353 19.2147 17.4167C19.0392 15.9135 18.4376 14.4842 17.4742 13.2819C16.5108 12.0795 15.222 11.1496 13.7459 10.5917V10.5917ZM10.4997 10C9.80746 10 9.13077 9.80453 8.5552 9.43825C7.97962 9.07198 7.53102 8.55139 7.26611 7.9423C7.00121 7.33321 6.9319 6.66299 7.06694 6.01639C7.20199 5.36979 7.53533 4.77584 8.02482 4.30967C8.5143 3.84349 9.13794 3.52602 9.81688 3.39741C10.4958 3.26879 11.1995 3.3348 11.8391 3.58709C12.4786 3.83938 13.0253 4.26662 13.4098 4.81479C13.7944 5.36295 13.9997 6.00742 13.9997 6.66669C13.9997 7.55074 13.6309 8.39859 12.9746 9.02371C12.3182 9.64883 11.428 10 10.4997 10Z"/>
    </Icon>
  );
};