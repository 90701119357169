import {HStack, Text, VStack} from '@chakra-ui/react';
import {ShippingCartIcon} from '@/components/svg/ShippingCartIcon';
import {useClient} from '@/haligator-factory';
import {useHalCollection} from '@synako/haligator';
import {CheckLoaded} from '@/containers/CheckLoaded';
import {LoadingSpinner} from '@/components/Loading/LoadingSpinner';
import NiceModal from '@ebay/nice-modal-react';
import {guessTitreAdresseDeLivraison} from 'ff-common';
import {CartonIcon} from '@/components/svg/CartonIcon';
import {useTranslation} from 'react-i18next';
import {ModaleChangerAdresseLivraison} from './ModaleChangerAdresseLivraison';
import {PanierCommands} from 'ff-common/src/hooks/panier/use-panier-commands';
import {Panier} from 'api-types/ffconnect';

export const PanierRecapAdresse: React.FC<{
  panier: Panier,
  panierWithCommands: PanierCommands
}> = ({
  panier,
  panierWithCommands
}) => {
  const {t} = useTranslation('common');
  const codeAdresse = panier.livraison_tolede.code_adresse_de_livraison;
  const mode_de_rendu = panier.livraison_tolede.mode_de_rendu ?? 'livraison';

  const clientAdressesHook = useHalCollection(useClient().follow('adresses_de_livraison'));
  const changerModeDeRenduOptionHook = panierWithCommands.changerModeDeRendu.options;

  const adresseRetrait = changerModeDeRenduOptionHook.data?.adresse_d_enlevement;

  return <CheckLoaded hook={clientAdressesHook.pageHook} render={() => {
    if (clientAdressesHook.items === undefined) return <LoadingSpinner />;
    const {tolede} = clientAdressesHook.items.find((adresse) => adresse.data.tolede.code_adresse_de_livraison === codeAdresse)!.data;
    const adresse = mode_de_rendu === 'livraison' ? tolede.adresse : adresseRetrait;
    const {nom_client, enseigne} = guessTitreAdresseDeLivraison(tolede);

    return <VStack bg='white'
      alignItems='stretch' p='5' borderRadius='8px' border='1px solid' borderColor='gray.light' textColor='black'>
      <HStack>
        {mode_de_rendu === 'livraison'
          ? <ShippingCartIcon color='black' />
          : <CartonIcon color='black' />}
        <Text>{t(`panier.recap.mode_de_rendu.${mode_de_rendu}`)}</Text>
        {changerModeDeRenduOptionHook.data?.bouton_modifier_actif && <Text textDecorationLine='underline' ml='auto' cursor='pointer'
          onClick={() => {
            NiceModal.show(ModaleChangerAdresseLivraison, {
              codeAdresse,
              changerModeDeRenduOptions: changerModeDeRenduOptionHook.state,
              validateAction: panierWithCommands.changerModeDeRendu.submit,
              states: clientAdressesHook.items,
              mode_de_rendu
            });
          }}
        >
          {t('global.modifier')}
        </Text>}
      </HStack>
      {adresse && <VStack alignItems='start' gap='0'>
        {mode_de_rendu === 'livraison' && <>
          {enseigne && <Text fontWeight='semibold'>{enseigne}</Text>}
          {nom_client && <Text fontWeight='semibold'>{nom_client}</Text>}
        </>}
        {adresse.ligne1 !== undefined && <Text fontSize='sm'>{adresse.ligne1}</Text>}
        {adresse.ligne2 !== undefined && <Text fontSize='sm'>{adresse.ligne2}</Text>}
        <Text fontSize='sm'>{adresse.code_postal} {adresse.ville}</Text>
      </VStack>}
    </VStack>;
  } } />;
};
