import {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {NiceModalHandler} from '@ebay/nice-modal-react';
import {ModaleLayout} from '../ModaleLayout';
import {OrderModalIcon} from '@/components/svg/OrderModalIcon';
import {VStack} from '@chakra-ui/react';

export const CalendarModale: React.FC<{
  modal: NiceModalHandler,
  messageKey: string,
  onCancel?: () => void,
  children: ReactNode
  size_desktop?: string | undefined,
}> = ({
  modal,
  messageKey,
  onCancel,
  children,
  size_desktop
}) => {
  const {t} = useTranslation('common');

  return (
    <ModaleLayout titre={t(`modale_livraison.header.${messageKey}`)} visible={modal.visible}
      headerIcon={<OrderModalIcon />}
      hide={modal.remove} onCancel={onCancel} size_desktop={size_desktop}>
      <VStack alignItems='stretch' 
        p={{base: '1', md: 'initial'}}>
        {children}
      </VStack>
    </ModaleLayout>
  );
};
