export const getLocale = (i18nLocale: string | undefined) => {
  if (i18nLocale) {
    return i18nLocale;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const locale = navigator.languages?.[0] ?? navigator.language;
  if (locale) {
    const [code] = locale.split('-');
    return code;
  }
  return 'fr';
};
