import {VStack} from '@chakra-ui/layout';
import {Box, Stack} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {LeftJeViensDeValider} from './PanierNotFound/LeftJeViensDeValider';
import {LeftPaniersAValider} from './PanierNotFound/LeftPaniersAValider';
import {Left404} from './PanierNotFound/Left404';
import {RightPaniersAValider} from './PanierNotFound/RightPaniersAValider';
import {RightJeViensDeValider} from './PanierNotFound/RightJeViensDeValider';
import {Right404} from './PanierNotFound/Right404';
import {HeaderPanierListe} from './controllers/HeaderPanierListe';
import {useLivraisons} from '@/haligator-factory';
import {useHalStates} from '@synako/haligator';

export const PanierNotFound: React.FC<{
  derniereAction?: string | null
}> = ({
  derniereAction
}) => {
  const {t} = useTranslation('common');

  const jeViensDeValider = derniereAction === 'valide';

  const livraisonsPossibles = useHalStates(useLivraisons().followAll('items'));
  if (!livraisonsPossibles.states || livraisonsPossibles.error) {
    return;
  }

  const paniers_a_valider = livraisonsPossibles.states.filter(livraison => livraison.hasOneLink('panier_ouvert'));
  const bordure = !jeViensDeValider;

  return <VStack p={{base:0, md: bordure ? 12 : 0}}
    position={{base:'fixed', md:'relative'}}
    zIndex={{base:'overlay', md:'initial'}}
    top='0'
    minH={{base:'100vh', md:'initial'}}
    gap='0'
    w='100%'
  >
    {/* Barre du haut, affichée uniquement en mobile */}
    <Box display={{base:'block', md:'none'}} w='100%'>
      <HeaderPanierListe nbPaniers={paniers_a_valider.length}/>
    </Box>
    
    <Stack direction={{base: 'column', md: 'row'}} flex='1' bg='white' w='100%' minH='80vh' py={{base:0, md:'28'}}
      justify={{base:'start', md:'center'}} alignItems='stretch' gap='5'>
      {jeViensDeValider ?
        <LeftJeViensDeValider t={t} />
        :<>{
          paniers_a_valider.length > 0 ?
            <LeftPaniersAValider t={t} nbPaniers={paniers_a_valider.length} />
            :
            <Left404 t={t} />
        }</>
      };
    
      {/* colonne de droite */}
      {paniers_a_valider.length > 0 ?
        <RightPaniersAValider 
          t={t} 
          jeViensDeValider={jeViensDeValider} 
          livraisonsAvecPanier={paniers_a_valider} 
        />
        :<>{
          jeViensDeValider ?
            <RightJeViensDeValider t={t} />
            :
            <Right404 t={t} />
        }</>
      };
    </Stack>
  </VStack>;
};
