import {CatalogueModale} from '@/domains/article/NomenclatureModale/CatalogueModale';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {routes} from 'ff-common';
import {Button, HStack, Text, VStack} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import {TFunction} from 'i18next';
import {Link} from 'react-router-dom';

export const Right404: React.FC<{t: TFunction}> = ({t}) => {
  const {buildAssistanceRoute} = useIsAssistanceMode();

  return (
    <VStack alignItems={{base:'stretch', md:'start'}} p='6'>
      <Text
        as='h2'
        fontSize={{base:'2xl', md:'3xl'}}
        fontWeight='700'
      >
        {t('panier.vide.votre_panier_est_vide')}
      </Text>
      <Text fontSize='lg' fontWeight='500' mb='5'>{t('panier.panier_vide_cta')}</Text>
      <HStack mb='5' gap='5' flexWrap='wrap'>
        <Button
          as={Link}
          to={buildAssistanceRoute(routes.client.cadencier)}
          colorScheme='blue'
          variant='outline'
          w={{base:'100%', md:'initial'}}
          _hover={{backgroundColor:'gray.light'}}
        >
          {t('erreur404.voir_cadencier')}
        </Button>
        <Button
          colorScheme='blue'
          variant='outline'
          w={{base:'100%', md:'initial'}}
          _hover={{backgroundColor:'gray.light'}}
          onClick={()=>{
            NiceModal.show(CatalogueModale);
          }}
        >
          {t('erreur404.voir_catalogue')}
        </Button>
      </HStack>
    </VStack>
  );
};
