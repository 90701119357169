import {MouseEvent} from 'react';
import {GridItem, HStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {DateButton} from './DateButton';
import {selectedProps} from '../calendar-utils';

export const CalendarCellPanier: React.FC<{
  date: Date,
  isSelected: boolean
  livraisonAssociee: State<LivraisonDesc>,
  handleDateClick: (livraison: State<LivraisonDesc>, event: MouseEvent<HTMLButtonElement>) => void
}> = ({
  date,
  isSelected,
  livraisonAssociee,
  handleDateClick,
}) => {
  const panierEnCours = livraisonAssociee.hasOneLink('panier_ouvert');

  return(
    <GridItem as={HStack} alignItems='stretch' justify='center'>
      <DateButton
        date={date}
        {...selectedProps(isSelected)}
        panierEnCours={panierEnCours}
        onClick={event => {
          handleDateClick(livraisonAssociee, event);
        }}
      />
    </GridItem>
  );
};
