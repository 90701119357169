import {useSearchParams} from 'react-router-dom';
import {useMemo} from 'react';

export const FROM_PARAMS_KEY = 'from'; // required
export const QUERY_PARAMS_KEY = 'query';

export default function useFromFilters() {
  const [queryParams] = useSearchParams();

  const fromFilters = useMemo(() => {
    if (!queryParams.has(FROM_PARAMS_KEY)) {
      return undefined;
    }

    return {
      [FROM_PARAMS_KEY]: queryParams.get(FROM_PARAMS_KEY),
      [QUERY_PARAMS_KEY]: queryParams.get(QUERY_PARAMS_KEY)
    };
  }, [queryParams]);

  return fromFilters;
}

export function encodeURI(value: string) {
  if (!value) {
    return;
  }
  return encodeURIComponent(value);
}

export function decodeURI(value: string | null) {
  if (!value) {
    return;
  }
  return decodeURIComponent(value);
}
