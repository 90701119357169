export const toDecimalFr: (
  number: number | undefined, 
  minDigit?:number, 
  maxDigit?:number
) => string = (number, minDigit=2, maxDigit=2) => {
  if (number) {
    return new Intl.NumberFormat(
      'fr-FR', {
        minimumFractionDigits: minDigit, 
        maximumFractionDigits: maxDigit
      }
    ).format(number);
  }
  return '0';
};
