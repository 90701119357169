import {TrashIcon} from '@/components/svg/TrashIcon';
import {Tooltip, Button} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {LigneDePanierCommands} from 'ff-common/src/hooks/ligneDePanier/use-ligne-de-panier-commands';

export const SupprimerErrorButton: React.FC<{
  // probleme: LigneProblem,
  // ligneDePanier: State<LigneDePanierDesc>,
  commands: LigneDePanierCommands,
  onClose?: () => void
  submittingOrRefreshing?: boolean,
}> = ({
  commands,
  onClose,
  submittingOrRefreshing
}) => {
  const {t} = useTranslation('common');
  const {somethingSubmitting, supprimer} = commands;
  const handleClick = async () => {
    supprimer.submit({});
    onClose?.();
  };
  return (
    <Tooltip 
      label={t('ligne_produit.indisponible.tooltip_rupture_totale')}
    >
      <Button 
        colorScheme='blue' 
        isDisabled={somethingSubmitting || submittingOrRefreshing}
        onClick={handleClick}
      >
        <TrashIcon />
      </Button>
    </Tooltip>

  );
};
