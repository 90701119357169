import React from 'react';
import {HStack, Text} from '@chakra-ui/react';
import {TimeIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';

export const LabelDisponibilite: React.FC<{precommande: boolean | undefined}> = ({precommande}) => {
  const {t} = useTranslation('common'); 
  if (!precommande) {
    return null;
  }

  return (
    <HStack position="absolute" top="10px" left="10px"  bg="lblue.100" color="blue.main" fontSize="xs" fontWeight="bold" py="2px" px="8px" borderRadius="md">
      <TimeIcon color="blue.main" />
      <Text fontWeight="500">
        {t('disponibilite.precommande')}
      </Text>
    </HStack>
  );
};
