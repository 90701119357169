import {WarningSignIcon} from '@/components/svg/WarningSignIcon';
import {dateAvecSlashes} from 'ff-common';
import {VStack, HStack, Text} from '@chakra-ui/react';
import {TFunction} from 'i18next';
import {ReactNode} from 'react';
import {Trans} from 'react-i18next';

export const ErreursLignesBlock: React.FC<{
  nbErreurs: number,
  dateDeLivraison: string,
  children: ReactNode,
  t: TFunction
}> = ({
  nbErreurs,
  dateDeLivraison,
  children,
  t
}) => (
  <VStack 
    bg='white' 
    gap='0' 
    alignItems='start'
    borderWidth={{base: '1px 0', lg: '1px'}}
    borderStyle="solid"
    borderColor="red.warning"
    borderRadius={{base: '0', lg: '8px'}} 
    maxW='100vw' 
    overflow='hidden' 
    mb='5'
  >
    <HStack p='5' textColor='red.warning'><WarningSignIcon />
      <Text>
        <Trans t={t}>
          {t('ligne_produit.indisponible.entete', {
            count: nbErreurs, 
            dateDeLivraison: dateAvecSlashes(dateDeLivraison)
          })}

        </Trans>
      </Text>
    </HStack>
    {children}
  </VStack>
);
