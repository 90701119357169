import {Icon, useColorModeValue} from '@chakra-ui/react';

export const PromoIcon: React.FC<{
  isDisabled?: boolean;
  width?: string;
  height?: string;
  color?: string;
}> = ({isDisabled = false, width = '19px', height = '20px', color}) => {
  const defaultColor = useColorModeValue('blue.main', 'blue.light');
  const disabledColor = useColorModeValue('gray.300', 'gray.600');

  const finalColor = isDisabled ? disabledColor : (color ?? defaultColor);

  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill={finalColor}
    >
      <path d="M5.93768 5.00872C5.70281 5.00872 5.47322 5.07837 5.27794 5.20885C5.08265 5.33933 4.93045 5.5248 4.84057 5.74178C4.75069 5.95877 4.72717 6.19754 4.77299 6.42789C4.81881 6.65824 4.93191 6.86984 5.09799 7.03591C5.26406 7.20198 5.47565 7.31508 5.70601 7.3609C5.93636 7.40672 6.17513 7.38321 6.39211 7.29333C6.6091 7.20345 6.79456 7.05124 6.92505 6.85596C7.05553 6.66068 7.12518 6.43109 7.12518 6.19622C7.12518 5.88128 7.00006 5.57923 6.77737 5.35653C6.55467 5.13383 6.25262 5.00872 5.93768 5.00872ZM16.7202 8.73747L10.0623 2.07164C9.98829 1.99826 9.90056 1.94022 9.8041 1.90082C9.70765 1.86142 9.60436 1.84145 9.50018 1.84205H2.37518C2.16521 1.84205 1.96385 1.92546 1.81538 2.07393C1.66692 2.22239 1.58351 2.42376 1.58351 2.63372V9.75872C1.58291 9.86291 1.60288 9.96619 1.64227 10.0626C1.68167 10.1591 1.73972 10.2468 1.81309 10.3208L8.47893 16.9787C8.92424 17.4235 9.52788 17.6733 10.1573 17.6733C10.7866 17.6733 11.3903 17.4235 11.8356 16.9787L16.7202 12.1337C17.1649 11.6884 17.4148 11.0848 17.4148 10.4554C17.4148 9.82601 17.1649 9.22237 16.7202 8.77705V8.73747ZM15.6039 10.97L10.7114 15.8546C10.5631 16.002 10.3624 16.0848 10.1533 16.0848C9.94415 16.0848 9.7435 16.002 9.59518 15.8546L3.16684 9.43414V3.42539H9.17559L15.6039 9.85372C15.6773 9.92769 15.7353 10.0154 15.7747 10.1119C15.8141 10.2083 15.8341 10.3116 15.8335 10.4158C15.8326 10.6235 15.7502 10.8225 15.6039 10.97Z" />
    </Icon>
  );
};
