import {useBreakpointValue} from '@chakra-ui/react';
import {PropsWithChildren, createContext, useMemo} from 'react';
import {breakpoints} from './breakpoints';

type Breakpoints = keyof typeof breakpoints;
export const BreakPointContext = createContext<{
  breakpoint:Breakpoints|undefined,
  isMobile:boolean
}|undefined>(undefined);

export const BreakpointProvider:React.FC<PropsWithChildren> = ({children})=>{
  const breakpoint = useBreakpointValue<Breakpoints>({base:'base', sm:'sm', md:'md', lg:'lg', xl:'xl', '2xl':'2xl'});
  const isMobile = useMemo(()=>breakpoint === 'base' || breakpoint === 'sm', [breakpoint]);
  return <BreakPointContext.Provider value={{isMobile, breakpoint}}>
    {children}
  </BreakPointContext.Provider>;
};