import {useClient} from '@/haligator-factory';
import NiceModal from '@ebay/nice-modal-react';
import {Outlet, ScrollRestoration} from 'react-router-dom';
import {ChargementCompte} from './ChargementCompte';
import {CompteBloque} from './CompteBloque';
import {LivraisonsPossiblesContextProvider} from 'ff-common';
import {BreakpointProvider} from '@/chakra/BreakpointProvider';
import {SearchClientProvider, useClientAppDisplay} from 'ff-common';

export const ClientApp : React.FC = () => {
  // const force = new URLSearchParams(location.search).get('force');
  const client = useClient();
  
  const clientApp = useClientAppDisplay(
    (prochaineLivraison) => <LivraisonsPossiblesContextProvider initialLivraison={prochaineLivraison}>
      <NiceModal.Provider>
        <Outlet/>
      </NiceModal.Provider>
    </LivraisonsPossiblesContextProvider>,
    (idMessage:string) =>
      <ChargementCompte idMessage={idMessage}/>,
    () =>
      <CompteBloque />,
    client
  );

  return (
    <SearchClientProvider client={client}>
      <BreakpointProvider>
        <ScrollRestoration />
        {clientApp}
      </BreakpointProvider>
    </SearchClientProvider>
  );
};