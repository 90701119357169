import {useRef} from 'react';
import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {LigneProblem} from 'api-types/ffconnect';
import {useLigneDePanierCommands} from 'ff-common';
import {ProduitPanierErrorCommun} from './ProduitPanierErrorCommun';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {ModaleRupture} from '@/domains/cadencier/components/ModaleRupture';
import {HaliRenderOne} from '@synako/haligator';

export interface ProduitPanierErrorProps {
  ligneDePanier?: State<LigneDePanierDesc>,
  probleme: LigneProblem,
  idError: string,
  validationRefresh: boolean,
}

export const ProduitPanierError: React.FC<ProduitPanierErrorProps> = (props) => {
  const {
    idError,
    probleme,
    ligneDePanier: lp,
    validationRefresh,
  } = props;
  // Cela règle le souci qu'il faut afficher l'ancienne ligne de panier potentiellement supprimée alors qu'on est en train de refresh
  const refLigneDePanier = useRef(lp);
  if (lp) {
    refLigneDePanier.current = lp;
  }
  const ligneDePanier = refLigneDePanier.current;

  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const commands = useLigneDePanierCommands(ligneDePanier, textToast, onError, ModaleRupture);
  
  // Alors qu'on l'action a submitted, il faut quand même garder le fait qu'on soit en 
  const {somethingSubmitting} = commands;

  if (!ligneDePanier) {return null;} // Ne devrais jamais arriver

  return (
    <HaliRenderOne
      nav={ligneDePanier.follow('item')}
      render={({data}) => {
        return (
          <ProduitPanierErrorCommun
            idError={idError}
            probleme={probleme}
            ligneDePanier={ligneDePanier}
            submittingOrRefreshing={somethingSubmitting || validationRefresh}
            multipleCommandeClient={data.tolede.multiple_de_commande_client}
          />
        );
      }}
    />
  );
};
