import {HStack} from '@chakra-ui/layout';
import {Alert, Box, Button} from '@chakra-ui/react';
import {ReactNode} from 'react';
import {CloseIcon} from '@/components/svg/CloseIcon';
import {CheckCircleToastIcon} from '@/components/svg/CheckCircleIcon';

export const CustomToast: React.FC<{icon?: ReactNode; body: ReactNode; onClose: () => void;}> = ({icon = <CheckCircleToastIcon />, body, onClose}) => {
  return <Alert borderRadius='8px'>
    <HStack alignItems='start' pr='4'>
      {icon}
      <Box maxW='300px'>{body}</Box>
    </HStack>
    <Button variant='ghost' onClick={onClose} p='1' position='absolute' right='0' top='0'><CloseIcon color='green.success' /></Button>
  </Alert>;
};
