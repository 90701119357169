/**
 * Héberge le state de sélection de la livraison pour toute l'application
 */
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {createContext,ReactNode, useState} from 'react';

export interface LivraisonsPossiblesContextValue {
  livraisonSelectionnee: State<LivraisonDesc>,
  date_de_livraison: string,
  setLivraisonSelectionnee: (selected: State<LivraisonDesc>) => void,
}

export const LivraisonsPossiblesContext = createContext<Partial<LivraisonsPossiblesContextValue>>({});

export const LivraisonsPossiblesContextProvider: React.FC<{
  initialLivraison: State<LivraisonDesc>,
  children: ReactNode
}> = ({initialLivraison, children})=>{
  // initialisation du state avec la 1ère livraison possible
  const [
    livraison, 
    setLivraisonSelectionnee
  ] = useState<State<LivraisonDesc>>(initialLivraison);

  const contextValue: LivraisonsPossiblesContextValue = {
    livraisonSelectionnee: livraison,
    date_de_livraison: livraison.data.tolede.date_de_livraison, 
    setLivraisonSelectionnee,
  };

  // console.log("RERENDER LivraisonsPossiblesContext.Provider");
  return (
    <LivraisonsPossiblesContext.Provider value={contextValue}>
      {children}
    </LivraisonsPossiblesContext.Provider>
  );
};