import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {Box, HStack, VStack, Text, Flex} from '@chakra-ui/layout';
import {Button, Collapse, useDisclosure} from '@chakra-ui/react';
import React, {useCallback} from 'react';
import {State} from '@synako/halitrek';
import {ArticleCadencierEnleveEventDesc, ItemCatalogueDesc, ItemListeSelectionDesc, LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {HaliRenderOne, useCommand, useHalState} from '@synako/haligator';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {Trans, useTranslation} from 'react-i18next';
import {ArticleBlock} from '@/domains/panier/PanierFound/ArticleBlock';
import {TrashIcon} from '@/components/svg/TrashIcon';
import {PoidsVariableTooltip} from '@/domains/panier/PanierFound/ProduitPanier/PoidsVariableTooltip';
import {LabelDisponibilite} from '@/domains/catalogue/components/LabelDisponibilite';
import {PrixBlockUnitaire} from '../components/PrixBlockUnitaire';
import {useBuyingBlocks} from '../../../hooks/panier/useBuyingBlocks';
import {LigneProduitLayout} from '@/domains/liste/components/LigneProduitLayout';
import {ProduitDejaCommande} from '@/domains/ligneProduit/ProduitDejaCommande';

export const CadencierSection: React.FC<{
  title: string;
  id: string;
  produits: State<ItemListeSelectionDesc>[];
  editMode?: boolean;
  lignesDePanier: State<LigneDePanierDesc>[];
  isMobile: boolean;
}> = React.memo(({
  title,
  id,
  produits,
  editMode = false,
  lignesDePanier,
  isMobile
}) => {
  const {isOpen, onToggle} = useDisclosure();

  // console.log('RERENDER : CadencierSection '+title);

  return <VStack alignItems='stretch' width='100%' mt='1' opacity={'100%'} id={id}>
    <HStack margin={{base: '2', lg: '0'}}>
      <Text fontWeight='600'>{title}</Text>
      <Box textColor='gray.dark' fontWeight='600' fontSize='xs' bg='gray.light' p='1' borderRadius='8px' minW={'2em'} aspectRatio={1} textAlign='center'>
        {produits.length}
      </Box>
      <Button variant='ghost' p='0' onClick={onToggle}>{!isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button>
    </HStack>
    <Collapse in={!isOpen}>
      <Box marginBottom='1px'>
        {produits.map((p:State<ItemListeSelectionDesc>) => {
          return (
            <HaliRenderOne key={`ligne_cadencier_${p.data.id}`} nav={p.follow('item_catalogue')} render={({state: itemState}) => {
              const ligneDePanier = lignesDePanier.find((l)=>l.data.code_article===p.data.tolede.code_article);
              return <LigneCadencier produit={p}
                canDelete={editMode}
                ligneDePanier={ligneDePanier}
                itemState={itemState}
                isMobile={isMobile}
              />;
            }}/>
          );
        }
        )}
      </Box>
    </Collapse>
  </VStack>;
});

const LigneCadencier: React.FC<{
  produit: State<ItemListeSelectionDesc>,
  canDelete: boolean,
  itemState:State<ItemCatalogueDesc>,
  ligneDePanier:State<LigneDePanierDesc>|undefined,
  isMobile: boolean
}> 
= React.memo(({produit, canDelete, itemState, ligneDePanier, isMobile}) => {
  // console.log('RERENDER : LigneCadencier '+itemState.data.tolede.code_article);

  const textToast = useSimpleTextToast();
  const {state: articleState, loading: articleLoading} = useHalState(produit.follow('article'));
  const {t} = useTranslation('common');
  const {code_article} = produit.data.tolede;

  const onSuccess = useCallback((event: State<ArticleCadencierEnleveEventDesc>)=>{
    switch (event.data.data.logique) {
      case 'avertissement':
        textToast(<Trans t={t}>{t('toast.supprime_cadencier_safelive', {code_article})}</Trans>);
        break;
      case 'simulation':
        textToast(<Trans t={t}>{t('toast.supprime_cadencier_simulation', {code_article})}</Trans>);
        break;
      default:
        textToast(<Trans t={t}>{t('toast.supprime_cadencier', {code_article})}</Trans>);
        break;
    }
  }, [textToast, t, code_article]);
  const onError = useDefaultOnError(textToast);
  const {submit:submitEnleverDuCadencier, isDisabled:deleteEnCours, isSubmitting} = useCommand(produit.resource, 'c:enlever-du-cadencier', {onSuccess, onError}); 

  const deleteAction = useCallback(()=>{
    submitEnleverDuCadencier({code_article});
  }
  ,[code_article, submitEnleverDuCadencier]);

  const precommande = itemState.data.tolede.precommande;
  const prixBlock = <PrixBlockUnitaire
    prixUnitaire={itemState.data.tolede.prix_par_livraison![0].montant_ht}
    uniteFacturation={itemState.data.tolede.unite_de_facturation} 
    tauxTVA={itemState.data.tolede.taux_de_tva}  
  />;

  const deleteBlock = canDelete && <Button alignSelf='center' ml={{base:'auto', lg:'2em'}} leftIcon={<TrashIcon />} variant='ghost'
    onClick={deleteAction} isDisabled={deleteEnCours || isSubmitting}
  >
    {t('ligne_produit.retirer_le_produit')}
  </Button>;

  const {uniteQuantiteBlock, buyBlock} = useBuyingBlocks(textToast, itemState, ligneDePanier);

  if(articleLoading) {
    return 'Chargement...'; // en embedded n'arrive jamais normaleent
  }
  if (!articleState) {
    return <div>Article dans le cadencier mais pas dans la base FF {code_article}</div>;
  }

  const articleBlock = <ArticleBlock
    code_article={code_article}
    article={articleState.follow('self')}
  />;
  const selectBlock = <Box 
    mt='15px'
    ml='0'
    minW='fit-content'
  >
    {uniteQuantiteBlock({minW:'245px', w:'245px'})}
    {articleState.data.tolede.a_peser && <PoidsVariableTooltip/>}
  </Box>;

  const precommandeBlock = precommande && <LabelDisponibilite precommande={precommande}/>;
  const produitDejaCommandeBlock = <ProduitDejaCommande itemState={itemState} />;

  return <LigneProduitLayout precommande={precommande}>
    {!isMobile ?
    /* Version desktop */
      <VStack w='100%'>
        <Flex w='100%' direction={{base: 'column', '2xl': 'row'}} gap={2}>
          <HStack  flex="1" flexWrap="wrap" spacing={4} w={{base: '100%'}}>
            {precommandeBlock}
            <HStack maxWidth={{lg: '350px', xl: '380px', '2xl': 'none'}}>
              {articleBlock}
            </HStack>
            <HStack alignItems='start' justifyContent='end' ml='auto' flexGrow={1}>
              {prixBlock}
              {selectBlock}
              {buyBlock({somethingSubmitting:isSubmitting, afficher_bouton_liste:true, minW:'280px', w:'280px'})}
            </HStack>
          </HStack>
          <HStack justifyContent={{base: 'flex-end', '2xl': 'flex-start'}} alignItems='center' mt={{base: '2', '2xl': '0'}}>
            {deleteBlock}
          </HStack>
        </Flex>
        <HStack w='100%'>{!canDelete && produitDejaCommandeBlock}</HStack>
      </VStack>

      : /* Version mobile */
      <VStack alignItems='stretch' w='100%'>
        <HStack>
          {precommandeBlock}
          {articleBlock}         
          {deleteBlock}
        </HStack>
        <HStack justify='space-between' w='100%' mb='2'>
          {selectBlock}
          {prixBlock}
        </HStack>
        <HStack w='100%'>
          {buyBlock({somethingSubmitting:isSubmitting, afficher_bouton_liste:true})}
        </HStack>
        {produitDejaCommandeBlock}
      </VStack>
    }
  </LigneProduitLayout>;
});
