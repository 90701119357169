import {usePromoMpSearch} from 'ff-common';
import {Box, Button, HStack, Skeleton, Spacer, Text} from '@chakra-ui/react';
import {CatalogProductsGrid} from '@/domains/catalogue/CatalogProductsGrid';
import {Link, useLocation} from 'react-router-dom';
import {RightSingleArrow} from '@/components/svg/RightSingleArrowIcon';
import {routes} from 'ff-common';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';

const ItemSkeleton: React.FC = () => (
  <>
    <HStack justifyContent='space-between' ml={5} mr={5} position="relative" mt={{base: '50px', md: '100px'}} mb="60px" bg="white" pt="30px">
      <Skeleton height='24px' width={{base:'200px', md:'350px'}}/>
      <Skeleton height='14px' width={{base:'100px', md:'230px'}}/>
    </HStack>
    <Box m={5} >
      <Skeleton height='250px' width='100%'/>
    </Box>
  </>
);

export const CatalogueViewAccueil: React.FC = () => {
  const location = useLocation();
  const {t} = useTranslation('common');
  const {buildAssistanceRoute} = useIsAssistanceMode();
    
  const {itemResultsPromo, itemResultsMP, isLoading, promoDispo} = usePromoMpSearch();
  
  const promoLien = buildAssistanceRoute(routes.client.catalogue, {}, {nature_du_prix: 'promo', from: 'promo'});
  const MPLien = buildAssistanceRoute(routes.client.catalogue, {}, {est_MP: true, from: 'mp'});

  const title = promoDispo ? t('catalogue.promotion_du_moment') : t('catalogue.produits_marque_proprietaire');
  // const buttonText = promoDispo ? t('catalogue.voir_tout_promotion') : t('catalogue.voir_tout');
  const buttonText = t('catalogue.voir_tout');
  const selectionHits = promoDispo ? itemResultsPromo?.hits : itemResultsMP?.hits ?? [];
  const Lien = promoDispo ? promoLien : MPLien;

  return (
    <>
      {isLoading && <ItemSkeleton/>}
      <Box bg='white' pb={1}>
        <Box pl={5} pr={5} position="relative" mt={{base: '50px', md: '100px'}} mb="60px" pt="30px" bg="white">
          <HStack width="100%">
            {!isLoading && (
              <>
                <Text fontSize={{base: 'sm', md: 'xl', lg: '2xl'}} fontWeight="700" color="blue.main"> {title} </Text>
                <Spacer/>
                <Button rightIcon={<RightSingleArrow />} as={Link} to={Lien} color="gray.dark" fontWeight="600"
                  variant="ghost" _hover={{backgroundColor: 'transparent'}} fontSize={{base: 'xs', md: 'inherit'}}
                  m={{base: 0, md: 'inherit'}} p={{base: 0, md: 'inherit'}}
                >
                  <Text display={{base: 'block', md: 'none'}}>{t('catalogue.voir_tout')}</Text>
                  <Text display={{base: 'none', md: 'block'}}>{buttonText}</Text>
                </Button>
              </>
            )}
          </HStack>
        </Box>
        {selectionHits && <CatalogProductsGrid
          hitResult={selectionHits}
          location={location}
        />}
      </Box>
    </>
  );
};
