import {checkboxAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    minW:'46px',
    textAlign:'right',
  },
  control: {
    backgroundColor:'white.100',
  },
});

export const checkboxTheme = defineMultiStyleConfig({baseStyle});