import {HStack, Text, useStyleConfig} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';

export const StatutBadge: React.FC<{variant: string}> = ({variant}) => {
  const {t} = useTranslation('common');
  const styles = useStyleConfig('StatutBadge', {variant});
  
  return (
    <HStack sx={styles} borderRadius="8px" px={4} py={1} justifyContent="center" alignItems="center">
      <Text fontSize="xs" fontWeight="400">{t(`commande.${variant}`)}</Text>
    </HStack>
  );
};