import {Image, VStack} from '@chakra-ui/react';
import {TFunction} from 'i18next';

export const Left404: React.FC<{t: TFunction}> = ({t}) => (
  <VStack justify={{base:'center', md:'start'}} alignItems='center' m='9'>
    <Image 
      src='/ffconnect/panier/panier_vide.webp' 
      alt={t('panier.vide.alt_carton_vide')} 
      w={{base:'10em', md:'25em'}} 
      maxW='100%' 
    />
  </VStack>

);
