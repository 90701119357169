import {Button, ButtonProps} from '@chakra-ui/react';

interface GrayButtonProps extends ButtonProps {
  texte: string | number;
}

export const GrayButton: React.FC<GrayButtonProps> = ({texte, ...props}) => {
  return (
    <Button
      fontSize="sm"
      bg='gray.light'
      color='black.text'
      px="8"
      h="52px"
      mt='20px'
      fontWeight="600"
      transition="all 0.2s ease-in-out"
      cursor='pointer'
      _hover={{bg: 'gray.400', borderColor:'white'}}
      {...props}
    >
      {texte}
    </Button>
  );
};
