import {Skeleton, Text, VStack} from '@chakra-ui/react';
import {State} from '@synako/halitrek';
import {LivraisonDesc} from 'api-types/ffconnect-hal';
import {TFunction} from 'i18next';
import {Trans} from 'react-i18next';
import {InfoLivraisonPanierVide} from './InfoLivraisonPanierVide';
import {HaliRenderOne} from '@synako/haligator';

export const RightPaniersAValider: React.FC<({
  t: TFunction,
  jeViensDeValider: boolean,
  livraisonsAvecPanier: State<LivraisonDesc>[]
})> = ({t, jeViensDeValider, livraisonsAvecPanier}) => (
  <VStack alignItems='stretch' p='6' pt='0'>
    {jeViensDeValider && (
      <Text fontSize='2xl' fontWeight='700' mb='5'>
        <Trans t={t}>
          {t('panier.vide.n_oubliez_pas_de_valider_restant', {count: livraisonsAvecPanier.length})}
        </Trans>
      </Text>
    )}
    {
      livraisonsAvecPanier.map((livraison)=>
        <HaliRenderOne 
          key={livraison.data.id} 
          nav={livraison.follow('panier_ouvert')} 
          skeleton={() => <Skeleton />}
          render={({data}) => {
            return (
              <InfoLivraisonPanierVide 
                panierData={data}
                t={t}
              />
            );
          }} />
      )
    }

  </VStack>

);
